import { observable } from "mobx";

var contactStore = observable({
    activeTab: "",
    setActiveTab(activeTab) {
        this.activeTab = activeTab;
    },
    splitterUrl(url) {
        let link = url.split("");
        let previewLink = '';
        for (let i = 0; i < 18; i++) {
            previewLink = previewLink + link[i]
        };
        return previewLink;
    }
});

export default contactStore;