export const ClientAudienceGridColumnName = {
    ClientAudienceCheckBox: "",
    ClientAudienceName: "Folder name",
    ClientAudienceTotalMembers: "Total members",
    ClientAudienceSource: "Source",
    ClientAudienceCreated: "Created",
    ClientAudienceLastAdded: "Last added",
    ClientAudienceLastActivity: "Last activity",
};

export const ClientAudienceGridFieldName = {
    ClientAudienceCheckBox : "rowNum",
    ClientAudienceName: "audienceName",
    ClientAudienceTotalMembers: "totalMembers",
    ClientAudienceSource: "source",
    ClientAudienceCreated: "created",
    ClientAudienceLastAdded: "lastAddedDate",
    ClientAudienceLastActivity: "activity",
};

// export const ClientAudienceMessage = {
//     DeleteMessage : "Delete the selected automation record?",
//     DeleteSecondMessage : "All of your automation scheduler and data will be deleted !",
//     DeleteTitle : "Delete Automations",
// };