import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { Card, FormGroup, Button } from "reactstrap";
import { fabricStore, chatbotStore } from "stores";

const deleteStepMouseOver = e => {
  const { canvas } = fabricStore;
  if (e && e.target && e.target.id && e.target.id.startsWith("delete-")) {
    // fill for the circle background
    e.target._objects[0].set("fill", "#EF4277");
    // fill for the X text
    e.target._objects[1].set("fill", "white");
    canvas.renderAll();
  }
};

const deleteStepMouseOut = e => {
  const { canvas } = fabricStore;
  if (e && e.target && e.target.id && e.target.id.startsWith("delete-")) {
    // fill for the circle background
    e.target._objects[0].set("fill", "lightgray");
    // fill for the X text
    e.target._objects[1].set("fill", "black");
    canvas.renderAll();
  }
};

const deleteStepMouseDown = e => {
  const { canvas } = fabricStore;

  // this function is for fix problem where user cancel the delete, the delete button still hovered
  const changeColorToMouseOut = object => {
    // fill for the circle background
    object._objects[0].set("fill", "lightgray");
    // fill for the X text
    object._objects[1].set("fill", "black");
    canvas.renderAll();
  };

  if (e.target && e.target.id && e.target._objects[1].id?.startsWith("delete-")) {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Card className="alert-confirm">
              <h4>Remove element ?</h4>
              <p>
                All your edits and progress will be deleted forever. This cannot
                be undone!
              </p>
              <FormGroup className="alert-confirm-buttons">
                <Button color="link" onClick={() => { onClose(); changeColorToMouseOut(e.target._objects[1]); }}>
                  Cancel
                </Button>{" "}
                &nbsp;
                <Button
                  color="danger"
                  onClick={() => {
                    const deletedObjectIndex = canvas.getObjects().indexOf(e.target);

                    for (let i = canvas.getObjects().length - 1; i > deletedObjectIndex; i--) {
                      canvas.item(i).top = canvas.item(i - 2).oCoords.bl.y;
                      canvas.item(i).addWithUpdate();
                    }

                    canvas.remove(e.target);
                    fabricStore.emptyActiveObject();

                    canvas.renderAll();

                    // Reset save canvas state to show modal leave confirmation
                    chatbotStore.setIsCanvasSaved(false);

                    onClose();
                  }}
                >
                  Remove
                </Button>
              </FormGroup>
            </Card>
          </div>
        );
      }
    });
  }
}

export { deleteStepMouseOver, deleteStepMouseOut, deleteStepMouseDown };
