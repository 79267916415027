import S3 from "aws-sdk/clients/s3";
import { v4 as uuidv4 } from "uuid";
import { extension, lookup } from "mime-types";
import { UploadToS3Type } from "./enum";
import { chatbotStore } from "stores"

const defaultBucketOptions = {
  ServerSideEncryption: "AES256",
  ACL: "public-read"
};

const s3Options = {
  apiVersion: '2006-03-01',
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
  region: process.env.REACT_APP_AWS_S3_REGION
};

const uploadToS3 = (object, type) => {
  const s3 = new S3(s3Options);
  let bucketName = process.env.REACT_APP_AWS_S3_TEMP_BUCKET;

  switch (type) {
    case UploadToS3Type.Temporary:
      bucketName = process.env.REACT_APP_AWS_S3_TEMP_BUCKET;
      break;
    case UploadToS3Type.Avatar:
      bucketName = `${process.env.REACT_APP_AWS_S3_FILE_BUCKET}/chatbot/avatars`;
      break;
    case UploadToS3Type.Attachment:
      bucketName = `${process.env.REACT_APP_AWS_S3_FILE_BUCKET}/chatbot/attachments`;
      break;
    default:
      bucketName = process.env.REACT_APP_AWS_S3_TEMP_BUCKET;
      break;
  };

  const bucketOptions = {
    ...defaultBucketOptions,
    Bucket: bucketName,
    Body: object,
    Key: `${uuidv4()}.${extension(object.type)}`,
    ContentLength: object.size,
    ContentType: object.type
  };

  return (s3.upload(bucketOptions).on('httpUploadProgress', event => {
    chatbotStore.setUploadPercentage(event, type);
  }).promise().then(response => {
    return Promise.resolve(response);
  }).catch(err => {
    return Promise.reject(err);
  }));
};

const moveFileS3 = (stringUrl, folderName) => {
  const s3 = new S3(s3Options);
  const url = new URL(stringUrl);

  const params = {
    ...defaultBucketOptions,
    Bucket: `${process.env.REACT_APP_AWS_S3_FILE_BUCKET}/${folderName}`,
    CopySource: `${url.pathname}`,
    Key: `${uuidv4()}.${extension(lookup(stringUrl))}`
  };

  return (s3.copyObject(params).promise().then(response => {
    const newUrl = `https://s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_S3_FILE_BUCKET}/${folderName}/${params.Key}`;
    return Promise.resolve({ ...response, Location: newUrl });
  }).catch(err => {
    return Promise.reject(err);
  }));
};

export { uploadToS3, moveFileS3 };
