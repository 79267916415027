import React from "react";
import { fabricStore } from "stores/";
import * as _ from "lodash";
import ImageChatbot from "components/Chatbots/components/customs/ImageChatbot";
import CustomMessageChatbot from "components/Chatbots/components/customs/CustomMessageChatbot";
import NpsChatbot from "components/Chatbots/components/customs/NpsChatbot";
import { validatorUserInputNumber, validatorUserInputLength, validatorUserInputEmail } from "components/Chatbots/components/customs/ValidatorChatbot";
import { Enum } from "helpers";
// import VideoChatbot from "components/Chatbots/components/customs/VideoChatbot";


// ====================== EXAMPLE DATA CHATBOT ===========================
// const steps = [
//   {
//     id: "1",
//     //asMessage: true,
//     //trigger: "2"
//     options: [
//       { value: 1, label: '1', trigger: '2' },
//       { value: 2, label: '2', trigger: '2' },
//       { value: 3, label: '3', trigger: '2' },
//       { value: 4, label: '4', trigger: '2' },
//       { value: 5, label: '5', trigger: '2' },
//       { value: 6, label: '6', trigger: '2' },
//       { value: 7, label: '7', trigger: '2' },
//       { value: 8, label: '8', trigger: '2' },
//       { value: 9, label: '9', trigger: '2' },
//       { value: 10, label: '10', trigger: '2' },
//     ],
//     waitAction: true
//   },
//   {
//     id: "2",
//     component: (
//       <VideoChatbot
//         src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
//         controls={true} // OPTIONAL, DEFAULT IS TRUE
//         autoplay={false} // OPTIONAL, DEFAULT IS FALSE
//       />
//     ),
//     trigger: "3"
//   },
//   {
//     id: "3",
//     message: "Hi {previousValue}, nice to meet you!",
//     user: true,
//     trigger: '4'
//   },
//   {
//     id: "4",
//     message: "Hi {previousValue}, nice to meet you!",
//     end: true
//   }
// ];

const getIndexGridCount = array => {
  return _.findIndex(array, o => {
    return o.id === "add-step-start";
  });
};

const convertCanvasArrayToChatbotArray = (loadFromBackend = false, canvasDataFromBackend = []) => {
  fabricStore.activeObject = {};
  const { canvasObjects } = fabricStore;
  let stepArray = [];
  let chatbotArray = [];
  let endStepId = "end";
  let canvasObjectFinal = canvasObjects

  if (loadFromBackend && canvasDataFromBackend.length > 0) {
    canvasObjectFinal = canvasDataFromBackend
  }

  for (
    let index = getIndexGridCount(canvasObjectFinal);
    index < canvasObjectFinal.length;
    index++
  ) {
    if (!canvasObjectFinal[index].id.startsWith("add-step")) {
      stepArray.push(canvasObjectFinal[index]);
    }
  }

  // check if have no step
  if (stepArray.length === 0) {
    return false
  }

  stepArray.forEach((element, index) => {
    let stepObject = {};
    let videoObjectId = `${element.id}-video`
    let imageObjectId = `${element.id}-image`
    let gifObjectId = `${element.id}-gif`
    let npsObjectId = `${element.id}-nps`
    let userInputObjectId = `${element.id}-userinput`
    let userInputValidationObjectId = `${element.id}-invalidlength`
    let userInputValidationPhoneObjectId = `${element.id}-invalidnumber`
    let userInputValidationEmailObjectId = `${element.id}-invalidemail`

    // canvas ID for the message id
    stepObject["id"] = element.id;
    // asMessage true: will make custom component's style same as bot message's style
    stepObject["asMessage"] = true;
    // canvas DESCRIPTION TEXT for the message chatbot ------- // added conditional for different array structur between native canvas and canvasData from backend (remove underscore only)
    stepObject["component"] = <CustomMessageChatbot message={loadFromBackend ? element.objects[0].objects[0].objects[0].objects[2].fullText : element._objects[0]._objects[0]._objects[0]._objects[2].fullText} />;
    // canvas next object for trigger for next chatbot
    if (index < stepArray.length - 1) {
      stepObject["trigger"] = stepArray[index + 1].id;
    }
    // push object to array
    chatbotArray.push(stepObject);


    // ======================= adding image =====================
    if (element.attImage) {
      let objectWithImage = {};
      objectWithImage["id"] = imageObjectId;
      objectWithImage["component"] = (<ImageChatbot image={element.attImage} />);

      if (!element.id.startsWith("no-reply")) {
        objectWithImage["trigger"] = userInputObjectId;
      } else {
        // check if current step is NOT the last step
        if (index < stepArray.length - 1) {
          objectWithImage["trigger"] = stepArray[index + 1].id;
        }
      }

      // set the latest object in array's trigger to this image message object
      chatbotArray[chatbotArray.length - 1].trigger = imageObjectId;

      chatbotArray.push(objectWithImage);
    }


    // ======================= adding gif =====================
    if (element.attGif) {
      let objectWithGif = {};
      objectWithGif["id"] = gifObjectId;
      objectWithGif["component"] = (<ImageChatbot image={element.attGif} />);

      if (!element.id.startsWith("no-reply")) {
        objectWithGif["trigger"] = userInputObjectId;
      } else {
        // check if current step is NOT the last step
        if (index < stepArray.length - 1) {
          objectWithGif["trigger"] = stepArray[index + 1].id;
        }
      }

      // set the latest object in array's trigger to this gif message object
      chatbotArray[chatbotArray.length - 1].trigger = gifObjectId;

      chatbotArray.push(objectWithGif);
    }


    // ======================= adding video =====================
    if (element.attVideo) {
      let objectWithVideo = {};
      objectWithVideo["id"] = videoObjectId;
      objectWithVideo["component"] = (
        <video width="100%" controls crossOrigin="anonymous">
          <source src={element.attVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      );
      if (!element.id.startsWith("no-reply")) {
        objectWithVideo['trigger'] = userInputObjectId;
      } else {
        // check if current step is NOT the last step
        if (index < stepArray.length - 1) {
          objectWithVideo["trigger"] = stepArray[index + 1].id;
        }
      }

      // set the latest object in array's trigger to this video message object
      chatbotArray[chatbotArray.length - 1].trigger = videoObjectId;

      chatbotArray.push(objectWithVideo);
    }


    // ======================= adding NPS =====================
    if (element.objectType === Enum.CanvasNodeType.NetPromoterScore) {
      let objectNPS = {};
      objectNPS["id"] = npsObjectId;
      objectNPS["component"] = <NpsChatbot scoreOneText={element.scoreOneText} scoreTenText={element.scoreTenText} />;
      objectNPS["waitAction"] = true;
      objectNPS["trigger"] = index < stepArray.length - 1 ? stepArray[index + 1].id : endStepId;

      // set the latest object in array's trigger to this nps message object
      chatbotArray[chatbotArray.length - 1].trigger = npsObjectId;

      chatbotArray.push(objectNPS);
    }


    // ============== adding userInput ===============
    if (element.objectType !== Enum.CanvasNodeType.NoReply && element.objectType !== Enum.CanvasNodeType.NetPromoterScore) {
      let objectWithInput = {};
      objectWithInput["id"] = userInputObjectId;
      objectWithInput["user"] = true;
      objectWithInput["inputAttributes"] = { maxLength: element.characterLimit };
      // set the latest object in array's trigger to this userinput object
      chatbotArray[chatbotArray.length - 1].trigger = userInputObjectId;
      chatbotArray.push(objectWithInput);
    }


    // ============== adding userInput validation length ===============
    if (element.objectType !== Enum.CanvasNodeType.NoReply && element.objectType !== Enum.CanvasNodeType.NetPromoterScore) {
      let objectWithInput = {};
      objectWithInput["id"] = userInputValidationObjectId;
      objectWithInput["asMessage"] = true;
      objectWithInput["component"] = <CustomMessageChatbot message={`Please enter a limit value between 1 to ${element.characterLimit} characters!`} />;
      objectWithInput["trigger"] = stepArray[index].id;

      // set the latest object in array's trigger to this userinput message object
      chatbotArray[chatbotArray.length - 1].trigger = props => {
        let result = validatorUserInputLength(props.value ?? props.steps[userInputObjectId].value, element.characterLimit);
        switch (result) {
          case Enum.ValidationStatus.Valid:
            if (element.objectType !== Enum.CanvasNodeType.PhoneNumber && element.objectType !== Enum.CanvasNodeType.EmailAddress) {
              if (index < stepArray.length - 1) {
                return stepArray[index + 1].id;
              } else {
                return endStepId;
              }
            } else {
              // if valid length, type email
              if (element.objectType === Enum.CanvasNodeType.EmailAddress) {
                let resultValidationEmail = validatorUserInputEmail(props.value ?? props.steps[userInputObjectId].value, element.characterLimit);
                switch (resultValidationEmail) {
                  case Enum.ValidationStatus.Valid:
                    if (index < stepArray.length - 1) {
                      return stepArray[index + 1].id;
                    } else {
                      return endStepId;
                    }
                  case Enum.ValidationStatus.InvalidEmail:
                    return userInputValidationEmailObjectId;
                  default:
                    break;
                }
              }

              // if valid length, type phone number
              if (element.objectType === Enum.CanvasNodeType.PhoneNumber) {
                let resultValidationPhone = validatorUserInputNumber(props.value ?? props.steps[userInputObjectId].value, element.characterLimit);
                switch (resultValidationPhone) {
                  case Enum.ValidationStatus.Valid:
                    if (index < stepArray.length - 1) {
                      return stepArray[index + 1].id;
                    } else {
                      return endStepId;
                    }
                  case Enum.ValidationStatus.InvalidNumber:
                    return userInputValidationPhoneObjectId;
                  default:
                    break;
                }
              }

              // if valid length, type document upload
              // etc
            }
            break;
          case Enum.ValidationStatus.InvalidLength:
            return userInputValidationObjectId;
          default:
            break;
        }
      }
      chatbotArray.push(objectWithInput);
    }

    // ============== adding userInput validation phone number ===============
    if (element.objectType === Enum.CanvasNodeType.PhoneNumber) {
      let objectWithInput = {};
      objectWithInput["id"] = userInputValidationPhoneObjectId;
      objectWithInput["asMessage"] = true;
      objectWithInput["component"] = <CustomMessageChatbot message="Please reply with a valid phone number format!" />;
      objectWithInput["trigger"] = stepArray[index].id;

      // set the latest object in array's trigger to this image message object
      chatbotArray[chatbotArray.length - 1].trigger = props => {
        let validationPhone = validatorUserInputNumber(props.value ?? props.steps[userInputObjectId].value, element.characterLimit);
        switch (validationPhone) {
          case Enum.ValidationStatus.Valid:
            if (index < stepArray.length - 1) {
              return stepArray[index + 1].id;
            } else {
              return endStepId;
            }
          case Enum.ValidationStatus.InvalidLength:
            return stepArray[index].id;
          case Enum.ValidationStatus.InvalidNumber:
            return stepArray[index].id;
          default:
            break;
        }
      }
      chatbotArray.push(objectWithInput);
    }


    // ============== adding userInput validation email ===============
    if (element.objectType === Enum.CanvasNodeType.EmailAddress) {
      let objectWithInput = {};
      objectWithInput["id"] = userInputValidationEmailObjectId;
      objectWithInput["asMessage"] = true;
      objectWithInput["component"] = <CustomMessageChatbot message="Please reply with a valid email format!" />;
      objectWithInput["trigger"] = stepArray[index].id;

      // set the latest object in array's trigger to this validation message object
      chatbotArray[chatbotArray.length - 1].trigger = props => {
        let validationEmail = validatorUserInputEmail(props.value ?? props.steps[userInputObjectId].value, element.characterLimit);
        switch (validationEmail) {
          case Enum.ValidationStatus.Valid:
            if (index < stepArray.length - 1) {
              return stepArray[index + 1].id;
            } else {
              return endStepId;
            }
          case Enum.ValidationStatus.InvalidLength:
            return stepArray[index].id;
          case Enum.ValidationStatus.InvalidEmail:
            return stepArray[index].id;
          default:
            break;
        }
      }
      chatbotArray.push(objectWithInput);
    }


  });

  // added trigger to END if NOREPLY is the last step
  if (chatbotArray[chatbotArray.length - 1].id.startsWith('no-reply')) {
    chatbotArray[chatbotArray.length - 1].trigger = endStepId
  }

  // add end value
  let endObject = {};
  endObject["id"] = endStepId;
  endObject["asMessage"] = true;
  endObject["delay"] = 0;
  endObject["component"] = <CustomMessageChatbot message="end" isEndStep={true} />;
  endObject["end"] = true;
  chatbotArray.push(endObject);


  return chatbotArray;
};


export { convertCanvasArrayToChatbotArray };
