import React, { useState } from 'react';
import styled from 'styled-components';
import { chatbotStore } from "stores";

const NpsChatbot = (props) => {
  const [activeValue, setActiveValue] = useState(null);
  const npsDefaultColor = { color: chatbotStore.fontColor, backgroundColor: chatbotStore.headerColor }
  const npsSelectedColor = { color: chatbotStore.headerColor, backgroundColor: chatbotStore.fontColor }

  const ButtonOption = styled.button`
    margin: 8px 0px;
    padding: 4px 8px;
    border-radius: 15px;
    border-width: thin;
    text-align: center;
    font-size: 9px;
    border-style: double;
    border-color: ${chatbotStore.headerColor};
    color:${chatbotStore.headerColor};
    background-color: ${chatbotStore.fontColor};
    &:hover {
      color: ${chatbotStore.fontColor} !important;
      background-color: ${chatbotStore.headerColor} !important;
    }
    &:focus {
      outline:none !important;
      color: ${chatbotStore.fontColor};
      background-color: ${chatbotStore.headerColor};
    }
    &:nth-last-child(1){
      padding: 4px 6px;
    }
  `;

  const ClickActive = (e) => {
    if (!activeValue) {
      setActiveValue(e.target.value);
      props.triggerNextStep({ value: e.target.value });
    }
  }

  return (
    <div className="chatbot-nps">
      <div>
        <ButtonOption value="1" style={activeValue === "1" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>1</ButtonOption>
        <ButtonOption value="2" style={activeValue === "2" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>2</ButtonOption>
        <ButtonOption value="3" style={activeValue === "3" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>3</ButtonOption>
        <ButtonOption value="4" style={activeValue === "4" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>4</ButtonOption>
        <ButtonOption value="5" style={activeValue === "5" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>5</ButtonOption>
        <ButtonOption value="6" style={activeValue === "6" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>6</ButtonOption>
        <ButtonOption value="7" style={activeValue === "7" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>7</ButtonOption>
        <ButtonOption value="8" style={activeValue === "8" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>8</ButtonOption>
        <ButtonOption value="9" style={activeValue === "9" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>9</ButtonOption>
        <ButtonOption value="10" style={activeValue === "10" ? npsDefaultColor : npsSelectedColor} onClick={(e) => ClickActive(e)}>10</ButtonOption>
      </div>
      <div style={{ color: chatbotStore.headerColor }}>
        <span>{props.scoreOneText ?? "Not at all likely"}</span>
        <span style={{ float: "right" }}>{props.scoreTenText ?? "Extremely likely"}</span>
      </div>
    </div>
  );
}

export default NpsChatbot;