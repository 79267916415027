import { observable, action } from "mobx";
import { ApiUrl, ApiService, Constants } from "helpers";

var candidateAudienceStore = observable({

  dataCandidate: [],
  idCandidates: [],
  idAudiences: [],
  isDisableDelete: true,
  activeExport: false,
  activeDelete: false,
  activeLoadFilter: false,
  activeSaveFilter: false,

  setActiveExport(data) {
    this.activeExport = data;
  },
  setActiveLoadFilter(data) {
    this.activeLoadFilter = data;
  },
  setActiveSaveFilter(data) {
    this.activeSaveFilter = data;
  },
  setActiveDelete(data) {
    this.activeDelete = data;
  },
  setIsDisableDelete(data) {
    this.isDisableDelete = data;
  },
  setAddIdAudiences(id) {
    this.idAudiences.push(id);
  },
  setRemoveIdAudiences(id) {
    this.idAudiences = id;
  },
  setIdCandidates(data) {
    this.idCandidates = data;
  },
  setDataCandidate(data) {
    this.dataCandidate = [];
    this.dataCandidate = [...data];
  },

  getAllAudience() {
    return (ApiService.get(ApiUrl.getAllAudience).then(
      action(
        response => {
          if (response && response.data && !response.data.isError) {
            this.setDataCandidate(response.data.result)
            return Promise.resolve(response.data);
          } else {
            return Promise.reject(response.data.message);
          }
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },

  // for configuration column
  audienceName: "",
  audienceType: "",
  totalMembers: "",
  source: "",
  created: "",
  activity: "",
  contacted: "",
  active: "",

  showColumn: {
    audienceName: true,
    audienceType: true,
    totalMembers: true,
    source: true,
    created: true,
    lastAddedDate: true,
    lastActivityDate: true,
    lastActivity: true,
    contacted: true,
    status: true,
    actionButton: true
  },

  showColumnConfigure: {
    audienceName: true,
    audienceType: true,
    totalMembers: true,
    source: true,
    created: true,
    lastAddedDate: true,
    lastActivityDate: true,
    lastActivity: true,
    activity: true,
    contacted: true,
    active: true,
    actionButton: true
  },

  columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  columnOrderTemp: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  columnOrderDisable: [],
  columnOrderDisableTemp: [],
  firstOrder: false,
  filterGrid: [],
  sortGrid: [],
  countRowCandidates: 0,

  setFirstOrder(data) {
    this.firstOrder = data;
  },
  setColumnOrder(data) {
    this.columnOrder = data;
  },
  setColumnOrderTemp(data) {
    this.columnOrderTemp = data;
  },
  setOrderDisable(data) {
    this.columnOrderDisable = data;
  },
  setOrderDisableTemp(data) {
    this.columnOrderDisableTemp = data;
  },
  addItemColumnOrderDisableTemp(data) {
    this.columnOrderDisableTemp.push(data);
  },
  removeItemColumnOrderDisableTemp(data) {
    var index = this.columnOrderDisableTemp.indexOf(data);
    if (index > -1) {
      this.columnOrderDisableTemp.splice(index, 1);
    }
  },
  setAudienceName(audienceName) {
    this.audienceName = audienceName;
  },
  setAudienceType(audienceType) {
    this.audienceType = audienceType;
  },
  setTotalMembers(totalMembers) {
    this.totalMembers = totalMembers;
  },
  setSource(source) {
    this.source = source;
  },
  setCreated(created) {
    this.created = created;
  },
  setActivity(activity) {
    this.activity = activity;
  },
  setContacted(contacted) {
    this.contacted = contacted;
  },
  setActive(active) {
    this.active = active;
  },
  setShowColumn(data) {
    this.showColumn = { ...this.showColumn, ...data };
  },
  setShowColumnConfigure(data) {
    this.showColumnConfigure = {
      ...this.showColumnConfigure,
      ...data
    };
  },
  setShowColumnConfigureDefault(data) {
    this.showColumnConfigure = data
  },
  setCountRowCandidates(countRowCandidates) {
    this.countRowCandidates = countRowCandidates;
  },
  setFilterGrid(filterGrid) {
    this.filterGrid = filterGrid;
  },
  setSortGrid(sortGrid) {
    this.sortGrid = sortGrid;
  },

  unsubscribeAudienceMembers(ids) {
    if (ids.length === 0) {
      return Promise.resolve()
    }
    let obj = {
      ids: ids,
    }
    return (
      ApiService.edit(ApiUrl.unsubscribeAudienceMember, obj, Constants.ALERT_UNSUBSCRIBE_SUCCESS, '')
        .then(
          action(
            response => {
              return Promise.resolve(response)
            }
          )
        ).catch(
          action(
            err => {
              return Promise.reject(err);
            }
          )
        )
    );
  },

  removeAudienceMembers(ids, type = null) {
    if (ids.length === 0) {
      return Promise.resolve()
    }
    return (
      ApiService.bulkRemove(ApiUrl.deleteAudienceMember, type, ids, Constants.ALERT_DELETE_SUCCESS, '')
        .then(
          action(
            response => {
              return Promise.resolve(response)
            }
          )
        ).catch(
          action(
            err => {
              return Promise.reject(err);
            }
          )
        )
    );
  },
});

export default candidateAudienceStore;
