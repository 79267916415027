import { observable } from "mobx";
import moment from 'moment';
import Alert from 'react-s-alert';
import { ApiService, ApiUrl, Constants } from "helpers";

var jobStore = observable({
  jobs: [],
  jobDetail: {},
  jobTitle: "",
  company: "",
  organization: "",
  status: "",
  location: "",
  lastContacted: "",
  recentActivity: "",
  source: "",
  clientName: "",
  industry: "",

  showColumn: {
    jobTitle: true,
    organisation: true,
    status: true,
    location: true,
    source: true,
    clientName: true,
    industry: true
  },

  showColumnConfigure: {
    jobTitle: true,
    organisation: true,
    status: true,
    location: true,
    source: true,
    clientName: true,
    industry: true
  },

  columnOrder: [0, 1, 2, 3, 4, 5, 6],
  columnOrderTemp: [0, 1, 2, 3, 4, 5, 6],

  columnOrderDisable: [],
  columnOrderDisableTemp: [],
  searchCandidateData: {},
  filterCandidateData: {},
  countRowJobs: '0',
  firstOrder: false,
  filterGrid: [],
  sortGrid: [],

  getAllJobs() {
    ApiService.get(ApiUrl.getAllJob, "", Constants.ALERT_FETCH_FAILED)
      .then(response => {
        let dataAPI = [];
        response.data.result.forEach(element => {
          let convertedObject = {
            ...element,
            updatedAt: moment.utc(element?.updatedAt).local().format('DD/MM/YYYY h:mma')
          }

          dataAPI.push(convertedObject)
        });

        this.setJobs(dataAPI);
      });
  },
  setJobs(data) {
    this.jobs = data;
  },
  setJobDetail(data) {
    this.jobDetail = data;
  },
  setFirstOrder(data) {
    this.firstOrder = data;
  },
  setColumnOrder(data) {
    this.columnOrder = data;
  },
  setColumnOrderTemp(data) {
    this.columnOrderTemp = data;
  },
  setOrderDisable(data) {
    this.columnOrderDisable = data;
  },
  setOrderDisableTemp(data) {
    this.columnOrderDisableTemp = data;
  },
  addItemColumnOrderDisableTemp(data) {
    this.columnOrderDisableTemp.push(data);
  },
  removeItemColumnOrderDisableTemp(data) {
    var index = this.columnOrderDisableTemp.indexOf(data);
    if (index > -1) {
      this.columnOrderDisableTemp.splice(index, 1);
    }
  },
  setJobTitle(jobTitle) {
    this.jobTitle = jobTitle;
  },
  setOrganization(organization) {
    this.organization = organization;
  },
  setStatus(status) {
    this.status = status;
  },
  setLocation(location) {
    this.location = location;
  },
  setLastContacted(lastContacted) {
    this.lastContacted = lastContacted;
  },
  setRecentActivity(recentActivity) {
    this.recentActivity = recentActivity;
  },
  setSource(source) {
    this.source = source;
  },
  setClientName(clientName) {
    this.clientName = clientName;
  },
  setIndustry(industry) {
    this.industry = industry;
  },
  setShowColumn(data) {
    this.showColumn = { ...this.showColumn, ...data };
  },
  setShowColumnConfigure(data) {
    this.showColumnConfigure = { ...this.showColumnConfigure, ...data };
  },
  setShowColumnConfigureDefault(data) {
    this.showColumnConfigure = data
  },
  setSearchJobData(searchJobData) {
    this.searchJobData = searchJobData;
  },
  setFilterJobData(filterJobData) {
    this.filterJobData = filterJobData;
  },
  setCountRowJobs(countRowJobs) {
    this.countRowJobs = countRowJobs;
  },
  setFilterGrid(filterGrid) {
    this.filterGrid = filterGrid;
  },
  setSortGrid(sortGrid) {
    this.sortGrid = sortGrid;
  },
  saveGridView(data, filterType) {
    let form = new FormData();
    form.append('Name', data.viewName);
    form.append('Summary', data.summary);
    form.append('GridFilterConfiguration', JSON.stringify(this.filterGrid));
    form.append('GridSortConfiguration', JSON.stringify(this.sortGrid));
    form.append('GridColumnConfiguration', JSON.stringify(this.columnOrder) + '||' +
      JSON.stringify(this.columnOrderDisable) + '||' +
      JSON.stringify(this.showColumn));
    form.append('FilterType', filterType);

    return (ApiService.add(ApiUrl.addFilter, form, "Save a view succeed!", '').then(() => {
      return Promise.resolve(true);
    }).catch(err => {
      Alert.error(err);
      return Promise.reject(err)
    }));
  },

  getListFilter(filterType, showLevel) {
    return (ApiService.getListFilter(
      ApiUrl.getAllFilter,
      filterType,
      showLevel,
      '',
      Constants.ALERT_FETCH_FAILED
    ).then(response => {
      return Promise.resolve(response.data.result);
    }).catch(err => {
      Alert.error(err);
      return Promise.reject(err)
    }));
  },

  getJobDetail(id) {
    return (ApiService.get(ApiUrl.getJobDetails + id, '', '').then((response) => {
      this.setJobDetail(response.data.result)
      return Promise.resolve(response.data.result);
    }).catch(err => {
      Alert.error('Failed get job data!')
      return Promise.reject(err)
    }))
  }
});

export default jobStore;
