import { userStore } from "stores"

const setCookie = (cookieName) => {
    const { id } = userStore.user;
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0);
    const expires = "expires=" + expirationDate.toGMTString(); 
    document.cookie = cookieName + "=" + id + "; " + expires + ";path=/";
}

const getCookie = (name) => {
    const { id } = userStore.user;
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const stringSplitCookie = decodedCookie.split(';') || [];
    let sameId = 0;
    for (let i = 0; i <= stringSplitCookie.length; i++) {
        let string = stringSplitCookie[i];
        while (string && string.charAt(0) === " ") {
            string = string.substring(1);
        }

        if (string && string.includes(cookieName)) {
            if(parseInt(string.substring(cookieName.length, string.length)) === id) {
                sameId += 1;
            };
        }

    }

    return (sameId > 0);
}

const removeCookie = (stringName) => {
    if( getCookie(stringName) ) {
        const currentDate = new Date();
        const expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7, 0, 0, 0);
        const expires = "expires=" + expirationDate.toGMTString(); 
        document.cookie = stringName + "=; " + expires + ";path=/";
    }
}

export {getCookie, setCookie, removeCookie}