export const SecondaryColor = '#2B2B2B';
export const PrimaryColor = '#EF4277';
export const WhiteColor = '#FFFFFF';
export const ShadowBoxColor = '#4e4c4c';
export const SwitchColor = '#707070';
export const HeaderAutomationsColor = '#E5E5E5';
export const DisabledColor = '#969393';
export const FontBlackColor = '#2C2C2C';
export const FontLableNPSColor = '#414042';
export const BackgroundInputColor = '#F5F5F5';
export const BackgroundInputProfile = '#E9E9E9';
export const LightGrayColor = '#bfbcbc';
