export const AutomationGridColumnName = {
    AutomationName: "Campaign name",
    AutomationDescription: "Description",
    AutomationCategory: "Category",
    AutomationCreatedDate: "Date created",
    AutomationStatus: "Status",
    AutomationAction: "",
};

export const AutomationGridFieldName = {
    AutomationName: "name",
    AutomationDescription: "description",
    AutomationCategory: "category",
    AutomationCreatedDate: "createdAt",
    AutomationStatus: "state",
    AutomationAction: "automationAction",
};

export const AutomationMessage = {
    DeleteMessage : "Delete the selected automation record?",
    DeleteSecondMessage : "All of your automation scheduler and data will be deleted !",
    DeleteTitle : "Delete Automations",
};