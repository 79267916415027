import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { observer } from "mobx-react";
import { Enum } from 'helpers';

const MergeTagsDropdown = observer(({
  atsCategorisedFieldsForMergeTags,
  onTagSelected,
  isDisabled = false,
  isAutomationContact = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  if (isAutomationContact) {
    atsCategorisedFieldsForMergeTags = [...atsCategorisedFieldsForMergeTags, ...Enum.CandidateMergeTagsForContacts];
  }

  const filteredCategories = searchTerm
    ? atsCategorisedFieldsForMergeTags.filter(category =>
      category.atsFields.some(atsField =>
        atsField.displayName.toLowerCase().includes(searchTerm.toLowerCase())))
    : atsCategorisedFieldsForMergeTags;

  return (
    <UncontrolledDropdown sm={2} md={2} className="d-inline w-100">
      <DropdownToggle color="secondary" className="mr-0 merge-tags-dropdown w-100" id="mergeTags" caret disabled={isDisabled}>
        Merge tags
      </DropdownToggle>
      <DropdownMenu dark className="merge-tags-dropdownmenu">
        <div className="merge-tags-search-input-container">
          <i className="fa fa-search" aria-hidden="true"></i>
          <Input
            type="search"
            placeholder="Type to search tags"
            className="merge-tags-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {filteredCategories.length > 0 && filteredCategories.map((category, categoryIndex) => (
          <React.Fragment key={categoryIndex}>
            <div className='merge-tags-bottom-line'></div>
            <DropdownItem header text className="merge-tags-header-dropdownitem">{category.categoryType}</DropdownItem>
            {category.atsFields.filter(atsField =>
              atsField.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((atsField) => (
                <DropdownItem
                  className="py-2 merge-tags-childe-dropdownitem"
                  id={atsField.id}
                  key={atsField.id}
                  onClick={() => onTagSelected(atsField.tokenVariable)}
                >
                  <span className="ml-3">{atsField.displayName}</span>
                </DropdownItem>
              ))}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
});

export default MergeTagsDropdown;
