import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"
import { Modal, ModalBody } from 'reactstrap';
import "./ImageChatbot.css";

const ImageChatbot = observer(({ image }) => {
  const imageDetail = image;
  const [imageURL, setImageURL] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (imageDetail) {
      setImageURL(imageDetail);
    }
  }, [imageDetail]);

  const toggleModal = () => setModal(!modal);

  return (
    <div className="position-relative">
      <img src={imageURL} alt="images-chatbot" className="image-preview" onClick={toggleModal} />
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalBody>
          <img src={imageURL} alt="images-chatbot-full" className="image-preview-full" />
        </ModalBody>
      </Modal>
    </div>
  );
});

export default ImageChatbot;
