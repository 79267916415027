import { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { observer } from "mobx-react";
import { textWithoutSpace } from "helpers/Validator"

const SurveyDropdown = observer(({
  surveyItems,
  onSurveySelected,
  isDisabled = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredSurveys = searchTerm
    ? surveyItems.filter(surveyItem => 
      surveyItem.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : surveyItems;

  return (
    <UncontrolledDropdown sm={2} md={2} className="d-inline w-100">
      <DropdownToggle color="secondary" className="mr-0 merge-tags-dropdown w-100" id="survey" caret disabled={isDisabled}>
        Survey
      </DropdownToggle>
      <DropdownMenu dark className="survey-tags-dropdownmenu">
        <div className="merge-tags-search-input-container">
          <i className="fa fa-search" aria-hidden="true"></i>
          <Input
            type="search"
            placeholder="Type to search surveys"
            className="merge-tags-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>        
        
        {filteredSurveys.length > 0 && filteredSurveys.map((item, index) => (
          <DropdownItem
            className="py-2 merge-tags-childe-dropdownitem"
            id={item.id}
            key={index}
            onClick={() => {
              const surveyName = textWithoutSpace(item.name);
              onSurveySelected(`survey_${item.id}_${surveyName}`)
            }}>
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
});

export default SurveyDropdown;