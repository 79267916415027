import { observable } from "mobx";
import moment from 'moment';
import { ApiService, ApiUrl } from "helpers";

var candidateStore = observable({
  candidateName: "",
  candidateTitle: "",
  tactic: "",
  status: "",
  roiAiScore: 0,
  resume: "",
  emailAddress: "",
  recruiterRating: 0,
  type: "",
  userIds: [],
  isArchived: false,
  allCandidateList: [],
  candidateListGrid: [],

  showColumn: {
    candidateName: true,
    candidateTitle: true,
    tactic: true,
    status: true,
    roiAiScore: true,
    resume: true,
    recruiterRating: true,
    type: true
  },

  showColumnConfigure: {
    candidateName: true,
    candidateTitle: true,
    tactic: true,
    status: true,
    roiAiScore: true,
    resume: true,
    recruiterRating: true,
    type: true
  },

  columnOrder: [0, 1, 2, 3, 4, 5, 6, 7],
  columnOrderTemp: [0, 1, 2, 3, 4, 5, 6, 7],
  // columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  // columnOrderTemp: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  columnOrderDisable: [],
  columnOrderDisableTemp: [],
  firstOrder: false,
  filterGrid: [],
  sortGrid: [],
  countRowCandidates: '0',

  getJobCandidate(id) {
    ApiService.get(ApiUrl.getJobCandidate + id, "", 'Failed to get job data')
      .then(response => {
        this.setDataCandidatesGrid(response.data.result);
      });
  },
  archiveJobCandidate(archiveData) {
    ApiService.post(ApiUrl.archiveOrRestoreJob, archiveData, `Candidate data successfully archived!`, `Failed to archive candidate data!`)
      .then(res => {
        if (!res.data.isError) {
          this.setDataCandidatesGrid(res.data.result.result);
        };
      });
  },
  restoreJobCandidate(restoreData) {
    ApiService.post(ApiUrl.archiveOrRestoreJob, restoreData, `Candidate data successfully restored!`, `Failed to restore candidate data!`)
      .then(res => {
        if (!res.data.isError) {
          this.setDataCandidatesGrid(res.data.result.result);
        };
      });
  },
  setDataCandidatesGrid(data) {
    let dataAPI = [];
    data.forEach(element => {
      let convertedObject = {
        ...element,
        updatedAt: moment.utc(element?.updatedAt).local().format('DD/MM/YYYY h:mma')
      }
      dataAPI.push(convertedObject)
    });
    const dataNonArchive = dataAPI.filter(e => e.isArchive === this.isArchived);
    this.allCandidateList = dataAPI;
    this.candidateListGrid = dataNonArchive;
    this.userIds = [];
  },
  setCandidateListGrid(data) {
    this.candidateListGrid = data;
  },
  setIsArchived(data) {
    this.isArchived = data;
  },
  setUserIds(data) {
    this.userIds = data;
  },
  setFirstOrder(data) {
    this.firstOrder = data;
  },
  setColumnOrder(data) {
    this.columnOrder = data;
  },
  setColumnOrderTemp(data) {
    this.columnOrderTemp = data;
  },
  setOrderDisable(data) {
    this.columnOrderDisable = data;
  },
  setOrderDisableTemp(data) {
    this.columnOrderDisableTemp = data;
  },
  addItemColumnOrderDisableTemp(data) {
    this.columnOrderDisableTemp.push(data);
  },
  removeItemColumnOrderDisableTemp(data) {
    var index = this.columnOrderDisableTemp.indexOf(data);
    if (index > -1) {
      this.columnOrderDisableTemp.splice(index, 1);
    }
  },
  setCandidateName(candidateName) {
    this.candidateName = candidateName;
  },
  setJobTitle(jobTitle) {
    this.jobTitle = jobTitle;
  },
  setCurrentEmployer(currentEmployer) {
    this.currentEmployer = currentEmployer;
  },
  setLocation(location) {
    this.location = location;
  },
  setResume(resume) {
    this.resume = resume;
  },
  setLastContacted(lastContacted) {
    this.lastContacted = lastContacted;
  },
  setRecentActivity(recentActivity) {
    this.recentActivity = recentActivity;
  },
  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  },
  setEmailAddress(emailAddress) {
    this.emailAddress = emailAddress;
  },
  setRoiAiScore(roiAiScore) {
    this.roiAiScore = roiAiScore;
  },
  setRecruiterRating(recruiterRating) {
    this.recruiterRating = recruiterRating;
  },
  setShowColumn(data) {
    this.showColumn = { ...this.showColumn, ...data };
  },
  setShowColumnConfigure(data) {
    this.showColumnConfigure = {
      ...this.showColumnConfigure,
      ...data
    };
  },
  setShowColumnConfigureDefault(data) {
    this.showColumnConfigure = data
  },
  setCountRowCandidates(countRowCandidates) {
    this.countRowCandidates = countRowCandidates;
  },
  setFilterGrid(filterGrid) {
    this.filterGrid = filterGrid;
  },
  setSortGrid(sortGrid) {
    this.sortGrid = sortGrid;
  },
});

export default candidateStore;
