import { action, observable } from "mobx";
import { ApiService, ApiUrl, Constants, Validator, LocalStorage } from "helpers";
import { DomainAuthentication, MAX_RETRY_DOMAIN_AUTHENTICATION } from "helpers/Constants";
import Alert from 'react-s-alert';

const user = LocalStorage.loadGeneral("user");

const domainAuthenticationStore = observable(
  {
    inputDomain: '',
    activeTab: DomainAuthentication.Input,
    domainAuthData: {},
    domainAuthList: [],
    isCompanyDomainVerified: false,
    companyId: user?.companyId,
    domainAuthenticationMaxRetry: MAX_RETRY_DOMAIN_AUTHENTICATION,

    setIsCompanyDomainVerified(data) {
      this.isCompanyDomainVerified = data
    },

    setDomainAuthData(domainAuthData) {
      this.domainAuthData = domainAuthData
    },

    setDomainAuthList(domainAuthList) {
      this.domainAuthList = [];
      this.domainAuthList = domainAuthList;
    },

    setActiveTab(data) {
      this.activeTab = data
    },

    setInputDomain(inputDomain) {
      this.inputDomain = inputDomain;
    },

    setDomainAuthenticationMaxRetry(domainAuthenticationMaxRetry) {
      this.domainAuthenticationMaxRetry = domainAuthenticationMaxRetry;
    },

    reset() {
      this.inputDomain = '';
      this.domainAuthData = {};
      this.activeTab = Constants.DomainAuthentication.Input;
      this.isCompanyDomainVerified = false;
    },

    getCompanyDetail() {
      return (ApiService.getDetail(ApiUrl.getCompanyById, this.companyId).then(
        action(
          response => {
            this.setIsCompanyDomainVerified(response.data.result.isDomainVerified);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getDomainAuthenticationList(isDefaultOnly = false, isVerifiedOnly = false) {
      return (ApiService.get(ApiUrl.getCompanyDomain + `?isDefaultOnly=${isDefaultOnly}&isVerifiedOnly=${isVerifiedOnly}`).then(
        action(
          response => {
            const { data } = response;
            if (data.statusCode === 200) {
              this.setDomainAuthList(data.result);
            } else {
              this.setActiveTab(Constants.DomainAuthentication.Input);
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getDomainAuthentication(companyDomainId = 0) {
      return (ApiService.get(ApiUrl.getCompanyDomainDetailById + `/${companyDomainId}`).then(
        action(
          response => {
            const { data } = response;
            if (data.statusCode === 200) {
              this.setDomainAuthData(data.result);
              this.setIsCompanyDomainVerified(data.result.isDomainVerified)
              // this.getCompanyDetail();
              this.setDomainAuthenticationMaxRetry(Constants.MAX_RETRY_DOMAIN_AUTHENTICATION)
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            // re run the fetch,, refference for bug #4608
            if (this.domainAuthenticationMaxRetry) {
              setTimeout(() => {
                this.setDomainAuthenticationMaxRetry(this.domainAuthenticationMaxRetry - 1)
                this.getDomainAuthentication()
              }, 1000);
            }
            return Promise.reject(err);
          }
        )
      ))
    },

    deleteCompanyDomain(companyDomainId) {
      //delete domain authentication by API
      return (ApiService.remove(ApiUrl.deleteCompanyDomain, companyDomainId).then(
        action(
          response => {
            //add get data domain
            this.reset();
            Alert.success(Constants.ALERT_DOMAIN_HAS_BEEN_DELETED);
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            Alert.error(err);
            return Promise.reject(err);
          }
        )
      ));
    },

    registerDomain() {
      //add domain to register
      const checkInputDomain = Validator.inputUrlFormat(this.inputDomain);
      if (checkInputDomain) {
        let params = {
          domainName: this.inputDomain
        };

        return (ApiService.post(ApiUrl.addCompanyDomain, params).then(
          action(
            response => {
              if (response && response.status === 200 && response.data && !response.data.isError) {
                return Promise.resolve(response.data);
              }

              Alert.error(response.data.message ? response.data.message : Constants.ALERT_SOMETHING_WENT_WRONG);
              return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          )
        ).catch(
          action(
            err => {
              return Promise.reject(err);
            }
          )
        ))
      } else {
        Alert.error("Domain format is incorrect");
      }
    },

    verifyDomain(companyDomainId, userId) {
      const params = { companyDomainId, userId };
      //request to verify
      return (ApiService.post(ApiUrl.verifyCompanyDomain, params).then(
        action(
          response => {
            this.getDomainAuthentication(companyDomainId);
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    setDomainDefault(companyDomainId) {
      const params = { companyDomainId };
      //request to set default
      return (ApiService.edit(ApiUrl.setDomainDefault, params).then(
        action(
          response => {
            this.getDomainAuthenticationList();
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    copyToClipboard(val) {
      const el = document.createElement('textarea');
      el.value = val;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  }
);

export default domainAuthenticationStore;
