import styled from 'styled-components';

export const PinkButton = styled.button`
        background: #DD5178;
        color: #FFFFFF;
        padding: 10px 18px;
        border: 1px solid #DD5178;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        &:hover {
            opacity: 0.7
            };
`;

export const BlackButton = styled.button`
        background: #222222;
        color: #FFFFFF;
        padding: 10px 18px;
        border: 1px solid #222222;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        &:hover {
            opacity: 0.7
            };
`;




/* 
export const ClearButton = styled.div`
    button {
        font-size: 10px;
        padding: 6px 12px;
        margin-bottom: 6px;
        background: #414042;
        border-radius: 8px;
        border: none;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
`;

export const FirstRow = styled.div`
    .div {
        display: flex;
        flex-direction: column;
        border: none;
        margin-bottom: 32.5px;
    }

    label {
        color: #fff;
    }

    .clear-button {
        font-size: 10px;
        padding: 6px 12px;
        margin-bottom: 6px;
        background: #414042;
        border-radius: 8px;
        border: none;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
`
export const SecondRow = styled.div`
    label {
        margin-top: 32px;
        color: #fff;
    }

    .button-items {
        width: 100%;
        padding: 6px;
        background: #222222;
        border: 1px solid #414042;
        border-radius: 8px;
    }

    .button-div {
        width: 100%;
        display: flex;
        gap: 8px; 
        justify-content: space-between;
    }

    button {
        width: 100%;
        border-radius: 8px;
        color: #777777;
        background: #222222;
        border: none;
        &:hover {
            color: #fff;
        }
    }

    button.isActive {
        background: #fff;
        color: #222222;
    }

    .btn {
        padding: 3px 6px;
        outline: none;
    }

    .secondRowBtn {
        font-size: 10px;
        padding: 6px 12px;
        margin-bottom: 6px;
        background: #414042;
        border-radius: 8px;
        border: none;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
`

export const ThirdRow = styled.div`
    .row-div {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
    }

    .col-div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    input {
        text-align: center;
        width: 100%;
        border-radius: 8px;
        padding: 10px;
        background: #222222;
        border: none;
        color: #fff;
    }

    label {
        font-size: 12px;
        margin-bottom: 30px;
        color: #ffffff80;
        text-align: center;
    }
`

export const FourthRow = styled.div`
    .div {
        display: flex;
        flex-direction: column;
        border: none;
    }
    label {
        margin-top: 34px;
        color: #fff;
    }
` */
