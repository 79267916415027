import { fabric } from "fabric";
import { fabricStore } from "stores";
import { showIsAddStep, addStepMouseOver, addStepMouseOut, removeActiveObjectOnClickCanvas } from "./events";
import { DESCRIPTION_FONT_SIZE } from "helpers/Constants";
import { resetRedoHistory } from "components/Canvas/commons/UndoRedo";

const InitCanvas = () => {
  const { canvas } = fabricStore;

  const rect = new fabric.Rect({
    width: 50,
    height: 50,
    originX: "center",
    originY: "center",
    fill: "white",
    stroke: "#2C2C2C",
    strokeWidth: 2,
    rx: 50,
    ry: 50
  });

  const text = new fabric.Text("Step", {
    fontSize: DESCRIPTION_FONT_SIZE,
    originX: "center",
    originY: "center",
    fontWeight: "bold",
  });

  const group = new fabric.Group([rect, text], {
    left: 85,
    top: 10,
    id: `add-step-start`,
    name: `add-step-start`,
    hoverCursor: "pointer"
  });

  group.on("mousedown", showIsAddStep);
  group.on("mouseover", addStepMouseOver);
  group.on("mouseout", addStepMouseOut);

  canvas.on("mouse:down", removeActiveObjectOnClickCanvas);

  canvas.on("object:beforemodified", e => {
    resetRedoHistory();
  });

  canvas.add(group);

  // Init undo redo here, remove history undo redo when add new line and add step
  if (canvas && canvas.historyNextState && canvas.historyUndo && canvas.historyRedo) {
    canvas.clearHistory();
  }

  canvas.renderAll();
};

export { InitCanvas };
