export const SurveyReportsGridColumnName = {
    CandidateName: "Candidate name",
    ClientName: "Contact name",
    CandidateEmail: "Candidate email",
    Email: "Email",
    PhoneNumber: "Phone number",
    CandidatePhoneNumber: "Candidate phone number",
    LastOpenedDate: "Last opened date",
    TotalOpened: "Total opened",
    CompletionDate: "Completion date",
    ViewResponseButton: "View responses button",
    CampaignName: "Campaign name",
    AutomationName: "Automation name",
    LastSendDate: "Last send date",
    EmailOrTextName: "Email/Text name",
    Status: "Status",
    SurveyName: "Survey name",
    Category: "Category",
    ActivatedBy: "Activated by",
    DateActivated: "Date activated",
    Completions: "Completed",
    TotalUsers: "Total users",
    Opened: "Opened",
    JobTitle: "Job title",
    Company: "Company",
    CandidateFirstName: "Candidate first name",
    CandidateLastName: "Candidate last name",
    ClientFirstName: "Contact first name",
    ClientLastName: "Contact last name",
};
