import { fabricStore, storyFormStore } from "stores";
import { PRIMARY_COLOR } from "helpers/Constants";

const showAddStepBottom = e => {
  fabricStore.showAddStepBottom(true);
  storyFormStore.emptyTempFormData();
};

const addStepBottomMouseOver = (e, object = undefined) => {
  const { canvas } = fabricStore;
  if (typeof object !== "undefined") {
    // if mouse over change border color, etc
    object._objects[0].set("stroke", PRIMARY_COLOR);
    canvas.renderAll();
  } else {
    // if mouse over change border color, etc
    e.target._objects[0]._objects[0].set("stroke", PRIMARY_COLOR);
    canvas.renderAll();
  }
};

const addStepBottomMouseOut = (e, object = undefined) => {
  const { canvas } = fabricStore;
  if (typeof object !== "undefined") {
    // if mouse out revert border color, etc
    object._objects[0].set("stroke", "#2C2C2C");
    canvas.renderAll();
  } else {
    // if mouse out revert border color, etc
    e.target._objects[0]._objects[0].set("stroke", "#2C2C2C");
    canvas.renderAll();
  }
};

export { showAddStepBottom, addStepBottomMouseOver, addStepBottomMouseOut };
