import { Editor } from "@tinymce/tinymce-react";
import PropTypes from "prop-types";

const RichTextEditor = ({
  id = "rich-text-editor",
  initialValue = "<p></p>",
  defaultInit = {
    selector: 'textarea',
    height: 300,
    menubar: false,
    contextmenu: "", // disable right click
    plugins: 'link lists paste',
    toolbar: 'fontselect | fontsizeselect | bold italic underline | forecolor | alignleft alignright aligncenter alignjustify | bullist numlist indent outdent | removeformat | link unlink ',
    toolbar_mode: 'wrap',
    branding: false,
    entity_encoding: 'raw',
    force_br_newlines: true,
    force_p_newlines: false,
    forced_root_block: '', // Needed for 3.x based on https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@force_br_newlines/
    font_formats:
      "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Open Sans=open sans, helvetica neue, helvetica, arial, sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
    content_style:
      "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');" +
      "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap')" +
      `html, body {
    			height: 100%;
    		}

    		html {
    			margin: auto;
    		}
        
    		body {
    			vertical-align: middle;
          color: #95a5a6;
          background-color: #ffffff;
    		}`,
    link_assume_external_targets: 'http',
    paste_as_text: true,
    formats: {
      alignleft: [{
        selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li',
        styles: { textAlign: 'left' },
        inherit: false,
        defaultBlock: 'div',
        preview: 'font-family font-size'
      }],
      aligncenter: [{
        selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li',
        styles: { textAlign: 'center' },
        inherit: false,
        defaultBlock: 'div',
        preview: 'font-family font-size'
      }],
      alignright: [{
        selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li',
        styles: { textAlign: 'right' },
        inherit: false,
        defaultBlock: 'div',
        preview: 'font-family font-size'
      }],
      alignjustify: [{
        selector: 'figure,p,h1,h2,h3,h4,h5,h6,td,th,tr,div,ul,ol,li',
        styles: { textAlign: 'justify' },
        inherit: false,
        defaultBlock: 'div',
        preview: 'font-family font-size'
      }],
    },
  },
  restInit = {},
  disabled = false,
  inline = false,
  outputFormat = "html",
  textareaName = "rte-textarea",
  handleEditorChange = () => { },
  ...restProps
}) => (
  <Editor
    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
    id={id}
    value={initialValue}
    init={{ ...defaultInit, ...restInit }}
    disabled={disabled}
    inline={inline}
    outputFormat={outputFormat}
    textareaName={textareaName}
    onEditorChange={handleEditorChange}
    {...restProps}
  />
);

RichTextEditor.propTypes = {
  id: PropTypes.string,
  initialValue: PropTypes.string,
  defaultInit: PropTypes.objectOf(PropTypes.object),
  restInit: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  outputFormat: PropTypes.oneOf(['html', 'string']),
  textareaName: PropTypes.string,
  handleEditorChange: PropTypes.func,
  restProps: PropTypes.objectOf(PropTypes.object),
};

export default RichTextEditor;
