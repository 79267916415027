import { useEffect, useRef } from 'react';
import moment from "moment";
import { RappidNodeType, RappidText } from "helpers/Constants";
import { Enum } from "helpers";

export const calculateAddDelayDate = (weeks, days, hours, minutes, startDate = null) => {
    const date = startDate ?? moment();
    return moment(date)
        .add(days + (weeks * 7), 'days')
        .add(minutes + (hours * 60), 'minutes')
        .utc().format();
}

export const calculateSubstractDelayDate = (weeks, days, hours, minutes, startDate) => {
    return moment(startDate)
        .subtract(days + (weeks * 7), 'days')
        .subtract(minutes + (hours * 60), 'minutes')
        .utc().format();
}

export const convertDatesToDays = (startDate = null, endDate = null) => {
    let startDates = new Date(startDate);
    let endDates = new Date(endDate);
    startDates.setHours(0, 0, 0, 1);  // Start just after midnight
    endDates.setHours(23, 59, 59, 999);  // End just before midnight

    // Validate input
    if (endDates < startDates)
        return 0;

    // Calculate days between dates
    const millisecondsPerDay = 86400000; // Day in milliseconds
    const diff = endDates - startDates;  // Milliseconds between datetime objects
    const days = Math.floor(diff / millisecondsPerDay);
    return days;
}

export const convertDaysToDisplayText = (roiData, isPlacementStartDate, isPlacementEndDate) => {
    if (isPlacementStartDate || isPlacementEndDate) {
        const totalDelayDays = roiData?.delayDays;
        var delayText = getWeeksAndDaysFromDelayForm(roiData);
        const dateFormat = isPlacementStartDate ? "Start Date" : "End Date";
        if (totalDelayDays < 0)
            return `${delayText} ${Math.abs(totalDelayDays)} day(s) before the ${dateFormat}`
        return totalDelayDays > 0 ? `${delayText} ${totalDelayDays} day(s) after the ${dateFormat}` : `${delayText} On the ${dateFormat}`
    } else {
        return `${getWeeksDaysHoursAndMinutesFromDelayForm(roiData)}`;
    }
}

const getWeeksAndDaysFromDelayForm = (roiData) => {
    let delayText = '';
    if (roiData?.weeks > 0) delayText = delayText + `${roiData.weeks} week(s) `;
    if (roiData?.days > 0) delayText = delayText + `${roiData.days} day(s) `;
    delayText = delayText + ' delay ---'
    return delayText;
}

const getWeeksDaysHoursAndMinutesFromDelayForm = (roiData) => {
    let delayText = '';
    if (roiData?.weeks > 0) delayText = delayText + `${roiData.weeks} week(s) `;
    if (roiData?.days > 0) delayText = delayText + `${roiData.days} day(s) `;
    if (roiData?.hours > 0) delayText = delayText + `${roiData.hours} hour(s) `;
    if (roiData?.minutes > 0) delayText = delayText + `${roiData.minutes} minute(s) `;

    return delayText;
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

//TODO : modify me to work on array structure...
export const checkFirstDelayStep = (diagramStructure) => {
    let data = null;

    const changeDelayStep = diagramStructure.cells.filter(item => item.roiNodeType === RappidNodeType.Delay);

    if (changeDelayStep.length === 0) return null;

    changeDelayStep.forEach(item => {
        if (item.roiNodeType === RappidNodeType.Delay && !data) {
            data = {};
            data = { ...item };
            data.attrs.label.text = RappidText.DelayScheduleStep;
            data.roiData.isFirstDelay = true;
        }
    });
    return data;
};

export const modifyDiagramStructureForDelay = (diagramStructure, delayStepData) => {
    const newDiagramStructure = diagramStructure.cells.map(item => {
        let newItem = { ...item };
        if (item.id === delayStepData.id) {
            newItem = delayStepData;
        }
        return newItem;
    });
    const data = { cells: newDiagramStructure };
    return data;
};

export const checkFirstDelayFrom = (arrayStructure) => {
    const getFirstDelayForm = arrayStructure.filter(item => item.type === RappidNodeType.Delay && item.roiData.isFirstDelay);

    return getFirstDelayForm;
};

export const getScheduleText = (specificDays, hour) => {
    var daysString = "";
    for (let i = 0; i < specificDays.length; i++) {
        daysString += dayOfWeekAsString(specificDays[i]);
        if (i === specificDays.length - 1)
            daysString += " "
        else
            daysString += ", "
    }
    daysString += "at " + formatAMPM(hour);
    return "Scheduled: " + daysString;
}

const dayOfWeekAsString = (dayIndex) => {
    return ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][dayIndex] || '';
};

const formatAMPM = (date) => {
    if (!date) return "";
    let hours = moment(date).hour();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var strTime = hours + ' ' + ampm;
    return strTime;
};

export const getRecordUpdate = (updateRecordType, automationType) => {
    var recordUpdate = "Record update: by ";
    switch (updateRecordType) {
        case 1:
            if (automationType === Enum.AutomationType.Candidate) {
                return recordUpdate + "Recruiter";
            } else {
                return recordUpdate + "Owner";
            }
        case 2:
            return recordUpdate + "Job owner";
        case 3:
            return recordUpdate + "Admin user";
        case 4:
            return recordUpdate + "No user";
        default:
            return "";
    }
};