import { action, observable } from "mobx";
import { ApiService, ApiUrl, Constants, Validator, LocalStorage } from "helpers";
import Alert from 'react-s-alert';

const user = LocalStorage.loadGeneral("user");

const webActivityTrackerStore = observable(
  {
    webActivityTrackerData: {
      jobViewPath: "",
      applicationViewPath: "",
      customViewPath: "",
      apiKey: "",
      companyId: user?.companyId,
    },
    defaultDomain: {
      id: 0,
      domain: ""
    },
    listDomains: [],
    isFormDirty: false,

    resetWebActivityTrackerData(keepApiKey = false) {
      this.webActivityTrackerData = {
        ...this.webActivityTrackerData,
        jobViewPath: "",
        applicationViewPath: "",
        customViewPath: "",
        apiKey: keepApiKey ? this.webActivityTrackerData.apiKey : "",
        companyId: user?.companyId,
      };
      this.isFormDirty = false;
      this.listDomains = [];
    },

    //update specific data by index
    updateListDomains(data, indexData){
      this.listDomains[indexData] = data;
    },

    //set data with array from API
    setListDomains(listDomains){
      this.listDomains = [];
      this.listDomains = [...listDomains];
    },
   
    //add new data
    addNewDomain(domain){
      this.listDomains.push(domain);
    },

    setIsFormDirty(isFormDirty) {
      this.isFormDirty = isFormDirty;
    },

    setWebActivityTrackerData(data) {
      this.webActivityTrackerData = { ...this.webActivityTrackerData, ...data };
      this.isFormDirty = true;
    },
    
    checkDomainData(data){
      const finalData = [];
      const domainNameList = [];

      data.forEach(item => {
        const isValidDomain = Validator.inputUrlFormat(item.domain);
        if(isValidDomain || item.domain === ""){
          domainNameList.push(item.domain.trim());
          if(item.id === 0 && item.domain !== ""){
            finalData.push(item);
          } else if (item.id !== 0){
            finalData.push(item);
          }
        } else {
          return []
        }
      });
      
      //checking for empty domains
      const emptyDomain = finalData.filter(x => x.domain === "").length;
      
      // checking for duplicate domain
      const isDuplicateDomain = domainNameList.some((item, idx) => { 
        return domainNameList.indexOf(item) !== idx;
      });

      //reset web activity tracker data if all domains are empty
      if(emptyDomain && emptyDomain === finalData.length){
        this.resetWebActivityTrackerData(true)
      }

      return {
        isValid: isDuplicateDomain || finalData.length <= 0 ? false : true,
        data: finalData,
        msg: finalData.length <= 0 ? "Domain format is incorrect" : 
          isDuplicateDomain ? "Domain is duplicate, please change the value for continue" : "",
      };
    },

    getWebActivityTrackerSetting() {
      return (ApiService.get(ApiUrl.getWebActivityTrackerSetting, "", Constants.ALERT_FETCH_FAILED).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setWebActivityTrackerData(response.data.result);
              this.setListDomains(response.data.result.domains);
              webActivityTrackerStore.setIsFormDirty(false);
              Alert.success("Fetch succeeded");
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    updateWebActivityTrackerSetting() {
      const domainData = this.checkDomainData(this.listDomains);
      
      if (domainData.isValid) {
        const listEnterDomain = domainData.data;
        webActivityTrackerStore.setWebActivityTrackerData({ domains: listEnterDomain });
        return (ApiService.edit(ApiUrl.updateWebActivityTrackerSetting, this.webActivityTrackerData).then(
          action(
            response => {
              if (response && response.status === 200 && response.data && !response.data.isError) {
                this.setWebActivityTrackerData(response.data.result);
                this.setListDomains(response.data.result.domains);
                this.setIsFormDirty(false);
                Alert.success("Web Activity Tracker has been saved");
              } else {
                Alert.error("Oops, Something went wrong! Please try again.");
              }
              return Promise.resolve(response);
            }
          )
        ).catch(
          action(
            err => {
              return Promise.reject(err);
            }
          )
        ))
      } else {
        Alert.error(domainData.msg);
      }
    },
  }
);

export default webActivityTrackerStore;
