export const SurveyTemplateGridColumnName = {
  SurveyName: "Template name",
  SurveyDescription: "Description",
  SurveyCategory: "Category",
  SurveyCreatedDate: "Date created",
  SurveyUpdatedDate: "Updated date",
  SurveyUpdatedBy: "Updated by",
  SurveyAction: "",
};

export const SurveyTemplateGridFieldName = {
  SurveyName: "name",
  SurveyDescription: "description",
  SurveyCategory: "category",
  SurveyCreatedDate: "createdAt",
  SurveyUpdatedDate: "updatedAt",
  SurveyUpdatedBy: "updatedBy",
  SurveyAction: "action",
};

export const SurveyTemplateMessage = {
  DeleteMessage: "Delete the selected survey template record?",
  DeleteTitle: "Delete Survey Template",
};