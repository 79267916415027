import Alert from 'react-s-alert';
import axios from '../AxiosInstance';
import { commonStore } from "stores";

function get(url, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Get ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function getListFilter(url, filterType, showLevel, successMessage = '', errorMessage = '') {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: { filterType: filterType, showLevel: showLevel } })
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Get detail ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  });
}

function getDetail(url, id, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios
      .get(url + id)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Get detail ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function getDetailWithParams(url, id, params, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios
      .get(url + id, { params: { ...params } })
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Get detail ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function post(url, dataModel, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios
      .post(url, dataModel)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Add data ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function add(url, dataModel, successMessage = '', errorMessage = '', setIsLoading = true) {
  commonStore.setIsLoading(setIsLoading);
  return new Promise((resolve, reject) => {
    axios
      .post(url, dataModel)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Add data ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function edit(url, dataModel, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios
      .put(url, dataModel)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Edit data ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function remove(url, id, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  return new Promise((resolve, reject) => {
    axios.delete(url + id)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Delete ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function bulkRemove(url, type, ids, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  const params = {
    ids: ids,
    ...(type !== null && { type: type })
  };

  return new Promise((resolve, reject) => {
    axios.delete(url, {
      data: params
    })
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Delete ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  })
    .finally(() => commonStore.setIsLoading(false));
}

function editAsync(url, dataModel, successMessage = '', errorMessage = '') {
  return new Promise((resolve, reject) => {
    axios
      .put(url, dataModel)
      .then(response => {
        if (successMessage !== '') {
          Alert.success(successMessage);
        }
        return resolve(response);
      })
      .catch(error => {
        console.log(`%c Edit data ${url} error: ${error}`, 'color: red');
        if (errorMessage !== '') {
          Alert.error(errorMessage);
        }
        return reject(error.response.data);
      });
  });
}

function multipleRequest(param, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  const arrFunc = param.map((item) => {
    switch (item.type) {
      case 'add':
        return axios.post(item.url, item.data);
      default:
        return axios.get(item.url);
    }
  });

  return Promise.all(arrFunc).then((response) => {
    if (successMessage !== '') {
      Alert.success(successMessage);
    }
    return Promise.resolve(response);
  })
    .catch(error => {
      console.log(`%c error: ${error}`, 'color: red');
      if (errorMessage !== '') {
        Alert.error(errorMessage);
      }
      return Promise.reject(error.response);
    })
    .finally(() => commonStore.setIsLoading(false));
}

function multipleRequestAllSettled(param, successMessage = '', errorMessage = '') {
  commonStore.setIsLoading(true);
  const arrFunc = param.map((item) => {
    switch (item.type) {
      case 'add':
        return axios.post(item.url, item.data);
      default:
        return axios.get(item.url);
    }
  });

  return Promise.allSettled(arrFunc).then((response) => {
    if (successMessage !== '') {
      Alert.success(successMessage);
    }
    return Promise.resolve(response);
  })
    .catch(error => {
      console.log(`%c error: ${error}`, 'color: red');
      if (errorMessage !== '') {
        Alert.error(errorMessage);
      }
      return Promise.reject(error.response);
    })
    .finally(() => commonStore.setIsLoading(false));
}

function postWithSignal(url, dataModel, signal = null) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, dataModel, {
        signal
      })
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        if (error?.message === "canceled") {
          console.log('Request canceled by user');
        }
        return reject(error);
      });
  });
}

function reloadPage() {
  window.location.reload()
}

export {
  get,
  getListFilter,
  getDetail,
  getDetailWithParams,
  post,
  add,
  postWithSignal,
  edit,
  editAsync,
  remove,
  reloadPage,
  bulkRemove,
  multipleRequest,
  multipleRequestAllSettled
}
