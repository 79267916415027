import { Fragment } from 'react';
import LogoUser from "assets/img/user/user-icon.svg";
import LogoAutomations from "assets/img/logo/automation-icon.svg";
import LogoBurger from "assets/img/logo/burger-icon.svg";
import LogoText from "assets/img/logo/text-icon.svg";
import LogoSurvey from "assets/img/logo/survey-icon.svg";
import LogoEmail from "assets/img/logo/email-icon.svg";
import LogoAudiences from "assets/img/logo/audiences-icon.svg";
import LogoContacts from "assets/img/logo/contacts-icon.svg";
import LogoReport from "assets/img/logo/report-icon.svg";
import LogoSetting from "assets/img/logo/setting-icon.svg";
import LogoKnowledgeBase from "assets/img/logo/knowledge-base-icon.svg";
import LogoLinkedin from "assets/img/logo/linkedin-icon.svg";
import ImageDefault from "assets/img/logo/image-default.png";
import LogoFolderOpen from "assets/img/logo/folder-open-icon.svg";
import LogoSettingGrid from "assets/img/logo/settings-grid-icon.svg";
import LogoDownload from "assets/img/logo/download-icon.svg";
import LogoTemplate from "assets/img/logo/template-icon.svg";
import LogoPictureBrowse from "assets/img/logo/picture-browse-icon.svg";
import LogoPlus from "assets/img/logo/plus-icon.svg";
import LogoSmiley from "assets/img/logo/smiley-icon.svg";
import LogoTabTemplates from "assets/img/logo/logo-tab-templates.svg";
import LogoTabExisting from "assets/img/logo/logo-tab-existing.svg";
import LogoArrowDown from "assets/img/logo/arrow-down.svg";
import LogoActivity from "assets/img/logo/activity-icon.svg";
import LogoLink from "assets/img/logo/logo-link.svg";
import StoryIcon from "assets/img/logo/story-icon.svg";
import SendIcon from "assets/img/logo/send-icon.svg";
import TickIcon from "assets/img/logo/tick.svg";
import MessageIcon from "assets/img/logo/message-icon.svg";
import UserIcon from "assets/img/logo/user-icon.svg";
import PeopleIcon from "assets/img/logo/people-icon.svg";
import RoiDashboardIcon from "assets/img/logo/roi-dashboard-icon.svg";
import DashboardAutomationIcon from "assets/img/logo/dashboard-automation.svg";
import DashboardAudienceIcon from "assets/img/logo/dashboard-audience.svg";
import DashboardContactsIcon from "assets/img/logo/dashboard-contacts.svg";
import DashboardEmailsIcon from "assets/img/logo/dashboard-emails.svg";
import DashboardReportsIcon from "assets/img/logo/dashboard-reports.svg";
import DashboardReportsTransparentIcon from "assets/img/grid/dashboard-reports-transparent.svg";
import DashboardSettingsIcon from "assets/img/logo/dashboard-settings.svg";
import DashboardSurveyIcon from "assets/img/logo/dashboard-survey.svg";
import DashboardTextIcon from "assets/img/logo/dashboard-text.svg";
import UserIconPink from "assets/img/user/user-icon-pink.svg";
import UserIconGrey from "assets/img/user/user-icon-grey.svg";
import WizardAutomation from "assets/img/wizard/Automation.svg";
import WizardSurvey from "assets/img/wizard/Surveys_Chats.svg";
import WizardEmail from "assets/img/wizard/Email.svg";
import IconView from "assets/img/grid/View.svg";
import IconEdit from "assets/img/grid/Edit.svg";
import IconDelete from "assets/img/grid/Delete.svg";
import IconActivate from "assets/img/grid/Activate.svg";
import IconDeactivate from "assets/img/grid/Deacitvate.svg";
import IconDuplicate from "assets/img/grid/Duplicate.svg";

const RenderIcon = ({ title = "Candidates", iconCode = 1, width = 15, height = 15, className = "mr-2" }) => {
  return (
    <Fragment>
      <img alt={title} src={IconTabCode[iconCode]} width={iconCode === 3 ? "20" : width} height={iconCode === 3 ? "20" : height} className={className} />
    </Fragment>
  );
};

const IconTabCode = {
  1: UserIcon,
  2: PeopleIcon,
  3: StoryIcon,
  4: SendIcon,
  5: MessageIcon,
  6: LogoTabTemplates,
  7: LogoTabExisting,
};

export {
  LogoAutomations,
  LogoUser,
  LogoBurger,
  LogoText,
  LogoSurvey,
  LogoEmail,
  LogoAudiences,
  LogoContacts,
  LogoReport,
  LogoSetting,
  LogoKnowledgeBase,
  LogoLinkedin,
  LogoLink,
  ImageDefault,
  LogoFolderOpen,
  LogoSettingGrid,
  LogoDownload,
  LogoTemplate,
  LogoPictureBrowse,
  LogoPlus,
  LogoSmiley,
  LogoTabTemplates,
  LogoTabExisting,
  LogoArrowDown,
  LogoActivity,
  StoryIcon,
  SendIcon,
  UserIcon,
  PeopleIcon,
  MessageIcon,
  RenderIcon,
  RoiDashboardIcon,
  DashboardAutomationIcon,
  DashboardAudienceIcon,
  DashboardContactsIcon,
  DashboardEmailsIcon,
  DashboardReportsIcon,
  DashboardReportsTransparentIcon,
  DashboardSettingsIcon,
  DashboardSurveyIcon,
  DashboardTextIcon,
  TickIcon,
  UserIconPink,
  UserIconGrey,
  WizardAutomation,
  WizardSurvey,
  WizardEmail,
  IconView,
  IconEdit,
  IconDelete,
  IconActivate,
  IconDeactivate,
  IconDuplicate,
};
