import { observable, action } from "mobx";
import { InitCanvas } from "components/Canvas/components";
import { storyFormStore } from "stores";

const fabricStore = observable(
  {
    step: [],
    canvas: undefined,
    fabricIsAddStepSelected: false,
    fabricIsAddStepBottomSelected: false,
    fabricIsMultipleSelected: false,
    fabricIsFreeTextReplySelected: false,
    fabricIsNoReplySelected: false,
    fabricIsEmailAddressSelected: false,
    fabricIsPhoneNumberSelected: false,
    fabricIsDocumentUploadSelected: false,
    fabricIsUsernameSelected: false,
    fabricIsNetPromoterScoreSelected: false,
    canvasObjects: [],
    fabricCanvasObjects: [],
    activeObject: {},
    undoCount: 0,
    redoCount: 0,

    undo() {
      this.undoCount++;
      if (this.undoCount > 5) this.undoCount = 5;
      this.redoCount--;
      if (this.redoCount < 0) this.redoCount = 0;
      this.canvasObjects = this.canvas.getObjects();
    },

    redo() {
      this.redoCount++;
      if (this.redoCount > 5) this.redoCount = 5;
      this.undoCount--;
      if (this.undoCount < 0) this.undoCount = 0;
      this.canvasObjects = this.canvas.getObjects();
    },

    resetUndo() {
      this.undoCount = 0;
    },

    resetRedo() {
      this.redoCount = 0;
    },

    setCanvas(canvas) {
      this.canvas = canvas;
    },

    addFabricCanvasObjects() {
      this.fabricCanvasObjects.push(JSON.stringify(this.canvas.toJSON()));
    },

    addCanvasObjects(object) {
      this.canvasObjects.push(object);
    },

    removeCanvasObjects(object) {
      this.canvasObjects.splice(this.canvasObjects.findIndex(obj => obj.id === object.id), 1);
    },

    setActiveObject(object) {
      this.activeObject = object;
    },

    emptyActiveObject() {
      this.activeObject = {};
      storyFormStore.emptyTempFormData()
    },

    emptyCanvasObject() {
      this.canvasObjects = [];
    },

    resetFabricCanvasChatbot() {
      this.activeObject = {}; // clear selection
      this.canvasObjects = []; // clear canvasObjects data
      this.canvas.clear();  // clear canvas
      InitCanvas(); // re-initialize canvas with empty data
    },

    showAddStep(show) {
      this.fabricIsAddStepSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showAddStepBottom(show) {
      this.fabricIsAddStepBottomSelected = show;
      this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showMultipleAction(show) {
      this.fabricIsMultipleSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showFreeTextReplyAction(show) {
      this.fabricIsFreeTextReplySelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showNoReplyAction(show) {
      this.fabricIsNoReplySelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showEmailAddressAction(show) {
      this.fabricIsEmailAddressSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showPhoneNumberAction(show) {
      this.fabricIsPhoneNumberSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showDocumentUploadAction(show) {
      this.fabricIsDocumentUploadSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsUsernameSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showUserNameAction(show) {
      this.fabricIsUsernameSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsNetPromoterScoreSelected = false;
    },
    showNetPromoterScoreAction(show) {
      this.fabricIsNetPromoterScoreSelected = show;
      this.fabricIsAddStepBottomSelected = this.fabricIsAddStepSelected = this.fabricIsMultipleSelected = this.fabricIsFreeTextReplySelected = this.fabricIsNoReplySelected = this.fabricIsEmailAddressSelected = this.fabricIsPhoneNumberSelected = this.fabricIsDocumentUploadSelected = this.fabricIsUsernameSelected = false;
    },
  },
  {
    undo: action,
    redo: action,
    resetUndo: action,
    resetRedo: action,
    setCanvas: action,
    addCanvasObjects: action,
    removeCanvasObjects: action,
    addFabricCanvasObjects: action,
    emptyActiveObject: action,
    emptyCanvasObject: action,
    resetFabricCanvasChatbot: action,
    setActiveObject: action,
    showAddStep: action,
    showAddStepBottom: action,
    showMultipleAction: action,
    showFreeTextReplyAction: action,
    showNoReplyAction: action,
    showEmailAddressAction: action,
    showPhoneNumberAction: action,
    showDocumentUploadAction: action,
    showUserNameAction: action,
    showNetPromoterScoreAction: action
  }
);

// autorun(function () {
//   console.log(toJS(fabricStore));
// });

export default fabricStore;
