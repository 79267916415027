import { fabricStore, chatbotStore } from "stores";

const undo = () => {
  const { canvas, undoCount } = fabricStore;
  if ((canvas && canvas.historyUndo.length === 0) || undoCount === 5) {
    return;
  } else {
    // Reset save canvas state to show modal leave confirmation
    chatbotStore.setIsCanvasSaved(false);
    canvas.undo();
  }
}

const redo = () => {
  const { canvas, redoCount } = fabricStore;
  if ((canvas && canvas.historyRedo.length === 0) || redoCount === 5) {
    return;
  } else {
    // Reset save canvas state to show modal leave confirmation
    chatbotStore.setIsCanvasSaved(false);
    canvas.redo();
  }
}

const resetRedoHistory = () => {
  const { canvas } = fabricStore;
  if (canvas && canvas.historyRedo.length > 0) {
    canvas.historyNextState = JSON.stringify(canvas.toDatalessJSON(canvas.extraProps));
    canvas.historyRedo = [];
    fabricStore.resetRedo();
  }
}

export { undo, redo, resetRedoHistory };
