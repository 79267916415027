import { action, observable } from "mobx";
import { LinkValidityType } from "helpers/enum";
import { ApiService, ApiUrl } from "helpers";
import userStore from "./userStore";
import Alert from 'react-s-alert';
import { TimeZone } from "helpers/Constants";

const defaultTimeZoneObj = {
  rawFormat: TimeZone.DefaultTimeZone,
  rawOffsetInMinutes: 0,
};

const generalSettingStore = observable(
  {
    id: undefined,
    companyId: undefined,
    linkValidity: 15,  // default value is 15
    linkValidityType: LinkValidityType.Minute,  // default value is 0 (Minute(s))
    lengthFooterText: 0,  // default value is 0 character

    get timeZoneName() { return this.timeZone?.rawFormat || TimeZone.DefaultTimeZone; },
    timeZone: defaultTimeZoneObj,

    resetSetting() {
      this.id = undefined;
      this.companyId = undefined;
      this.linkValidity = 15;
      this.linkValidityType = LinkValidityType.Minute;
    },

    setSetting(data) {
      this.id = data.id;
      this.companyId = data.companyId;
      this.linkValidity = data.linkValidity;
      this.linkValidityType = data.linkValidityType;
    },

    setTimeZoneSetting(data) {
      this.id = data.id;

      this.timeZone = !!data.vvoTimeZone ? JSON.parse(data.vvoTimeZone) : {
        rawFormat: data.timeZoneName,
      };
    },

    setId(id) {
      this.id = id;
    },

    setCompanyId(companyId) {
      this.companyId = companyId;
    },

    setTimeZone(timeZoneObj) {
      this.timeZone = { ...this.timeZone, ...timeZoneObj };
    },

    resetTimeZone() {
      this.timeZone = defaultTimeZoneObj;
    },

    setLinkValidity(linkValidity) {
      if (linkValidity === 0) {
        this.linkValidity = 1;  // minimum value = 1
      } else {
        this.linkValidity = linkValidity;
      }
    },

    setLinkValidityType(linkValidityType) {
      this.linkValidityType = linkValidityType;
    },

    setLengthFooterText(lengthFooterText) {
      this.lengthFooterText = lengthFooterText;
    },

    getCompanyGeneralSetting() {
      const { user: { companyId: userCompanyId = 0 } } = userStore;
      if (!userCompanyId || userCompanyId === 0)
        return Promise.reject("User company not found!");

      return (ApiService.getDetail(ApiUrl.getCompanySetting, userCompanyId).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setSetting(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getCompanyTimeZone() {
      return (ApiService.get(ApiUrl.getTimeZones).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTimeZoneSetting(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => Promise.reject(err)
        )
      ))
    },

    updateCompanyTimeZone() {
      const data = {
        id: this.id,
        timeZoneName: this.timeZoneName,
        vvoTimeZone: JSON.stringify(this.timeZone),
      };

      return (ApiService.edit(ApiUrl.updateTimeZone, data).then(
        action(
          response => {
            console.log(response)
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTimeZoneSetting(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => Promise.reject(err)
        )
      ))
    },

    submit() {
      const { user: { companyId: userCompanyId = 0 } } = userStore;
      if (!userCompanyId || userCompanyId === 0) {
        return Promise.reject("User company not found!");
      }

      const data = {
        companyId: userCompanyId,
        linkValidity: this.linkValidity,
        linkValidityType: this.linkValidityType
      };

      if (this.id !== undefined) {
        this.updateGeneralSetting(data);
      } else {
        this.createGeneralSetting(data);
      }
    },

    createGeneralSetting(data) {
      return (ApiService.add(ApiUrl.createCompanySetting, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setSetting(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    updateGeneralSetting(data) {
      const updatedData = {
        id: this.id,
        ...data
      };

      return (ApiService.edit(ApiUrl.updateCompanySetting, updatedData).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setSetting(response.data.result);
              Alert.success("Changes is saved successfully");
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getLengthFooterText() {
      return (ApiService.get(ApiUrl.getLengthFooterText).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setLengthFooterText(response.data.result.totalChar);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },
  }
);

export default generalSettingStore;
