import { observable, action } from "mobx";
import { ApiUrl, ApiService } from "helpers";
import { ClientAudienceGridColumnName, ClientAudienceGridFieldName } from "views/Audience/CandidateAudience/ClientGrid/ClientAudienceConstant";

const clientAudienceStore = observable({
  clientAudiences: [],
  countRowAudienceClients: 0,
  idClientAudiences: [],
  isDisableDelete: true,
  activeDelete: false,

  setAddIdClientAudiences(id) {
    this.idClientAudiences.push(id);
  },
  setRemoveIdClientAudiences(data) {
    this.idClientAudiences = data;
  },
  setClearIdClientAudiences() {
    this.idClientAudiences = [];
  },
  setIsDisableDelete(data) {
    this.isDisableDelete = data;
  },
  setActiveDelete(data) {
    this.setActiveDelete = data;
  },
  setClientAudiences(data) {
    this.clientAudiences = [];
    this.clientAudiences = [...data];
  },
  setCountRowAudienceClients(countRow){
    this.countRowAudienceClients = countRow
  },

  gridColumn: Object.values(ClientAudienceGridFieldName).map((item, index) => {
    const headerName = Object.values(ClientAudienceGridColumnName);
    return {
      id: index,
      colId: item,
      field: item,
      headerName: headerName[index],
      width: item === ClientAudienceGridColumnName.ClientAudienceAction ? 400 : item === ClientAudienceGridColumnName.ClientAudienceName ? 350 : 200,
      hide: false,
      sort: item === ClientAudienceGridColumnName.ClientAudienceCreated ? 'asc' : null,
      order: index++
    }
  }),

  getClientAudiences() {
    return (ApiService.get(ApiUrl.getAudienceClients).then(
      action(
        response => {
          if (response && response.data && !response.data.isError && response.status === 200) {
            this.setClientAudiences(response.data.result)
            return Promise.resolve(response);
          }
          else {
            return Promise.reject(response?.data?.message);
          }
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },
  
});

export default clientAudienceStore;
