import styled from 'styled-components';
import { Col, Button, Label, Input, TabContent, FormGroup, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { PrimaryColor, WhiteColor, SecondaryColor, LightGrayColor } from '_variable';
import Select from 'react-select';

export const MarginCol = styled(Col)`
    ${(props) => `margin :` + props.contentmargin}
`;

export const AutomationInputHeader = styled(Input)`
  background-color: transparent;
  border-color: transparent;
  color: white;
  font-size: 16px;
  font-weight: bold;
  color: var(--quarternary);
  &:placeholder-shown {
     font-style: italic;
  }
   font-style: italic;
`

export const AutomationTabContent = styled(TabContent)`
  .automation-bg {
    background-color: var(--lightPrimaryRoi);
    border: none;
  }

  .rounded-pill {
    border-radius: 8px !important;
  }

  height : 100%;

  .sidebar-header {
    text-align: left;
    font-size: 18px;
    border-bottom: 1px solid;
    color: var(--lightColorRoi);
    margin-bottom: 20px;
    padding: 15px 10px 10px 10px;
    font-family: 'Roboto';
  }

  .icon-minimize {
    float: right;
    margin-top: 5px;
    cursor: pointer;
    transition: 0.2s;
    font-size : 16px;
  }

  .icon-minimize:hover {
    color: var(--primary);
  }

  .step-menu {
    background: var(--quarternary);
  }

  .step-icon {
    width: 40px;
    margin-right: 6px;
  }

  .step-title {
    font-weight: bold;
    padding: 13px 0;
    color: var(--lightColorRoi);
  }

  .step-option {
    margin: 17px 0px;
    color: var(--lightColorRoi);
    cursor: pointer;
    transition: 0.2s;
    font-size: 18px;
  }

  .step-body-text {
    margin: 17px 0px;
    color: var(--lightColorRoi);
    cursor: pointer;
    transition: 0.2s;
    font-size: 12px;
  }

  .segment-title {
    margin: 17px 0px;
    color: var(--lightColorRoi);
    cursor: pointer;
    transition: 0.2s;
    font-size: 13px;
  }

  .step-option:hover {
    margin: 17px 0px;
    color: var(--primary);
  }

  .automation-button-finalise {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  .automation-finalise-input-field {
    background-color: transparent;
    border-color: transparent;
  }

  .automation-to-finalise {
    color: #5c6873;
    padding-left: 1rem;
  }

  .automation-back-button {
    background: transparent;
    font-size: 20px;
    font-weight: bold;
    border: none;
  }

  .automation-back-icon {
    transition: .2s;
    cursor: pointer;
  }

  .automation-back-icon:hover {
    color: var(--primary);
  }

  .automation-form-input {
    min-width: 55px;
    margin: 0 -5px;
  }

  .automation-form-input-time {
    min-width: 55px;
  }

  .show-list-data {
    padding: 0.5rem 1.2rem !important;
    background-color: var(--fontHeaderGridRoi) !important;
    border-color: var(--fontHeaderGridRoi) !important;
    color: var(--lightPrimaryRoi) !important;
    border-radius: 50px !important;
  }

  .show-list-data button {
    padding: 0.35rem 1.2rem !important;
    opacity: 1 !important;
  }

  .show-list-data span {
    color: white !important;
  }

  .alert-custom {
    min-width: 100px !important;
    display: inline-block !important;
    margin-right: 5px !important;

    div {
        width : 100%;
        margin-right : 15px;
    }
  }

  .automation-dropdown__control, .automation-dropdown__menu, .automation-dropdown__menu-list, .automation-operator-dropdown__menu, .automation-operator-dropdown__menu-list {
    border: none !important;
    border-radius: 8px !important;
    background: #222 !important;
    color: var(--lightPrimaryRoi) !important;
    box-shadow: none !important;
  }

  .automation-dropdown__control--is-disabled {
    background-color : #3B3A3A !important;
    color : gray !important;
  }

  .automation-dropdown__single-value {
    color: #fff !important;
  }

  .automation-dropdown__option:hover, .automation-dropdown__option--is-selected, .automation-dropdown__option--is-focused, .automation-dropdown__option:active, .automation-operator-dropdown__option:hover, .automation-operator-dropdown__option--is-selected, .automation-operator-dropdown__option--is-focused, .automation-operator-dropdown__option:active {
    background: var(--primary) !important;
  }

  .automation-dropdown__indicator-separator, .automation-operator-dropdown__indicator-separator {
    width: 0 !important;
  }

  .automation-operator-dropdown__control {
    border: none !important;
    border-radius: 20px !important;
    background: var(--primary) !important;
    color: #FFFFFF !important;
    box-shadow: none !important;
  }

  .automation-operator-dropdown__single-value, .automation-operator-dropdown__placeholder, .automation-operator-dropdown__dropdown-indicator {
    color: #FFFFFF !important;
  }

  .automation-operator {
    margin: 0 auto;
    width: 105px;
  }

  .automation-form-dropdown-multi {
    font-size: 14px;
    background-color: #222;
    color: var(--lightPrimaryRoi);
    border: none;
    border-radius: 20px;
  }

  .automation-form-dropdown-multi .automation-dropdown__multi-value__label, .automation-form-dropdown-multi .automation-dropdown__single-value {
    background-color: #222;
    color: var(--lightPrimaryRoi);
    border-radius: 0;
  }

  .automation-form-dropdown-multi .automation-dropdown__multi-value__remove {
    background-color: #222;
    color: red;
    border-radius: 0;
  }

  .automation-form-dropdown-multi .automation-dropdown__clear-indicator {
    display: none;
  }

  .automation-form-dropdown-multi .css-b8ldur-Input, .automation-ifelse-third-column .css-b8ldur-Input {
    color: var(--lightPrimaryRoi)
  }

  .automation-alert-input, .automation-alert-input:focus, .automation-ifelse-third-column, .automation-ifelse-third-column:focus, .automation-form-input, .automation-form-input:focus, .automation-form-input-time, .automation-form-input-time:focus, .automation-email-input, .automation-email-input:focus, .automation-email-domain {
    color: var(--lightPrimaryRoi);
    background-color: #222;
    border: none;
  }

  .automation-form-dropdown-multi .automation-dropdown__multi-value__label {
    white-space: normal;
  }

  .automation-dropdown__control--is-disabled {
    background-color: #3B3A3A !important;
    color: grey !important;

    .automation-dropdown__multi-value__label, 
    .automation-dropdown__multi-value__remove, 
    .automation-dropdown__single-value--is-disabled {
      background-color: #3B3A3A !important;
      color: grey !important;
    }
  }

  .automation-form-input-time:disabled, 
  .input-within-last:disabled, 
  .automation-email-input:disabled, 
  .input-delay:disabled,
  .automation-alert-input:disabled,
  .custom-input-dropdown__control--is-disabled,
  .automation-form-dropdown-multi:disabled {
    background-color: #3B3A3A !important;
    color: grey !important;
  }

  .automation-operator-remove {
    color: var(--danger-color);
    margin-right: 15px;
  }

  .automation-add-operator {
    background-color: var(--white);
    color: var(--black);
  }

  .automation-email-domain {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: var(--primary);
  }

  .automation-email-input-form-email {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .automation-trigger-matching-loading {
    display: block;
    margin: 0 auto;
    color: white;
  }

  .automation-trigger-matching-text, .automation-trigger-matching-text:hover {
    font-size: 14px;
    margin-bottom: 0;
    color: var(--lightColorRoi);
  }

  .automation-trigger-matching-number, .automation-trigger-matching-number:hover {
    font-size: 16px;
    color: var(--primary);
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type=number] {
    -moz-appearance: textfield;
  }

  .container-fluid .tab-content {
    height: 100%;
  }

  .textarea-form {
    height: 94px !important;
  }

  .textarea-form:focus {
    background-color: #222;
    color: var(--lightPrimaryRoi);
  }

  /* automation time input */
  .automation-finalise-input-hours {
    background-color: transparent;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
    text-align: left;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #222;
  }

  .automation-finalise-input-hours:hover, .automation-finalise-input-hours:active {
    background-color: transparent !important;
    color: #222 !important;
    border-radius: 0;
  }
 
  .automation-story-sidebar-input-hours {
    background-color: ${WhiteColor};
    border: 0;
    text-align: left;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 2.5;
    color: #222;
  }

  .automation-story-sidebar-input-hours:hover, .automation-story-sidebar-input-hours:active {
    background-color: ${WhiteColor} !important;
    color: #222 !important;
    border-radius: 0;
  }

  .automation-finalise-time>.react-datepicker-wrapper {
    width: 75%;
  }

  .automation-finalise-time .react-datepicker-wrapper .btn {
    height: 25px;
    padding-bottom: 25px !important;
  }

  /* automation manage */
  .automation-manage {
    padding: 0 !important;
  }
  .automation-manage:focus,
  .automation-manage:active {
    border: 0 !important;
  }

  .btn-group-automation-switch-content {
    width: 100%;
  }
  .btn-automation-switch-content {
    border-radius: 0;
  }

  .ag-center-cols-container {
    width: 100% !important;
  }

  /* Region Automation Manage custom styling */
  .nav-tabs .nav-link.automation-grid {
    color: gray !important;
    border: none !important;
  }

  .nav-tabs .nav-link.active.automation-grid {
    color: var(--white) !important;
    background-color: var(--secondary-color) !important;
    border: none !important;
  }

  .margin-y-1rem {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

export const DatepickerButton = styled(Button)`
    color : #707070 !important;
    font-size : 12px !important;
    border : solid 1px #707070;
`;

export const DateFormGroup = styled(FormGroup)`
  display : inherit;
  padding : 5px 35px 5px 35px;
`

export const AutomationDateSpan = styled.div`
   font-family : 'Roboto';
   font-size : 12px;
   width : 55px;

   input {
      ${(props) => props.checked && `color : #707070;`}
   }

   label::before {
     height : 14px !important;
     font-size : 10px !important;
     background-color : ${PrimaryColor} !important;
     padding : 2px !important;
   }

   @media (max-width : 450px) {
       margin-left : 1px !important;
   }

   @media (max-width : 360px) {
       margin-left : 1px !important;
   }

   @media (max-width : 900px) {
       margin-left : 1px !important;
   }
`;

export const AutomationDateCheckbox = styled(Input)`
   font-family : 'Roboto';
   font-size : 12px;
`;

export const HeaderSaveButton = styled(Button)`
    ${(props) => !props.isblack && `
      background-color : white !important;
      color : var(--dark) !important;
      &:hover {
        background-color : var(--secondary) !important;
      }
      `
  }
`;

export const AutomationLabelSchedule = styled(Label)`
    font-family : 'Roboto';
    font-size : 12px;
    color : #707070;
`;

export const SelectButton = styled(Button)`
    background-color : white !important;
    color : var(--secondary) !important;
    font-size : .875rem;
    font-family : 'Roboto';
    &:hover {
      background-color : var(--secondary) !important;
      color : var(--white) !important;
    }
`;

export const AutomationItem = styled.div`
    div {
      font-family: 'Roboto';
      font-size : 12px;

      img {
         width : 28px;
      }
    }
`;

export const TextCount = styled(Label)`
    position: absolute;
    right: 34px;
    bottom: 31px;
    color: #5C6570;
`;


export const TotalTime = styled.div`
    font-size: 10px;
    text-align: center;
    margin-top: 15px;
`;

export const ButtonDropdown = styled(UncontrolledDropdown)`
    button {
        width: 200px;
        border-radius: 10px;
    }

    div.dropdown-menu {
      border-radius: 10px;
      margin-top : 5px;
    }

    button.dropdown-toggle {
      border-radius: 40px;
      background-color: ${PrimaryColor};

      :focus {
        background-color: ${PrimaryColor};
      }

      :click {
        background-color: ${PrimaryColor};
      }
    }

    &.dropdown.show {
       button {
         background-color: ${PrimaryColor};
       }
    }

    button.dropdown-item:hover {
      border-radius: 10px;
      background-color: ${PrimaryColor};
      color: ${WhiteColor};
    }
`;
export const HeaderButtonDropdown = styled(UncontrolledDropdown)`
    button {
        width: 150px;
    }
`;

export const PersonalDropdownToggle = styled(DropdownToggle)`
  background-color : ${SecondaryColor};
  border-color : ${SecondaryColor};
  font-family : 'Roboto';

  &.primary {
      background-color : ${PrimaryColor};
  }
`;

export const EmailEditorButton = styled(Button)`
  margin-top : 0px !important;
  padding : 4px 10px 4px 10px !important;
  font-size : 0.875rem !important;
`

export const SwitchContainer = styled.div`
  height : 42px !important;
  padding-top : 5px;
`

export const SwitchLabel = styled(Label)`
  position: absolute;
  top: 1px;
  margin-left: 10px;
`
export const SidebarFormSelect = styled(Select)`
    font-size : 0.875rem !important;

    .automation-dropdown__control {
      min-height : 0px !important;
    }
`

export const LabelField = styled(Label)`
    font-family : 'Roboto';
    font-size : 12px;
    color : ${WhiteColor};
`;

export const RadioAutomation = styled(Input)`
    &:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: ${WhiteColor};
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid ${LightGrayColor};
    }

    &:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: ${PrimaryColor};
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid ${LightGrayColor};
    }
`

export const CustomCol = styled(Col)`
  &.disabled {
      color : ${LightGrayColor};
  }
`
