import { ApiService, ApiUrl, Constants, FormatDate } from "helpers";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';
import { FormatDateUtc } from "helpers/FormatDateTable";
import { SurveyReportsFieldName, SurveyResponsesDefaultHeaderCsv, SurveyReportsDefaultHeaderCsv } from "helpers/Constants";
import { SurveyReportsGridColumnName } from "views/Reports/Survey/SurveyReportsGrid/SurveyReportsGridConstant";

const surveyReportStore = observable(
    {
        surveyReports: [],
        filteredSurveyReports: [],
        filterSurveyReportsModel: {},
        filterSearchText: "",
        surveyReportChartConfigDataset: [],
        dataId: undefined,
        surveyName: undefined,
        selectedReport: undefined,
        selectedReportCompletion: undefined,
        selectedReportLastSendDate: '-',
        surveyResponses: [],
        surveyReportDetailPercentage: [],
        responseCandidateName: null,
        responseCampaignName: null,
        responseSurveyName: null,
        reponseAutomationName: "N/A",
        responseCompletionDate: null,
        surveyCompletions: [],
        surveyOpened: [],
        surveyReportDropdownAutomation: [{ value: 0, label: 'ALL' }],
        surveyReportDropdownCampaign: [{ value: null, label: 'ALL' }],
        surveyCount: {
            totalUsers: 0,
            totalOpened: 0,
            totalCompletions: 0,
            openedPercentage: 0,
            completionPercentage: 0
        },
        chartInstance: null,
        comingFromType: 5,
        filterGrid: [],
        sortGrid: [],
        agGridRowsPage: 0,
        agGridTotalRow: 0,
        agGridTotalPage: 0,
        surveyReportSelectedAutomation: 0,
        surveyReportSelectedCampaign: null,
        surveyCompletionResponses: [],
        surveyCompletionResponsesHeader: [],
        exportDataSurveyReport: [],
        exportHeaderSurveyReport: [],

        gridColumn: [
            {
                id: 1,
                colId: SurveyReportsFieldName.SURVEY_NAME,
                headerName: SurveyReportsGridColumnName.SurveyName,
                field: SurveyReportsFieldName.SURVEY_NAME,
                minWidth: 220,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            // {
            //     id: 2,
            //     colId: SurveyReportsFieldName.CAMPAIGN_NAME,
            //     headerName: SurveyReportsGridColumnName.EmailOrTextName,
            //     field: SurveyReportsFieldName.CAMPAIGN_NAME,
            //     minWidth: 220,
            //     hide: false,
            //     hideColConfig: false,
            //     checkedColConfig: true,
            // },
            // {
            //     id: 3,
            //     colId: SurveyReportsFieldName.AUTOMATION_NAME,
            //     headerName: SurveyReportsGridColumnName.AutomationName,
            //     field: SurveyReportsFieldName.AUTOMATION_NAME,
            //     minWidth: 220,
            //     hide: false,
            //     hideColConfig: false,
            //     checkedColConfig: true,
            // },
            {
                id: 2,
                colId: SurveyReportsFieldName.LAST_SEND_DATE,
                headerName: SurveyReportsGridColumnName.LastSendDate,
                field: SurveyReportsFieldName.LAST_SEND_DATE,
                minWidth: 180,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 3,
                colId: SurveyReportsFieldName.TOTAL_USERS,
                headerName: SurveyReportsGridColumnName.TotalUsers,
                field: SurveyReportsFieldName.TOTAL_USERS,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 4,
                colId: SurveyReportsFieldName.TOTAL_OPENED,
                headerName: SurveyReportsGridColumnName.Opened,
                field: SurveyReportsFieldName.TOTAL_OPENED,
                minWidth: 50,
                filter: true,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 5,
                colId: SurveyReportsFieldName.TOTAL_COMPLETIONS,
                headerName: SurveyReportsGridColumnName.Completions,
                field: SurveyReportsFieldName.TOTAL_COMPLETIONS,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
        ],

        setSurveyCompletionResponses(surveyCompletionResponses) {
            this.surveyCompletionResponses = [...surveyCompletionResponses];
        },

        setSurveyCompletionResponsesHeader(surveyCompletionResponsesHeader) {
            this.surveyCompletionResponsesHeader = [...surveyCompletionResponsesHeader];
        },

        setExportDataSurveyReport(exportDataSurveyReport) {
            this.exportDataSurveyReport = [...exportDataSurveyReport];
        },

        setExportHeaderSurveyReport(exportHeaderSurveyReport) {
            this.exportHeaderSurveyReport = [...exportHeaderSurveyReport];
        },

        setSurveyReportSelectedAutomation(surveyReportSelectedAutomation) {
            this.surveyReportSelectedAutomation = surveyReportSelectedAutomation;

            if (surveyReportSelectedAutomation === 0) {
                this.setSurveyReportSelectedCampaign(null);
            }
        },

        setSurveyReportSelectedCampaign(surveyReportSelectedCampaign) {
            this.surveyReportSelectedCampaign = surveyReportSelectedCampaign;
        },

        setFilterGrid(filterGrid) {
            this.filterGrid = filterGrid;
        },

        setSortGrid(sortGrid) {
            this.sortGrid = sortGrid;
        },

        setAgGridRowsPage(agGridRowsPage) {
            this.agGridRowsPage = agGridRowsPage;
        },

        setAgGridTotalRow(agGridTotalRow) {
            this.agGridTotalRow = agGridTotalRow;
        },

        setAgGridTotalPage(agGridTotalPage) {
            this.agGridTotalPage = agGridTotalPage;
        },

        updateGridColumn(gridColumnData) {
            this.gridColumn = gridColumnData;
        },

        setResponseCandidateName(responseCandidateName) {
            this.responseCandidateName = responseCandidateName;
        },

        setComingFromType(type) {
            this.comingFromType = type
        },

        setSelectedReportLastSendDate(date) {
            this.selectedReportLastSendDate = FormatDateUtc(date, false);
        },

        setResponseCampaignName(responseCampaignName) {
            this.responseCampaignName = responseCampaignName;
        },

        setResponseSurveyName(responseSurveyName) {
            this.responseSurveyName = responseSurveyName;
        },

        setResponseAutomationName(reponseAutomationName) {
            this.reponseAutomationName = reponseAutomationName;
        },

        setCompletionDate(responseCompletionDate) {
            this.responseCompletionDate = responseCompletionDate;
        },

        setSurveyReportDetailPercentage(surveyReportDetailPercentage) {
            this.surveyReportDetailPercentage = [];
            this.surveyReportDetailPercentage = [...surveyReportDetailPercentage];
        },

        setDataId(id) {
            this.dataId = id
        },

        setSelectedReport(report) {
            this.selectedReport = report
        },

        setSelectedReportCompletion(completion) {
            this.selectedReportCompletion = completion
        },

        setSurveyName(name) {
            this.surveyName = name
        },

        setSurveyReports(reports) {
            this.surveyReports = reports
            this.filteredSurveyReports = [...reports]
            this.filterSurveyReportsModel = {}
        },

        setfilterSurveyReportsModel(filter) {
            this.filterSurveyReportsModel = filter;
        },

        setSurveyReportsClient(reports) {
            this.surveyReportsClient = reports
        },

        setSurveyReportChartConfigDataset(dataset) {
            this.surveyReportChartConfigDataset = dataset;
        },

        setSurveyCompletions(surveyCompletions) {
            this.surveyCompletions = [...surveyCompletions];
        },

        setSurveyOpened(surveyOpened) {
            this.surveyOpened = [...surveyOpened];
        },

        setChartInstance(chartInstance) {
            this.chartInstance = chartInstance;
        },

        getDataId() {
            return this.dataId
        },

        setSurveyResponses(surveyResponses) {
            this.surveyResponses = [...surveyResponses];
        },

        setSurveyReportDropdownAutomation(surveyReportDropdownAutomation) {
            const automationOptions = surveyReportDropdownAutomation.map((x) => ({ value: x.automationId, label: x.automationName }));
            this.surveyReportDropdownAutomation = [this.surveyReportDropdownAutomation[0], ...automationOptions];
        },

        setSurveyCount(surveyCount) {
            this.surveyCount = { ...surveyCount };
        },

        setSurveyReportDropdownCampaign(campaignReports) {
            this.setSurveyReportSelectedCampaign(null); //reset selected campaign 
            let campaignDropdown = campaignReports.length > 0 ? campaignReports.map((x) => ({ value: x.campaignReportToken, label: x.campaignName })) : [];
            this.surveyReportDropdownCampaign = [this.surveyReportDropdownCampaign[0], ...campaignDropdown];
        },

        resetSurveyReportStore() {
            this.surveyReports = [];
            this.surveyReportChartConfigDataset = [];
            this.dataId = undefined;
            this.surveyName = undefined;
            this.selectedReport = undefined;
            this.selectedReportCompletion = undefined;
            this.selectedReportLastSendDate = "-";
            this.surveyResponses = [];
            this.surveyReportDetailPercentage = [];
            this.responseCandidateName = null;
            this.responseCampaignName = null;
            this.responseSurveyName = null;
            this.reponseAutomationName = "N/A";
            this.responseCompletionDate = null;
            this.surveyCompletions = [];
            this.surveyOpened = [];
            this.chartInstance = null;
            this.comingFromType = 5;
            this.surveyReportDropdownAutomation = [{ value: 0, label: 'ALL' }];
            this.surveyReportDropdownCampaign = [{ value: null, label: 'ALL' }];
            this.surveyReportSelectedAutomation = 0;
            this.surveyReportSelectedCampaign = null;
            this.surveyCount = {
                totalUsers: 0,
                totalOpened: 0,
                totalCompletions: 0,
                openedPercentage: 0,
                completionPercentage: 0
            };
            this.surveyCompletionResponses = [];
            this.surveyCompletionResponsesHeader = [];
            this.exportDataSurveyReport = [];
            this.exportHeaderSurveyReport = [];
        },

        getSurveyResponses(candidateCompletionId, token, surveyId, candidateId) {
            return (ApiService.get(ApiUrl.getSurveyCompletion + `${candidateCompletionId}/${surveyId}/${token}/${candidateId}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            const data = response.data.result;
                            this.setSurveyResponses(data.surveyResponses);
                            this.setResponseCandidateName(data.candidateName);
                            this.setResponseCampaignName(data.campaignName);
                            this.setResponseSurveyName(data.surveyName);
                            this.setResponseAutomationName(data.automationName);
                            this.setCompletionDate(data.completedAt);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));

        },

        getSurveyReportDetail(surveyId, surveyReportToken) {
            return (ApiService.get(ApiUrl.getSurveyReports + `/${surveyId}/${surveyReportToken}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            if (response.data.result) {
                                this.setSelectedReport(response.data.result);
                                this.setSurveyReportDetailPercentage([
                                    response.data.result.totalOpenedPercentage.toFixed(2),
                                    response.data.result.totalCompletionsPercentage.toFixed(2),
                                ]);
                                this.setSelectedReportLastSendDate(response.data.result?.reports.lastSendDate)
                            } else {
                                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                            }
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        // getSurveyReportCompletion(surveyId, surveyReportToken){
        //     return (ApiService.get(ApiUrl.getSurveyCompletionReport+ `/${surveyId}/${surveyReportToken}`).then(
        //         action(
        //             response => {
        //                 if (response && response.status === 200 && response.data && !response.data.isError) {
        //                     this.setSurveyCompletions(response.data.result);
        //                 } else {
        //                     Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
        //                 }
        //                 return Promise.resolve(response.data);
        //             }
        //         )
        //     ).catch(
        //         action(
        //             err => {
        //                 Alert.error(err);
        //                 return Promise.reject(err);
        //             }
        //         )
        //     ));
        // },

        // getSurveyReportOpened(surveyId, surveyReportToken){
        //     return (ApiService.get(ApiUrl.getSurveyOpenReport+ `/${surveyId}/${surveyReportToken}`).then(
        //         action(
        //             response => {
        //                 if (response && response.status === 200 && response.data && !response.data.isError) {
        //                     this.setSurveyOpened(response.data.result)
        //                 } else {
        //                     Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
        //                 }
        //                 return Promise.resolve(response.data);
        //             }
        //         )
        //     ).catch(
        //         action(
        //             err => {
        //                 Alert.error(err);
        //                 return Promise.reject(err);
        //             }
        //         )
        //     ));
        // },

        getSurveyReportServerSide(request) {
            const body = {
                param: JSON.stringify(request)
            };

            return (ApiService.post(`${ApiUrl.getSurveyReports}`, body).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setAgGridTotalRow(response.data.result.totalCount);
                            return Promise.resolve(response.data);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);

                            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ))
        },
        getSurveyReports() {
            return (ApiService.get(ApiUrl.getAllSurveyGroupReports).then(
                action(
                    response => {
                        this.setSurveyReports(response.data.result);
                        return Promise.resolve(response);
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyReportDetailById(surveyId, automationId = null, campaignReportToken = null, isCampaignChange = false) {
            const params = `/${surveyId}${automationId ? '/' + automationId : ''}${campaignReportToken ? '/' + campaignReportToken : ''}`;

            return (ApiService.get(ApiUrl.getSurveyGroupReports + params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            if (response.data.result) {
                                const { totalUsers, totalOpened, totalCompletions, totalOpenedPercentage, totalCompletionsPercentage, automations, reports, lastSendDate, surveyName } = response.data.result;

                                if (!isCampaignChange) {
                                    this.setSurveyReportDropdownCampaign(reports);
                                }
                                this.setSurveyReportDropdownAutomation(automations);
                                this.setSurveyReportDetailPercentage([totalOpenedPercentage.toFixed(2), totalCompletionsPercentage.toFixed(2)]);
                                this.setSurveyName(surveyName);
                                this.setSelectedReportLastSendDate(lastSendDate);
                                this.setSurveyCount({
                                    totalUsers: totalUsers,
                                    totalOpened: totalOpened,
                                    totalCompletions: totalCompletions,
                                    openedPercentage: totalOpenedPercentage,
                                    completionPercentage: totalCompletionsPercentage
                                });
                            } else {
                                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                            }
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyReportTotalOpened(surveyId, automationId, campaignReportToken) {

            const campaignTokenSelected = campaignReportToken ? "/" + campaignReportToken : "";
            const automationIdSelected = automationId || 0;

            return (ApiService.get(ApiUrl.getSurveyReportTotalOpened + `/${surveyId}/${automationIdSelected}${campaignTokenSelected}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setSurveyOpened(response.data.result)
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyReportTotalCompletion(surveyId, automationId, campaignReportToken) {

            const campaignTokenSelected = campaignReportToken ? "/" + campaignReportToken : "";
            const automationIdSelected = automationId || 0;

            return (ApiService.get(ApiUrl.getSurveyReportTotalCompletion + `/${surveyId}/${automationIdSelected}${campaignTokenSelected}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setSurveyCompletions(response.data.result);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyCompletionResponses(surveyId) {
            return (ApiService.get(ApiUrl.getSurveyCompletionResponses + surveyId).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            const data = response.data.result;
                            let csvData = [];
                            let csvHeader = [...SurveyResponsesDefaultHeaderCsv];
                            data.forEach(item => {
                                const { candidateId, candidateName = "", email = "", mobile = "", completionDate = "", responses = [] } = item;
                                let candidateDetails = { candidateId, candidateName, email, mobile, completionDate };

                                //for add question in csv header
                                if (csvHeader.length <= 5) {
                                    for (var i = 0; i < responses.length; i++) {
                                        const header = {
                                            label: responses[i].surveyQuestion,
                                            key: `answer-${i}`
                                        }
                                        csvHeader.push(header);
                                    }
                                }
                                //for add answer in csv data
                                for (let i = 0; i < responses.length; i++) {
                                    const answer = responses[i].surveyAnswer || "";
                                    const propertyName = `answer-${i}`;
                                    candidateDetails[propertyName] = answer;
                                }
                                candidateDetails.completionDate = FormatDateUtc(completionDate);
                                csvData.push(candidateDetails);
                            });
                            this.setSurveyCompletionResponses(csvData);
                            this.setSurveyCompletionResponsesHeader(csvHeader);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        modifySurveyReportsExportToCsv(defaultHeader, data) {
            let csvData = [];
            let csvHeader = [...defaultHeader];
            data.forEach(item => {
                const { totalCompletionsPercentage = 0, totalOpenedPercentage = 0, totalUsers = 0, reports = undefined } = item;
                let surveyReports = {};
                if (reports) {
                    surveyReports["surveyName"] = reports.surveyName;
                    surveyReports["lastSendDate"] = FormatDateUtc(reports.lastSendDate);
                    surveyReports["totalUsers"] = reports.totalUsers;
                    surveyReports["totalOpened"] = reports.totalOpened;
                    surveyReports["totalCompletions"] = reports.totalCompletions;
                    surveyReports["totalCompletionsPercentage"] = totalCompletionsPercentage.toFixed(2)
                    surveyReports["totalOpenedPercentage"] = totalOpenedPercentage.toFixed(2)
                    surveyReports["totalUsers"] = totalUsers
                }
                csvData.push(surveyReports);
            });
            this.setExportDataSurveyReport(csvData);
            this.setExportHeaderSurveyReport(csvHeader);
        },

        getSurveyReportsToCsv() {
            return (ApiService.get(ApiUrl.getSurveyReportCsv).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.modifySurveyReportsExportToCsv(SurveyReportsDefaultHeaderCsv, response.data.result);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        setSurveyReportSearchFilterString(searchString) {
            const { FormatDateUtc } = FormatDate;
            //TODO: possible extract to helpers for searching strings, or extract to store helpers
            function ApplySearchStringIfCreatedDateExists(item, searchString) {
                return !!item.dateCreated && FormatDateUtc(item?.dateCreated).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
            }

            this.filterSearchText = searchString;

            this.filteredSurveyReports = this.surveyReports.filter(item =>
                ApplySearchStringIfCreatedDateExists(item, searchString) ||
                item.surveyName?.toLowerCase().includes(searchString.toLowerCase()) ||
                item.category?.toLowerCase().includes(searchString.toLowerCase()) ||
                item.createdBy?.toLowerCase().includes(searchString.toLowerCase()) ||
                item.dateCreated?.toLowerCase().includes(searchString.toLowerCase())
            );
        },
    }
);

export default surveyReportStore;
