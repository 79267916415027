import moment from 'moment';

const FormatDateTable = (e) => {
    let createDate = new Date(e.value);
    let date = moment(createDate).format('DD MMM YYYY');
    return date
}

const FormatDateUtc = (dateTimeFromDb, isShowTime = true) => {
    return moment.utc(dateTimeFromDb).local().format(`DD MMM YYYY ${isShowTime ? 'h:mma' : ''}`);
}

const FormatDateUtcWithFullMonth = (dateTimeFromDb, isShowTime = true) => {
    return moment.utc(dateTimeFromDb).local().format(`DD MMMM YYYY ${isShowTime ? 'h:mma' : ''}`);
}


export {FormatDateTable, FormatDateUtc, FormatDateUtcWithFullMonth}