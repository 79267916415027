import { Enum } from "helpers";

export function validatorUserInputLength(value, size) {
  if (value.length > size) {
    return Enum.ValidationStatus.InvalidLength
  } else {
    return Enum.ValidationStatus.Valid
  }
}

export function validatorUserInputEmail(value, size) {
  //eslint-disable-next-line
  if (/^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9_.+-]+)$/.test(value) && value.length <= size) {
    return Enum.ValidationStatus.Valid;
  }
  //eslint-disable-next-line
  if (!/^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9_.+-]+)$/.test(value)) {
    return Enum.ValidationStatus.InvalidEmail;
  }
  if (value.length > size) {
    return Enum.ValidationStatus.InvalidLength
  }
}

export function validatorUserInputNumber(value, size) {

  if (!isNaN(value) && value && value > 0 && value.length <= size) {
    return Enum.ValidationStatus.Valid;
  }
  if (isNaN(value) && value) {
    return Enum.ValidationStatus.InvalidNumber;
  }
  if (value.length > size) {
    return Enum.ValidationStatus.InvalidLength
  }
}
