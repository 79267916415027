import { observable, action } from "mobx";
import { ApiUrl, ApiService } from "../helpers";
//import { useLocalStore } from "mobx-react"; // 6.x or mobx-react-lite@1.4.0

const userStore = observable(
  {
    id: null,
    user: {},
    isLoading: false,

    resetUser() {
      this.user = {};
    },

    setUser(user) {
      this.user = user;
    },

    getUserDetail(id) {
      this.isLoading = true;
      return ApiService.getDetail(ApiUrl.detailUser, id, "", "")
        .then(
          action(response => {
            if (
              response &&
              response.status === 200 &&
              response.data &&
              !response.data.isError
            ) {
              this.setUser(response.data.result);
              return response.data.result;
            }
            return response;
          })
        )
        .catch(
          action(err => {
            console.log(err);
          })
        )
        .finally(action(() => (this.isLoading = false)));
    }
  },
  {
    resetUser: action,
    setUser: action,
    getUserDetail: action
  }
);

export default userStore;
