export function isEmpty(obj) {return Object.keys(obj).length === 0;}

export function isEmptyArray(array) {return array.length === 0;}

export function isNullOrUndefined(value) { return value === null || value === undefined;}

export function isPlainObject(obj) {
    return typeof obj === 'object'  // separate from primitives
        && obj !== null            // is obvious
        && obj.constructor === Object // separate instances (Array, DOM, ...)
        && Object.prototype.toString.call(obj) === '[object Object]'; // separate native objects (String, Number, ...)
  }

export function isStringEmpty(str) {
  return (!str || /^\s*$/.test(str));
}