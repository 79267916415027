
const HeaderForm = ({
    automationHeaderContainerClassName = "",
    automationHeaderId = "form-step-id",
    automationHeaderImgClassName = "",
    automationHeaderImgSrc = "",
    automationHeaderImgAlt = "icon-placeholder",
    automationHeaderTextStep = "Automation Step"
}) => (
    <div className={`step-option ${automationHeaderContainerClassName}`} id={automationHeaderId}>
        <img src={automationHeaderImgSrc} className={`step-icon ${automationHeaderImgClassName}`} alt={automationHeaderImgAlt} /> {automationHeaderTextStep}
    </div>
);

export default HeaderForm;