import React from "react";
import { chatbotStore } from 'stores'
import './customMessageChatbot.css'

const Handlebars = require("handlebars");

// initialize variable for autoclose setTimeout function
let timeout;

const CustomMessageChatbot = ({ message, isEndStep }) => {

  const closeOnInactive = () => {
    const { finalizeAutoCloseBotTime, disabledFinalizeAutoCloseBot } = chatbotStore;
    if (!disabledFinalizeAutoCloseBot) {
      clearTimeout(timeout);

      // close the preview after delay finalizeAutoCloseBotTime*1000*60 (eg: 5*1000 = 5000ms *60seconds = 300000ms) /// 300000ms = 5 minutes
      timeout = setTimeout(() => {
        chatbotStore.setIsPreviewChatbot(false)
      }, finalizeAutoCloseBotTime * 1000 * 60);
    }
  }

  const createLinkPreview = () => {

    // count how many link matches by number of '<<' in string
    let count = (message.match(/<</g) || []).length;
    let finalString = message

    for (let index = 0; index < count; index++) {
      let dataLink = finalString.split("|")[1];
      let dataText = finalString.split("|")[0];
      dataLink = dataLink.split(">>")[0];
      dataText = dataText.split("<<")[1];

      if (!dataLink.startsWith('http://') && !dataLink.startsWith('https://')) {
        // adding double slash to make string without http or https as normal link
        dataLink = `//${dataLink}`
      }
      const urlData = {
        textUrl: dataText,
        url: dataLink
      };
      let link = `<a href="${urlData.url}" target="_blank">${urlData.textUrl}</a>`

      // replace FIRST <<text|link>> to <a href> tag
      finalString = finalString.replace(/<<[^>]*>>/, link)
    }

    return createMarkup(finalString)
  }

  const createMarkup = (finalString) => {
    let linkComponent = finalString;
    let template = Handlebars.compile(linkComponent);
    let resultTemp = template(finalString);
    resultTemp = resultTemp.replace(/\r\n|\r|\n/g, "</br>"); // replace 'enter key' textarea to </br>

    return { __html: resultTemp };
  }

  // hide the END step
  if (isEndStep) {
    let items = document.getElementsByClassName("rsc-ts-bubble");
    let lastchild = items[items.length - 1];
    lastchild.classList.add("d-none");
  }

  closeOnInactive();

  return <div className="wrap-text" dangerouslySetInnerHTML={createLinkPreview()} id={isEndStep ? "end-step" : undefined} />;
};

export default CustomMessageChatbot;
