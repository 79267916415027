import { observable, action } from "mobx";
import Alert from 'react-s-alert';
import { Enum, ApiService, ApiUrl, Constants } from "helpers";
import { emailStore } from "stores";
import { EmailSenderDefault } from "helpers/Constants";
import { ContactType } from "helpers/enum";

const campaignStore = observable({
  audienceRecipients: [],
  audienceRecipientIds: [],
  audienceClientRecipients: [],
  audienceClientRecipientIds: [],
  individualRecipients: [],
  campaignMessage: "",
  campaignTextOnly: "",
  campaignBlastMethod: 1,
  isEmailMethod: false,
  isTextMethod: false,
  isWhatsappMethod: false,
  isFormDirty: false,
  isFormHasEdited: false,
  isSubmitting: false,
  campaignToken: [
    "first_name",
    "last_name",
    "email",
    "mobile",
    "current_job_title",
    "current_employer",
    "address1",
    "address2",
    "city",
    "state",
    "country",
    "zip",
    "url_data"
  ],
  formName: EmailSenderDefault.Name,
  formEmail: EmailSenderDefault.Email,
  formEmailDomain: null,
  formDomainList: [],
  subject: "",
  countryCode: "au",  // default value to au
  title: "",
  showConvertLink: "",
  emailTemplate: {},
  campaignContactType: ContactType.Candidate,

  resetAll() {
    this.audienceRecipients = [];
    this.audienceRecipientIds = [];
    this.audienceClientRecipients = [];
    this.audienceClientRecipientIds = [];
    this.individualRecipients = [];
    this.campaignMessage = "";
    this.campaignTextOnly = "";
    this.isFormDirty = false;
    this.isFormHasEdited = false;
    this.isSubmitting = false;
    this.selectedEmailTemplate = {};
    this.formName = EmailSenderDefault.Name;
    this.formEmail = EmailSenderDefault.Email;
    this.formEmailDomain = null;
    this.subject = "";
    this.countryCode = "au";
    this.title = "";
    this.showConvertLink = "";
    this.emailTemplate = {};
    this.campaignContactType = Enum.ContactType.Candidate;
  },

  resetMessage() {
    this.campaignMessage = "";
  },

  setEmailTemplate() {
    const jsonPage = JSON.parse(emailStore.jsonTemplate);
    const jsonTemp = JSON.stringify({
      page: jsonPage.page ? jsonPage.page : jsonPage,
      comment: {}
    })
    this.emailTemplate = {
      id: emailStore.id,
      campaignName: emailStore.campaignName,
      jsonTemplate: jsonTemp,
      htmlTemplate: emailStore.htmlTemplate,
      description: emailStore.description,
      tagCategories: JSON.stringify(emailStore.tagCategories.map((item) => {
        return item["value"];
      })),
      isUseEmailTemplate: emailStore.isUseEmailTemplate
    };
    this.setIsFormDirty(false);
  },

  setCampaignContactType(campaignContactType) {
    this.campaignContactType = campaignContactType;
  },

  setShowConvertLink(showConvertLink) {
    this.showConvertLink = showConvertLink;
  },

  addAudienceClientRecipients(clientAudience) {
    this.audienceClientRecipients = [...this.audienceClientRecipients, clientAudience];
    this.setIsFormDirty(true);
  },

  addAudienceClientRecipientsIds(clientAudienceIds) {
    this.audienceClientRecipientIds = [...this.audienceClientRecipientIds, clientAudienceIds];
    this.setIsFormDirty(true);
  },

  setAudienceClientRecipients(clientAudience) {
    this.audienceClientRecipients = [];
    this.audienceClientRecipients = [...clientAudience];
    this.setIsFormDirty(true);
  },

  setAudienceClientRecipientsIds(clientAudienceIds) {
    this.audienceClientRecipientIds = [];
    this.audienceClientRecipientIds = [...clientAudienceIds];
    this.setIsFormDirty(true);
  },

  addAudienceRecipients(audience) {
    this.audienceRecipients = audience;
    this.setIsFormDirty(true);
  },

  addAudienceRecipientsIds(audienceIds) {
    this.audienceRecipientIds = audienceIds;
    this.setIsFormDirty(true);
  },

  pushAudienceRecipients(audienceRecipient) {
    this.audienceRecipients.push(audienceRecipient);
    this.setIsFormDirty(true);
  },

  pushAudienceRecipientsIds(audienceRecipientIds) {
    this.audienceRecipientIds.push(audienceRecipientIds);
  },

  deleteAudienceRecipient(audienceId) {
    this.audienceRecipients = this.audienceRecipients.filter(x => x.id !== audienceId);
    let index = this.audienceRecipientIds.indexOf(audienceId)
    if (index !== -1) {
      this.audienceRecipientIds.splice(index, 1);
    };
  },

  deleteAudienceClientRecipient(audienceId) {
    this.audienceClientRecipients = this.audienceClientRecipients.filter(x => x.id !== audienceId);
    let index = this.audienceClientRecipientIds.indexOf(audienceId)
    if (index !== -1) {
      this.audienceClientRecipientIds.splice(index, 1);
    };
  },

  setIndividualRecipients(individual) {
    this.individualRecipients = individual;
    this.setIsFormDirty(true);
  },

  deleteIndividualRecipient(individual) {
    let index = this.individualRecipients.indexOf(individual);
    this.individualRecipients.splice(index, 1);
    this.setIsFormDirty(true);
  },

  setCampaignMessage(message) {
    this.campaignMessage = message;
    this.setIsFormDirty(true);
  },

  setCampaignTextOnly(message) {
    this.campaignTextOnly = message;
    this.setIsFormDirty(true);
  },

  setCampaignBlastMethod(method) {
    this.campaignBlastMethod = method;
    if (method === Enum.CampaignBlastMethod.Email) {
      this.isEmailMethod = true;
      this.isTextMethod = false;
      this.isWhatsappMethod = false;
    } else if (method === Enum.CampaignBlastMethod.Text) {
      this.isEmailMethod = false;
      this.isTextMethod = true;
      this.isWhatsappMethod = false;
    } else {
      this.isEmailMethod = false;
      this.isTextMethod = false;
      this.isWhatsappMethod = false;
    }
  },

  setIsFormDirty(isDirty) {
    this.isFormDirty = isDirty;
    this.setIsFormHasEdited(true);
  },

  setIsFormHasEdited(isFormHasEdited) {
    this.isFormHasEdited = isFormHasEdited;
  },

  setIsSubmitting(isSubmitting) {
    this.isSubmitting = isSubmitting;
  },

  setFormName(formName) {
    this.formName = formName;
    this.setIsFormDirty(true);
  },

  setFormEmail(formEmail) {
    this.formEmail = formEmail;
    this.setIsFormDirty(true);
  },

  setFormEmailDomain(formEmailDomain) {
    this.formEmailDomain = { ...formEmailDomain };
    this.setIsFormDirty(true);
  },

  setSubject(subject) {
    this.subject = subject;
    this.setIsFormDirty(true);
  },

  setTitle(title) {
    this.title = title;
    this.setIsFormDirty(true);
  },

  setCountryPhoneCode(countryCode) {
    this.countryCode = countryCode;
    this.setIsFormDirty(true);
  },

  setFormDomainList(formDomainList) {
    this.formDomainList = [...formDomainList];
  },

  sendCampaignMail() {
    let param = [];
    let dataModel = {
      type: "add",
      data:
      {
        emailCampaignId: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email && emailStore.id ? emailStore.id : null,
        campaignMessage: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? emailStore.htmlTemplate : this.campaignMessage,
        campaignTextOnly: this.campaignTextOnly,
        title: this.campaignBlastMethod !== Enum.CampaignBlastMethod.Email ? this.title : emailStore.campaignName,
        individualRecipients: this.individualRecipients,
        blastMethod: this.campaignBlastMethod,
        senderName: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? this.formName : null,
        senderEmail: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? this.formEmail : null,
        subject: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? this.subject : null,
        countryCode: this.countryCode,
        companyDomainId: this.formEmailDomain ? this.formEmailDomain.id : null
      }
    };

    if (this.audienceRecipientIds.length > 0) {
      let dataModelCandidate = {
        ...dataModel,
        url: ApiUrl.sendCampaign,
        data: {
          ...dataModel.data,
          audienceRecipients: [...this.audienceRecipientIds]
        }
      };
      param.push(dataModelCandidate);
    }
    if (this.audienceClientRecipientIds.length > 0) {
      let dataModelClient = {
        ...dataModel,
        url: ApiUrl.sendCampaignClient,
        data: {
          ...dataModel.data,
          audienceClientRecipients: [...this.audienceClientRecipientIds]
        }
      };
      param.push(dataModelClient);
    }

    return (ApiService.multipleRequest(param).then(
      action(
        response => {
          let errorMessage = [];
          response.forEach((item) => {
            if (item && item.data.statusCode !== 200 && item.data && item.data.isError) {
              errorMessage = [...errorMessage, item.data.message];
            }
          });

          if (errorMessage.length > 0) {
            errorMessage.forEach(item => {
              Alert.success(item);
            });
          } else {
            this.resetAll();
            emailStore.clearFormData();
            emailStore.clearTemplateData();
            this.checkDomainVerify();
            this.setCampaignBlastMethod(Enum.CampaignBlastMethod.Email);
            Alert.success(this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? Constants.ALERT_SUCCESS_SEND_EMAIL : Constants.ALERT_SUCCESS_SEND_TEXT);
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          Alert.error(err.data);
          return Promise.reject(err);
        }
      )
    ).finally(
      action(
        () => this.setIsSubmitting(false)
      )
    ));
  },

  sendTestEmailCampaign() {
    const jsonTemp = JSON.parse(this.emailTemplate.jsonTemplate);
    const data = {
      campaignMessage: this.emailTemplate.htmlTemplate,
      contactId: this.individualRecipients[0].id,
      senderName: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? this.formName : null,
      senderEmail: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? this.formEmail : null,
      subject: this.campaignBlastMethod === Enum.CampaignBlastMethod.Email ? this.subject : null,
      contactType: this.campaignContactType,
      companyDomainId: this.formEmailDomain ? this.formEmailDomain.id : null,
      jsonContent: JSON.stringify(jsonTemp.page.body)
    };

    return (ApiService.post(`${ApiUrl.sendTestMail}`, data).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            this.subject = "";
            this.individualRecipients = [];
            this.isFormDirty = false;
            Alert.success(Constants.ALERT_SUCCESS_SEND_EMAIL);
            return Promise.resolve(response.data);
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
          }
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  checkDnsValid(dnsItems) {
    let count = 0;
    dnsItems.forEach(item => {
      if (!item.isDnsValid) {
        count++;
      }
    });

    return count > 0 ? false : true;
  },

  checkDefaultDomain(data) {
    if (data.length > 0) {
      let selectedDomain = { ...data.find(x => x.isDefault) };
      selectedDomain.domainName = '@' + selectedDomain.domainName;
      this.setFormEmailDomain(selectedDomain);
    }
  },

  checkDomainVerify() {
    return (ApiService.get(ApiUrl.getCompanyDomain + "?isVerifiedOnly=true&isDefaultOnly=false").then(
      action(
        response => {
          const { data } = response;
          if (data.result.length > 0) {
            this.setFormDomainList(data.result);
            this.checkDefaultDomain(data.result);
            this.setFormName(EmailSenderDefault.Name); // [NOTE] change from " " to default name
          } else {
            Alert.error(Constants.ALERT_UNVALIDATED_DOMAIN);
          }
          this.setCampaignBlastMethod(Enum.CampaignBlastMethod.Email);
          this.setIsFormDirty(false); // Reset form is dirty because of setFormName to space
          this.setIsFormHasEdited(false);
          return Promise.resolve(response);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  getConvertLink() {
    return (ApiService.get(ApiUrl.getConvertLink).then(
      action(
        response => {
          const { data } = response;
          if (data.statusCode === 200 && !data.isError) {
            this.setShowConvertLink(data.result);
          }
          return Promise.resolve(response);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  }
});

export default campaignStore;
