import { ApiService, ApiUrl } from "helpers";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';

const activityLogStore = observable(
  {
    activityLogId: null,

    activityLogData: {
      id: null,
      contactId: null,
      contactName: "",
      companyId: null,
      lastActivityDate: null,
      featureType: null,
      activityLogType: null,
    },
    activityLogs: [],

    getActivityLogs() {
      return (ApiService.get(ApiUrl.getActivityLogs).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.activityLogs = response.data.result;
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    }
  }
);

export default activityLogStore;
