import { observable, action } from "mobx";
import { ClientColumnName } from "helpers/Constants";
import { ClientColumnName as EnumClientColumnName } from "helpers/enum";
import { ApiService, ApiUrl, Constants } from "helpers";
import Alert from 'react-s-alert';
import _ from "lodash";
import { userStore } from "stores";

const clientStore = observable({
  clientId: null,
  clientName: "",
  clientFullName: "",
  jobTitle: "",
  organization: "",
  location: "",
  phoneNumber: "",
  emailAddress: "",
  recruiterRating: 0,
  totalJobPosted: 0,
  lastContacted: "",
  recentActivity: "",
  roiAiScore: 0,
  industry: "",
  isFormDirty: false,
  activityLogData: [],
  updatedClientData: {},
  clientData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    mobile: "",
    address: {},
    otherEmail: [],
    social: {},
    employment: {},
    education: [],
    skillTags: [],
    links: {},
    skills: [],
    attachmentsPreview: "",
    attachments: {},
    photoPreview: "",
    photo: {},
    roiAiScore: 0,
    source: "",
    summary: "",
    rating: "",
    scale: null,
    isValidEmail: undefined,
    isSubscribe: false,
  },
  clientAudiences: [],
  showColumn: {
    clientName: true,
    jobTitle: true,
    organization: true,
    location: true,
    phoneNumber: true,
    emailAddress: true,
    recruiterRating: true,
    totalJobPosted: false,
    lastContacted: false,
    recentActivity: false,
    roiAiScore: false,
    industry: false
  },

  showColumnConfigure: {
    clientName: true,
    jobTitle: true,
    organization: true,
    location: true,
    phoneNumber: true,
    emailAddress: true,
    recruiterRating: true,
    totalJobPosted: false,
    lastContacted: false,
    recentActivity: false,
    roiAiScore: false,
    industry: false
  },

  columnOrder: [0, 1, 2, 3, 4, 5, 6],
  columnOrderTemp: [0, 1, 2, 3, 4, 5, 6],
  // columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  // columnOrderTemp: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

  columnOrderDisable: [],
  columnOrderDisableTemp: [],
  searchClientData: {},
  filterClientData: {},
  firstOrder: false,
  filterGrid: [],
  sortGrid: [],
  countRowClients: '0',
  agGridRowsPage : 0,
  agGridTotalRow : 0,
  agGridTotalPage : 0,

  gridColumn: [
    {
      id: 1,
      colId: ClientColumnName.CLIENT_NAME,
      headerName: EnumClientColumnName.ClientName,
      field: ClientColumnName.CLIENT_NAME,
      minWidth: 150,
      hide: false,
      order: 0,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 2,
      colId: ClientColumnName.JOB_TITLE,
      headerName: EnumClientColumnName.CurrentJobTitle,
      field: ClientColumnName.JOB_TITLE,
      minWidth: 150,
      hide: false,
      order: 1,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 3,
      colId: ClientColumnName.ORGANIZATION,
      headerName: EnumClientColumnName.Company,
      field: ClientColumnName.ORGANIZATION,
      minWidth: 150,
      hide: false,
      order: 1,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 4,
      colId: ClientColumnName.MOBILE_NUMBER,
      headerName: EnumClientColumnName.MobileNumber,
      field: ClientColumnName.MOBILE_NUMBER,
      minWidth: 130,
      hide: false,
      order: 6,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 5,
      colId: ClientColumnName.EMAIL_ADDRESS,
      headerName: EnumClientColumnName.EmailAddress,
      field: ClientColumnName.EMAIL_ADDRESS,
      minWidth: 150,
      hide: false,
      order: 7,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 6,
      colId: ClientColumnName.ROI_AI_SCORE,
      headerName: EnumClientColumnName.RoiAiScore,
      field: ClientColumnName.ROI_AI_SCORE,
      minWidth: 150,
      hide: false,
      order: 7,
      hideColConfig: false,
      checkedColConfig: true,
    },
  ],

  resetClientData(){
    this.clientId= null;
    this.clientFullName= null;
    this.clientData = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      mobile: "",
      address: {},
      otherEmail: [],
      social: {},
      employment: {},
      education: [],
      skillTags: [],
      links: {},
      skills: [],
      attachmentsPreview: "",
      attachments: {},
      photoPreview: "",
      photo: {},
      roiAiScore: 0,
      source: "",
      summary: "",
      rating: "",
      scale: null,
      isValidEmail: undefined
    };
    this.updatedClientData = {};
    this.clientAudiences = [];
    this.isFormDirty = false;
  },

  setClientAudiences(clientAudiences){
    this.clientAudiences = [...this.clientAudiences, clientAudiences];
  },

  setClientId(clientId){
    this.clientId = clientId;
  },

  updateGridColumn(gridColumnData) {
    this.gridColumn = gridColumnData;
  },


  setFirstOrder(data) {
    this.firstOrder = data;
  },
  setColumnOrder(data) {
    this.columnOrder = data;
  },
  setColumnOrderTemp(data) {
    this.columnOrderTemp = data;
  },
  setOrderDisable(data) {
    this.columnOrderDisable = data;
  },
  setOrderDisableTemp(data) {
    this.columnOrderDisableTemp = data;
  },
  addItemColumnOrderDisableTemp(data) {
    this.columnOrderDisableTemp.push(data);
  },
  removeItemColumnOrderDisableTemp(data) {
    var index = this.columnOrderDisableTemp.indexOf(data);
    if (index > -1) {
      this.columnOrderDisableTemp.splice(index, 1);
    }
  },
  setClientName(clientName) {
    this.clientName = clientName;
  },
  setJobTitle(jobTitle) {
    this.jobTitle = jobTitle;
  },
  setOrganization(organization) {
    this.organization = organization;
  },
  setLocation(location) {
    this.location = location;
  },
  setTotalJobPosted(totalJobPosted) {
    this.totalJobPosted = totalJobPosted;
  },
  setLastContacted(lastContacted) {
    this.lastContacted = lastContacted;
  },
  setRecentActivity(recentActivity) {
    this.recentActivity = recentActivity;
  },
  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  },
  setEmailAddress(emailAddress) {
    this.emailAddress = emailAddress;
  },
  setRoiAiScore(roiAiScore) {
    this.roiAiScore = roiAiScore;
  },
  setRecruiterRating(recruiterRating) {
    this.recruiterRating = recruiterRating;
  },
  setIndustry(industry) {
    this.industry = industry;
  },
  setShowColumn(data) {
    this.showColumn = { ...this.showColumn, ...data };
  },
  setShowColumnConfigure(data) {
    this.showColumnConfigure = { ...this.showColumnConfigure, ...data };
  },
  setShowColumnConfigureDefault(data) {
    this.showColumnConfigure = data
  },
  setSearchClientData(searchClientData) {
    this.searchClientData = searchClientData;
  },
  setFilterClientData(filterClientData) {
    this.filterClientData = filterClientData;
  },
  setCountRowClients(countRowClients) {
    this.countRowClients = countRowClients;
  },
  setFilterGrid(filterGrid) {
    this.filterGrid = filterGrid;
  },
  setSortGrid(sortGrid) {
    this.sortGrid = sortGrid;
  },
  setAgGridRowsPage(agGridRowsPage) {
    this.agGridRowsPage = agGridRowsPage;
  },
  setAgGridTotalRow(agGridTotalRow) {
    this.agGridTotalRow = agGridTotalRow;
  },
  setAgGridTotalPage(agGridTotalPage) {
    this.agGridTotalPage = agGridTotalPage;
  },
  setIsFormDirty(isFormDirty){
    this.isFormDirty = isFormDirty;
  },

  setClientDataValue(key, value) {
    _.set(this.clientData, key, value);
    _.set(this.updatedClientData, key, value);
    this.clientFullName = this.clientData?.firstName + " " + this.clientData?.lastName;
    this.isFormDirty = true;
  },

  setActivityLogData(data) {
    this.activityLogData = [];
    this.activityLogData = [...data];
  },

  initializeClientData(data, isFormUpdate = false) {
    this.clientId = data.clientId;
    this.clientFullName = data.firstName + ' ' + data.lastName;
    this.clientData = {
      ...this.clientData,
      clientId: data.clientId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      position: data.position,
      organization: data.organization,
      isValidEmail: data.isValidEmail,
      roiAiScore: data.roiAiScore,
      isSubscribe: data.isSubscribe
    };
    if (isFormUpdate) {
      // isFormUpdate, reset updatedClientData, set attachments and photo accordingly (if user update, then re-set photo/attachment)
      this.updatedClientData = {};
      // let attachmentsPreview = "", attachments = {}, photoPreview = "", photo = {}; // related PBI 6317 Task 6321
      let attachmentsPreview = "", attachments = {};
      if (_.isObject(data.attachments) && !_.isEmpty(data.attachments)) {
        attachmentsPreview = `data:${data.attachments?.contentType};base64, ${data.attachments?.fileContent}`;
        attachments = data.attachments;

        this.clientData = {
          ...this.clientData,
          attachmentsPreview,
          attachments
        };
      }
      // related PBI 6317 Task 6321
      // if (_.isObject(data.photo) && !_.isEmpty(data.photo.contentType)) {
      //   photoPreview = `data:${data.photo?.contentType};base64, ${data.photo?.fileContent}`;
      //   photo = data.photo;

      //   this.clientData = {
      //     ...this.clientData,
      //     photoPreview,
      //     photo
      //   };
      // }
    } else {
      this.clientData = {
        ...this.clientData,
        attachmentsPreview: _.isObject(data.attachments) && !_.isEmpty(data.attachments) && data?.attachments?.attachmentId !== 0 ? `data:${data.attachments?.contentType};base64, ${data.attachments?.fileContent}` : "",
        attachments: data.attachments,
        // related PBI 6317 Task 6321
        // photoPreview: _.isObject(data.photo) && !_.isEmpty(data.photo.contentType) ? `data:${data.photo?.contentType};base64, ${data.photo?.fileContent}` : "",
        // photo: data.photo
      };
    }

    this.isFormDirty = false;
  },

  getClientsServerSide(request) {
    const body = {
      param: JSON.stringify(request)
    };

    return (ApiService.add(`${ApiUrl.getAllClients}`, body).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.setCountRowClients(response.data.result.totalCount);
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  getClientDetail() {
    ApiService.getDetail(ApiUrl.getClientAtsDetails, this.clientId).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.initializeClientData(response.data.result);
            Alert.success(response.data.message);
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          }
          return Promise.resolve();
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    );
  },

  updateClientDetail() {
    const client = {
      client: {
        ...this.updatedClientData,
        clientId: this.clientId
      }
    };
    return (ApiService.edit(ApiUrl.updateClientAts, client).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.initializeClientData(response.data.result, true);
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          }

          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  unsubscribeClientFromPublic(token, type) {
    const client = {
      token: token,
      type: type,
    };
    return (ApiService.edit(ApiUrl.unsubscribeClientFromPublic, client).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  getClientActivityLogs() {
    return (ApiService.getDetail(ApiUrl.getClientActivity, this.clientId).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            this.setActivityLogData(response.data.result);
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  unsubscribeClient() {
    const { user } = userStore;
    const payload = {
      clientId: this.clientId,
      userId: user.id
    }
    return (ApiService.edit(ApiUrl.unsubscribeClient, payload).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
          } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  }

});

export default clientStore;
