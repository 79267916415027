import { observable } from "mobx";
import { AudienceMemberClientColumnName, AudienceMemberClientFieldName } from "views/Audience/AudienceMemberGrid/AudienceMemberGridConstant";

var audienceMemberStore = observable({

  showColumn: {
    contactName: true,
    emailAddress: true,
    phoneNumber: false,
    status: true,
    dateAdded: true,
    lastActivityAt: true,
    mobileNumber: true,
    roiAiScore: true
  },

  showColumnConfigure: {
    candidateName: true,
    emailAddress: true,
    phoneNumber: false,
    status: true,
    dateAdded: true,
    lastActivityAt: true,
    mobileNumber: true,
    roiAiScore: true
  },

  columnOrder: [0, 1, 2, 3, 4, 5, 6, 7],
  columnOrderTemp: [0, 1, 2, 3, 4, 5, 6, 7],
  columnOrderDisable: [],
  columnOrderDisableTemp: [],
  firstOrder: false,
  filterGrid: [],
  sortGrid: [],

  gridColumnClient: Object.values(AudienceMemberClientFieldName).map((item, index) => {
    const headerName = Object.values(AudienceMemberClientColumnName);
    return {
      id: index,
      colId: item,
      field: item,
      headerName: headerName[index],
      hide: false,
      sort: item === AudienceMemberClientFieldName.DATE_ADDED ? 'asc' : null,
      order: index++
    }
  }),
});

export default audienceMemberStore;
