import { observable } from "mobx";
import * as _ from "lodash";

const paginationGridStore = observable(
  {
      paginationState: [],

      setPaginationState(newPaginationState) {
        const find = this.paginationState.find(item => item.pathname === newPaginationState.pathname && item.renderIndex === newPaginationState.renderIndex)
        if(find) {
          this.paginationState.splice(_.findIndex(this.paginationState, function(item) {
              return item.pathname === newPaginationState.pathname && item.renderIndex === newPaginationState.renderIndex;
          }), 1);
        }
        this.paginationState = [...this.paginationState, newPaginationState];
      }
  }
);

export default paginationGridStore;
