import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
// import { ForgotPassword } from './views';
import { History } from './helpers';
import Alert from 'react-s-alert';
import PromiseLoader from 'PromiseLoader';

// Import react-s-alert styling
import 'react-s-alert/dist/s-alert-default.css';
// choose the react-s-alert effect
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import './App.scss';

// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const loading = () => <PromiseLoader></PromiseLoader>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Auth/Login/Login'));
const Register = React.lazy(() => import('./views/Auth/Register'));
const ForgotPassword = React.lazy(() => import('./views/Auth/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/Auth/Page404'));
const Page500 = React.lazy(() => import('./views/Auth/Page500'));
// const WebPortal = React.lazy(() => import('./Webportal/components/routes'));
const DocsViewer = React.lazy(() => import('./views/DocsViewer/DocsViewer'));
// const Live = React.lazy(() => import('./views/Chatbots/LiveLink/Live'));
// const Widget = React.lazy(() => import('./views/Chatbots/Widget/Widget'));
// const Test = React.lazy(() => import('./views/Chatbots/TestLink/Test'));
const UnsubscribeLandingPage = React.lazy(() => import('./views/LandingPage/Unsubscribe/UnsubscribeLandingPage'));
const Shortlink = React.lazy(() => import('./views/Shortlink/Shortlink'));
const Baitlink = React.lazy(() => import('./views/Baitlink/Baitlink'));
// const SurveyLandingPage = React.lazy(() => import('./views/LandingPage/Survey/SurveyLandingPage'));
const AutomationPatch = React.lazy(() => import('./views/AutomationPatch/AutomationPatch'));

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Alert
          stack={{ limit: 5, spacing: 50 }}
          effect="jelly"
          position="bottom-right"
          timeout={5000}
          offset={50}
        />
        <Router history={History}>
          <React.Suspense fallback={loading()}>
            <Switch>
              {/* <Route exact path="/" name="Home Page" render={props => <WebPortal {...props} />} /> */}
              <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/forgot" name="Forgot Password Page" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route exact path="/dashboard" name="Home" render={props => <DefaultLayout {...props} />} />
              {/* <Route exact path="/chatbot/live/:key" name="Live Chatbot" render={props => <Live {...props} />} />
              <Route exact path="/chatbot/widget/:key" name="Widget Chatbot" render={props => <Widget {...props} />} />
              <Route exact path="/chatbot/test/:key" name="Test Chatbot" render={props => <Test {...props} />} /> */}
              <Route exact path="/unsubscribe/:token" name="Unsubscribe Landing Page" render={props => <UnsubscribeLandingPage {...props} />} />
              <Route path="/admin" name="Home" render={props => <DefaultLayout {...props} />} />
              <Route path="/document/view/:id" name="Docs Viewer" render={props => <DocsViewer {...props} />} />
              <Route exact path="/bl/:token" name="ROI AI Baitlink" render={props => <Baitlink {...props} />} />
              <Route exact path="/sl/:token" name="ROI AI Shortlink" render={props => <Shortlink {...props} />} />
              <Route exact path="/ul/:token" name="ROI AI Unsubscribe Link" render={props => <Redirect {...props} to={{ pathname: `/unsubscribe/${props.match.params?.token}`, search: "?type=2" }} />} />
              {/* <Route exact path="/chat/:token" name="Survey Landing Page" render={props => <SurveyLandingPage {...props} />} /> */}
              <Route exact path="/automation/patch" name="Automation Patch" render={props => <AutomationPatch {...props} />} />
              <Redirect to="/404" />
            </Switch >
          </React.Suspense >
        </Router >
      </React.Fragment >
    );
  }
}

export default App;
 