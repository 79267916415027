function encodeFileAsURL(files, callback) {
  const filesSelected = files;
  if (filesSelected.length > 0) {
    const fileToLoad = filesSelected[0];
    const fileReader = new FileReader();

    fileReader.onload = function (fileLoadedEvent) {
      callback(fileLoadedEvent.target.result);
    };
    fileReader.readAsDataURL(fileToLoad);
  }
}

async function ToBase64(imageUrl) {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}

function dataURItoBlob(imageDataUrl) {
  // convert base64 to raw binary data held in a string
  let byteString = atob(imageDataUrl.split(',')[1]);

  // separate out the mime component
  let mimeString = imageDataUrl.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  // set the blob name below if needed
  // blob.name = 'filename.png'
  return blob;
}

export { encodeFileAsURL, ToBase64, dataURItoBlob };
