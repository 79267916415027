import { observable, action } from "mobx";
import { ApiUrl, ApiService } from "helpers";
import Alert from 'react-s-alert';

const storyFormStore = observable(
  {
    /* some observable state */
    isPickerModalActive: false,
    isTemplateModalActive: false,
    isTemplateConfirmationModalActive: false,
    templateName: '',
    templateDescription: '',
    pickerModalType: '',
    dataCanvas: {},
    formData: {
      characterLimit: 50,
      isSaveAsGoal: false,
      saveAsGoalValue: "Etc",
      documentUploadRadio: "Document",
      multipleChoice: [],
      multipleChoiceSwitch: false,
    },
    tempFormData: {},
    isFormValidated: {
      title: true,
      description: false,
      characterLimit: true,
      scoreOne: false,
      scoreTen: false,
      templateName: true,
      templateDescription: true,
    },
    isEditTitle: false,
    isCopyFromExisting: false,

    //state for the canvas
    selectedStory: {},

    //array format for chatbot
    chatbotFinalData: [],

    // set the action here
    setIsPickerModalActive(status, type) {
      this.isPickerModalActive = status
      this.pickerModalType = type
    },

    setIsTemplateModalActive(status) {
      this.isTemplateModalActive = status
    },

    setIsTemplateConfirmationModalActive(status) {
      this.isTemplateConfirmationModalActive = status
    },

    setTemplateName(name) {
      this.templateName = name
    },

    setTemplateDescription(desc) {
      this.templateDescription = desc
    },

    setNodeCanvas(data) {
      this.nodeCanvas = data
    },

    setFormData(data) {
      this.formData = { ...this.formData, ...data };
    },

    setTempFormData(data) {
      this.deleteFormData()
      this.tempFormData = data
    },
    emptyTempFormData() {
      this.tempFormData = {}
    },

    deleteFormData() {
      this.formData = {};
    },

    resetFormData() {
      this.formData = {
        characterLimit: 50,
        isSaveAsGoal: false,
        saveAsGoalValue: "Etc",
        documentUploadRadio: "Document",
      };
    },

    resetTemplate() {
      this.templateName = '';
      this.templateDescription = '';
    },

    deleteFormText() {
      delete this.formData.text
    },

    deleteFormImage() {
      delete this.formData.image
    },

    deleteFormGif() {
      delete this.formData.gif
    },

    deleteFormVideo() {
      delete this.formData.video
    },

    deleteFormLink() {
      delete this.formData.link
    },
    resetIsFormValidated() {
      this.isFormValidated.title = true
      this.isFormValidated.description = false
      this.isFormValidated.characterLimit = true
      this.isFormValidated.scoreOne = false
      this.isFormValidated.scoreTen = false
    },
    toggleIsEditTitle() {
      this.isEditTitle = !this.isEditTitle;
    },
    resetIsEditTitle() {
      this.isEditTitle = false;
    },
    toggleIsCopyFromExisting() {
      this.isCopyFromExisting = !this.isCopyFromExisting;
    },
    resetIsCopyFromExisting() {
      this.isCopyFromExisting = false;
    },
    resetBotForm() {
      this.resetFormData();
      this.resetIsEditTitle();
      this.resetIsCopyFromExisting();
    },
    setDataCanvas(data) {
      this.dataCanvas = data
    },
    addChatbotTemplate() {
      let form = new FormData();
      form.append('Name', this.templateName.trim());
      form.append('Description', this.templateDescription.trim());
      form.append('CanvasData', this.dataCanvas);

      return ApiService.add(ApiUrl.addChatbotTemplate, form, "", "")
        .then(
          action(response => {
            if (
              response &&
              response.status === 200 &&
              response.data &&
              !response.data.isError
            ) {
              //  this.setUser(response.data.result);
              return response.data;
            }
            return response.data;
          })
        )
        .catch(
          action(err => {
            Alert.error(err);
          })
        )
    },
  },
)

/* a function that observes the state */
// autorun(function () {
//   console.log(toJS(storyFormStore.formData.text));
// })

export default storyFormStore;
