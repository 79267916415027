import { ApiService, ApiUrl, Enum, Constants, FormatDate } from "helpers";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';
import { AutomationReportGridFieldName, AutomationReportGridColumnName } from "views/Reports/Automation/AutomationReportsGrid/AutomationReportGridConstant";
import moment from 'moment';
import { loadAutomationReportIntoCanvas } from "components/Rappid/RappidCanvasFunctions";
import { AutomationStateType, AutomationIfElseFirstColumnTypeClient } from "helpers/enum";
import { FilterModelPageName, HeaderTabName } from "helpers/Constants";

const automationReportStore = observable(
  {
    automationId: null,
    automationName: "",
    automationDate: moment.utc(),
    automationState: AutomationStateType.Active,
    automationTotalStep: 1,
    automationList: [],
    filteredAutomationList: [],
    filterAutomationsModel: {},
    filterSearchText: "",
    automationClientList: [],
    automationCompletion: [],
    automationAudiences: [],
    userJobAdders: [],
    officeJobAdders: [],
    groupJobAdders: [],
    triggerType: null,
    triggerList: [],
    candidateTriggered: 0,
    candidateSteps: [],
    reportId: null,
    completionStepName: "",
    completionAutomationName: "",
    defaultTab: HeaderTabName.TAB_CANDIDATE,
    filterGrid: [],
    sortGrid: [],
    agGridRowsPage: 0,
    agGridRowsPageClient: 0,
    agGridTotalRow: 0,
    agGridTotalRowClient: 0,
    agGridTotalPage: 0,
    agGridTotalPageClient: 0,

    gridColumn: [
      {
        id: 1,
        colId: AutomationReportGridFieldName.AutomationName,
        field: AutomationReportGridFieldName.AutomationName,
        headerName: AutomationReportGridColumnName.AutomationName,
        width: 150,
        hide: false,
        order: 0,
      },
      {
        id: 2,
        colId: AutomationReportGridFieldName.AutomationCreatedDate,
        field: AutomationReportGridFieldName.AutomationCreatedDate,
        headerName: AutomationReportGridColumnName.AutomationCreatedDate,
        width: 150,
        hide: false,
        sort: 'desc',
        order: 1,
      },
      {
        id: 3,
        colId: AutomationReportGridFieldName.AutomationDescription,
        field: AutomationReportGridFieldName.AutomationDescription,
        headerName: AutomationReportGridColumnName.AutomationDescription,
        width: 150,
        hide: false,
        order: 2,
      },
      {
        id: 4,
        colId: AutomationReportGridFieldName.AutomationStatus,
        field: AutomationReportGridFieldName.AutomationStatus,
        headerName: AutomationReportGridColumnName.AutomationStatus,
        width: 150,
        hide: false,
        order: 3,
      },
      {
        id: 5,
        colId: AutomationReportGridFieldName.AutomationAction,
        field: AutomationReportGridFieldName.AutomationAction,
        headerName: AutomationReportGridColumnName.AutomationAction,
        width: 150,
        hide: false,
        order: 4,
      },
    ],

    updateGridColumn(gridColumnData) {
      this.gridColumn = gridColumnData;
    },

    setFilterGrid(filterGrid) {
      this.filterGrid = filterGrid;
    },

    setSortGrid(sortGrid) {
      this.sortGrid = sortGrid;
    },

    setAgGridRowsPage(agGridRowsPage) {
      this.agGridRowsPage = agGridRowsPage;
    },

    setAgGridRowsPageClient(agGridRowsPageClient) {
      this.agGridRowsPageClient = agGridRowsPageClient;
    },

    setAgGridTotalRow(agGridTotalRow) {
      this.agGridTotalRow = agGridTotalRow;
    },

    setAgGridTotalRowClient(agGridTotalRow) {
      this.agGridTotalRowClient = agGridTotalRow;
    },

    setAgGridTotalPage(agGridTotalPage) {
      this.agGridTotalPage = agGridTotalPage;
    },

    setAgGridTotalPageClient(agGridTotalPageClient) {
      this.agGridTotalPageClient = agGridTotalPageClient;
    },

    resetAutomationReportStore() {
      this.automationId = null;
      this.automationName = "";
      this.automationState = AutomationStateType.Active;
      this.automationTotalStep = 1;
      this.automationList = [];
      this.automationClientList = [];
      this.triggerType = null;
      this.triggerList = [];
      this.candidateTriggered = 0;
      this.candidateSteps = [];
      this.automationAudiences = [];
    },

    setDefaultTab(tab) {
      this.defaultTab = tab
    },

    setCompletionStepName(completionStepName) {
      this.completionStepName = completionStepName;
    },

    setCompletionAutomationName(completionAutomationName) {
      this.completionAutomationName = completionAutomationName;
    },

    setAutomationTotalStep(automationTotalStep) {
      this.automationTotalStep = automationTotalStep;
    },

    setReportId(reportId) {
      this.reportId = reportId;
    },

    setAutomationCompletion(automationCompletion) {
      this.automationCompletion = [];
      this.automationCompletion = [...automationCompletion];
    },

    setAutomationList(automationList) {
      this.automationList = [...automationList];
      this.filteredAutomationList = automationList;
      this.filterAutomationsModel = {};
    },

    setAutomationClientList(automationClientList) {
      this.automationClientList = [];
      this.automationClientList = [...automationClientList];
    },

    setAutomationsFilterModel(data) {
      this.filterAutomationsModel = data;
      sessionStorage.setItem(FilterModelPageName.CampaignReportPage, JSON.stringify(data));
    },

    setAutomationReportDetail(response) {
      const { arrayStructure, triggerList, id, name, state, createdAt, candidateTriggered, candidateSteps, audiences, userJobAdders, groupJobAdders, officeJobAdders } = response.data.result;

      this.automationId = id;
      this.automationName = name;
      this.automationState = state;
      this.automationDate = createdAt;
      this.automationAudiences = audiences;
      this.userJobAdders = userJobAdders;
      this.groupJobAdders = groupJobAdders;
      this.officeJobAdders = officeJobAdders;

      if (triggerList) {
        const trigger = JSON.parse(triggerList);
        if (trigger.length > 0) {
          const triggerId = trigger[0].firstColumn.id;
          this.triggerType = triggerId === Enum.TriggerList.CandidatePlacementStartDate ? Enum.TriggerType.PlacementBased : Enum.TriggerType.CandidateBased;
          this.triggerList = [...trigger];
        }
      }

      this.candidateTriggered = candidateTriggered;
      this.candidateSteps = candidateSteps && [...candidateSteps];

      this.automationTotalStep = JSON.parse(arrayStructure).length;
    },

    setAutomationReportSearchFilterString(searchString) {
      const { FormatDateUtc } = FormatDate;
      //TODO: possible extract to helpers for searching strings, or extract to store helpers
      function ApplySearchStringIfCreatedDateExists(item, searchString) {
        return !!item.createdAt && FormatDateUtc(item?.createdAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
      }

      this.filterSearchText = searchString;

      this.filteredAutomationList = this.automationList.filter(item =>
        ApplySearchStringIfCreatedDateExists(item, searchString) ||
        item.automationName.toLowerCase().includes(searchString.toLowerCase()) ||
        item.category.toLowerCase().includes(searchString.toLowerCase()) ||
        item.createdBy?.toLowerCase().includes(searchString.toLowerCase()) ||
        item.createdAt?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    getAutomationReportCompletions(stepId, reportId) {
      const data = {
        stepId: stepId,
        reportId: Number(reportId),
      };

      return (ApiService.post(ApiUrl.getAutomationReportCompletions, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // TODO HERE if something necessary, for now just return data exactly from API
              this.setAutomationCompletion(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            Alert.error(err);
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationReports() {
      return (ApiService.get(ApiUrl.getAllAutomationReports).then(
        action(
          response => {
            this.setAutomationList(response.data.result);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationReportDetail(id) {
      return (ApiService.getDetail(ApiUrl.getAutomationReportDetails, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAutomationReportDetail(response);
              this.setAutomationReportData(response);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            Alert.error(err);
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationReportServerSide(request) {
      const body = {
        param: JSON.stringify(request)
      }; //add api url
      return (ApiService.post(`${ApiUrl.getAutomationReports}`, body).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAgGridTotalRow(response.data.result.totalCount);
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);

              return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getAutomationClientReportServerSide(request) {
      const body = {
        param: JSON.stringify(request)
      };
      //adjust api url 
      return (ApiService.post(`${ApiUrl.getAutomationClientReports}`, body).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAgGridTotalRowClient(response.data.result.totalCount);
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);

              return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    setAutomationReportData(response, redrawCanvas = true) {
      const { arrayStructure, diagramStructure, triggerList } = response.data.result;

      //TODO: remove all references to diagram structure is no longer needed
      var parsedDiagramStructure = JSON.parse(diagramStructure);

      parsedDiagramStructure.cells.filter(cell => cell.roiNodeType === 'ifelse').forEach(cell =>
        cell.roiData?.forEach(data => {
          data.withinLastOptions = {
            showWeeks: true,
            showDays: true,
            showHours: true,
            showMinutes: data.firstColumn.id !== AutomationIfElseFirstColumnTypeClient.ClientNote,
            ...data.withinLastOptions,
          };
          data.isShowWithinLast ??= false;
        })
      );

      if (redrawCanvas) {
        const trigger = JSON.parse(triggerList);
        loadAutomationReportIntoCanvas(arrayStructure, trigger);
      }
    },

    deactivateAutomation(id, state) {
      let params = {
        id: id,
        state: state
      };

      return (ApiService.edit(ApiUrl.setAutomationState, params).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (!this.automationId) {
                this.getAutomationReports();
              }
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },
  }
);

export default automationReportStore;
