import { observable, action } from "mobx";
import { ApiService, ApiUrl, Constants, Enum } from "helpers";
import Alert from 'react-s-alert';
import userStore from "stores/userStore";
import { AtsFieldCategoryType, ContactType } from "helpers/enum";
import { CandidateCommonlyUsedMergeTags, ContactCommonlyUsedMergeTags } from "helpers/Constants";

const atsStore = observable(
  {
    code: undefined,
    atsAccessToken: undefined,
    atsCurrentEmail: undefined,
    isNeedRelogin: false,
    isJobScopeDisabled: false,
    isUseLegacyAutomation: true,
    atsFieldCandidate: [],
    atsFieldClient: [],
    atsFieldForMergeTags: [],
    atsCategorisedFieldsForMergeTags: [],
    atsFieldForTextMergeTags: [],
    atsCategorisedFieldForTextMergeTags: [],
    integratedAtsFields: [],
    atsSyncStatusInfo: undefined,
    customFields: [],
    atsFieldClientAllowed: [],

    setCode(code) {
      this.code = code;
    },

    setAtsAccessToken(atsAccessToken) {
      this.atsAccessToken = atsAccessToken;
    },


    setAtsCurrentEmail(atsCurrentEmail) {
      this.atsCurrentEmail = atsCurrentEmail;
    },

    setIsNeedRelogin(isNeedRelogin) {
      this.isNeedRelogin = isNeedRelogin;
    },

    setIsJobScopeDisabled(isJobScopeDisabled) {
      this.isJobScopeDisabled = isJobScopeDisabled;
    },

    setIsUseLegacyAutomation(isUseLegacyAutomation) {
      this.isUseLegacyAutomation = isUseLegacyAutomation;
    },

    setAtsFieldCandidate(atsFieldCandidate) {
      this.atsFieldCandidate = [...atsFieldCandidate];
    },

    setAtsFieldClient(atsFieldClient) {
      this.atsFieldClient = [...atsFieldClient];
    },

    setAtsFieldForMergeTags(atsFieldForMergeTags) {
      this.atsFieldForMergeTags = [...atsFieldForMergeTags];
    },

    setAtsCategorisedFieldsForMergeTags(atsCategorisedFieldsForMergeTags) {
      this.atsCategorisedFieldsForMergeTags = [...atsCategorisedFieldsForMergeTags];
    },

    setAtsFieldForTextMergeTags(atsFieldForTextMergeTags) {
      this.atsFieldForTextMergeTags = [...atsFieldForTextMergeTags];
    },

    setAtsCategorisedFieldForTextMergeTags(atsCategorisedFieldForTextMergeTags) {
      this.atsCategorisedFieldForTextMergeTags = [...atsCategorisedFieldForTextMergeTags];
    },

    setIntegratedAtsFields(integratedAtsFields) {
      this.integratedAtsFields = integratedAtsFields;
    },

    setCustomFields(customFields) {
      this.customFields = customFields;
    },

    setAtsSyncStatusInfo(syncStatusInfo) {
      this.atsSyncStatusInfo = syncStatusInfo;
    },

    getAtsSyncInfo() {
      return this.atsSyncStatusInfo;
    },

    setAtsFieldsForCandidateAndClient(atsFields = []) {
      const filterAtsFieldsCandidate = atsFields.filter(item =>
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidatePhoto &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidateResume &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidateNPS &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.UrlUpdateData &&
        item.contactType === Enum.ContactType.Candidate);
      const filterAtsFieldsClient = atsFields.filter(item =>
        item.contactType === Enum.ContactType.Client);
      this.setAtsFieldCandidate(filterAtsFieldsCandidate);
      this.setAtsFieldClient(filterAtsFieldsClient);

      // only show clientFirstName in mergetags list (PBI #4087)
      const filterMergeTagsClient = filterAtsFieldsClient.filter(item =>
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientId ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientFirstName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientOwnerFirstName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientOwnerLastName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientOwnerEmail ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientOwnerMobile ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientOwnerJobTitle ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientEmail ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientLastName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientJobTitle ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientMobile ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ClientOrganisation ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactId ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactFirstName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactOwnerFirstName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactOwnerLastName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactOwnerEmail ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactOwnerMobile ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactOwnerJobTitle ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactEmail ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactLastName ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactJobTitle ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactMobile ||
        item.tokenVariable === Constants.AtsFieldsTokenVariable.ContactOrganisation
      );

      // use for text form in automation & campaign task #5990 PBI #3393
      const filterAtsCandidate = filterAtsFieldsCandidate.filter(item => item.isShow &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.Summary &&
        item.tokenVariable !== Constants.AtsFieldsTokenVariable.CandidateDateOfBirth
      );

      this.setAtsFieldForTextMergeTags(filterAtsCandidate);

      // use for BeePlugin in email template task #5990 PBI #3393
      this.setAtsFieldForMergeTags([...this.atsFieldForTextMergeTags, ...filterMergeTagsClient]);
    },

    filterCommonlyUsedMergeTags(atsCategorisedFields, commonlyUsedTags) {
      return atsCategorisedFields.reduce((acc, category) => {
        const matchedFields = category.atsFields.filter(field => commonlyUsedTags.includes(field.tokenVariable));
        return [...acc, ...matchedFields];
      }, []);
    },

    filterAtsFieldsForText(atsFields) {
      return atsFields.filter(item =>
        ![Constants.AtsFieldsTokenVariable.CandidatePhoto,
        Constants.AtsFieldsTokenVariable.CandidateResume,
        Constants.AtsFieldsTokenVariable.CandidateNPS,
        Constants.AtsFieldsTokenVariable.UrlUpdateData,
        Constants.AtsFieldsTokenVariable.Summary,
        Constants.AtsFieldsTokenVariable.CandidateDateOfBirth].includes(item.tokenVariable) && item.isShow);
    },

    setAtsCategorisedFieldsForEmailAndText(atsCategorisedFields = [], contactType) {
      atsCategorisedFields = atsCategorisedFields.map(item => {
        const category = AtsFieldCategoryType.find(category => category.id === item.categoryType);
        return {
          atsFields: item.atsFields,
          categoryType: category ? category.displayName : item.categoryType
        };
      });

      let commonlyUsedMergeTags = [];
      if (contactType === ContactType.Candidate || !contactType) {
        commonlyUsedMergeTags = this.filterCommonlyUsedMergeTags(atsCategorisedFields, CandidateCommonlyUsedMergeTags);
      }
      if (contactType === ContactType.Client || !contactType) {
        commonlyUsedMergeTags = [...commonlyUsedMergeTags, ...this.filterCommonlyUsedMergeTags(atsCategorisedFields, ContactCommonlyUsedMergeTags)];
      }
      if (commonlyUsedMergeTags) {
        atsCategorisedFields.unshift({ categoryType: 'Commonly used tags', atsFields: commonlyUsedMergeTags });
      }

      if (contactType === ContactType.Candidate) {
        const atsCategorisedFieldsForText = atsCategorisedFields.map(category => ({
          ...category,
          atsFields: this.filterAtsFieldsForText(category.atsFields)
        }));
        this.setAtsCategorisedFieldForTextMergeTags(atsCategorisedFieldsForText);
      }

      this.setAtsCategorisedFieldsForMergeTags(atsCategorisedFields);
    },

    getAtsDetail() {
      const { user } = userStore;
      return (ApiService.getDetail(ApiUrl.getCompanyById, user?.companyId).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // set access token and email address here
              this.atsCurrentEmail = response.data.result?.atsCurrentEmail;
              this.atsAccessToken = response.data.result?.atsAccessToken;
              this.isNeedRelogin = response.data.result?.isNeedRelogin;
              this.isJobScopeDisabled = response.data.result?.isJobScopeDisabled;
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    exchangeCodeWithAccessToken() {
      const data = {
        code: this.code
      };

      return (ApiService.post(ApiUrl.atsAuthentication, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // set access token and email address here
              this.atsCurrentEmail = response.data.result.atsCurrentEmail;
              this.atsAccessToken = response.data.result.atsAccessToken;
              this.getAtsField();
              window.history.pushState("", "", "/admin/settings/atsfields/");
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAtsField() {
      return (ApiService.get(ApiUrl.getAtsField).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { atsFields = [] } = response.data.result;
              this.setAtsFieldsForCandidateAndClient(atsFields);
              const showIntegratedAtsFields = response.data.result.atsFields.filter(item => item.isShow ||
                item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateResume ||
                item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateNPS);
              this.setIntegratedAtsFields(showIntegratedAtsFields);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAtsCategorisedFields(contactType) {
      const data = {
        contactType
      };
      return (ApiService.getDetailWithParams(ApiUrl.getAtsCategorisedFields, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsCategorisedFieldsForEmailAndText(response.data.result, contactType);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getLandingPageAtsField(token, type) {
      const data = {
        token,
        type
      };

      return (ApiService.getDetailWithParams(ApiUrl.getLandingPageAtsField, '', data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsFieldCandidate(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAtsSyncStatus() {
      return (ApiService.get(`/AtsSync/${Enum.AtsSyncVendor.JobAdder}/GetSyncStatus`).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsSyncStatusInfo(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    toggleField(id, state) {
      const data = {
        state: state,
        fieldId: id
      };

      return (ApiService.edit(ApiUrl.setAtsField, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { atsFields = [] } = response.data.result;
              this.setAtsFieldsForCandidateAndClient(atsFields);
              const showIntegratedAtsFields = response.data.result.atsFields.filter(item => item.isShow || item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateResume);
              this.setIntegratedAtsFields(showIntegratedAtsFields);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    toggleAllField(state, contactType = Enum.ContactType.Candidate) {
      const data = {
        state,
        contactType
      };

      return (ApiService.edit(ApiUrl.setAllAtsField, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { atsFields = [] } = response.data.result;
              this.setAtsFieldsForCandidateAndClient(atsFields);
              const showIntegratedAtsFields = response.data.result.atsFields.filter(item => item.isShow || item.tokenVariable === Constants.AtsFieldsTokenVariable.CandidateResume);
              this.setIntegratedAtsFields(showIntegratedAtsFields);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    syncNow() {
      return (ApiService.get(ApiUrl.syncNow).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAtsSyncStatusInfo({ status: "In Progress", lastSync: this.getAtsSyncInfo().lastSync });
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    syncFields() {
      return (ApiService.get(ApiUrl.syncFields).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              Alert.success(Constants.ALERT_SYNC_SUCCESS);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getCustomFields() {
      return (ApiService.get(ApiUrl.customFields).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setCustomFields(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getCompanyInfo() {
      return (ApiService.get(ApiUrl.getCompanyAdditionalInfo).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.isNeedRelogin = response.data.result?.isNeedRelogin;
              this.isJobScopeDisabled = response.data.result?.isJobScopeDisabled;
              this.isUseLegacyAutomation = response.data.result?.useLegacyAutomation;
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },
  }
);

export default atsStore;
