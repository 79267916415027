export const AudienceMemberColumnName = {
  CHECKBOX_ROW: "rowNum",
  CONTACT_NAME: "contactName",
  EMAIL_ADDRESS: "emailAddress",
  PHONE_NUMBER: "phoneNumber",
  STATUS: "isSubscribe",
  DATE_ADDED: "createdAt",
  LAST_ACTIVITY_AT: "lastActivityAt",
  MOBILE_NUMBER: "mobileNumber",
  ROIAI_SCORE: "roiAiScore"
};


export const AudienceMemberClientColumnName = {
  CHECKBOX_ROW: "rowNum",
  CONTACT_NAME: "Contact Name",
  EMAIL_ADDRESS: "Email",
  PHONE_NUMBER: "Phone Number",
  STATUS: "Status",
  DATE_ADDED: "Date Added",
  ROIAI_SCORE: "Roi-AI Score"
};

export const AudienceMemberClientFieldName = {
  CHECKBOX_ROW: "rowNum",
  CONTACT_NAME: "contactName",
  EMAIL_ADDRESS: "emailAddress",
  PHONE_NUMBER: "phone",
  STATUS: "isSubscribe",
  DATE_ADDED: "createdAt",
  ROIAI_SCORE: "roiAiScore"
};
