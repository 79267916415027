import { fabricStore, storyFormStore } from "stores";
import { PRIMARY_COLOR } from "helpers/Constants";

const createNodeMouseDown = e => {
  const { canvas } = fabricStore;
  fabricStore.showAddStep(false);
  fabricStore.showAddStepBottom(false);
  storyFormStore.emptyTempFormData();
  canvas.setActiveObject(e.target);
}

const createNodeMouseOver = e => {
  const { canvas } = fabricStore;
  if (e && e.target && e.target.id && e.target.id.startsWith("groupText-")) {
    e.target._objects[0].set("stroke", PRIMARY_COLOR);
    canvas.renderAll();
  }
};

const createNodeMouseOut = e => {
  const { canvas } = fabricStore;
  if (e && e.target && e.target.id && e.target.id.startsWith("groupText-")) {
    e.target._objects[0].set("stroke", "lightgray");
    canvas.renderAll();
  }
};

export { createNodeMouseDown, createNodeMouseOver, createNodeMouseOut };
