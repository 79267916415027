import { ApiService, ApiUrl, Constants, Enum } from "helpers";
import { AutomationStateType, TriggerTypeClient, AutomationIfElseFirstColumnTypeClient } from "helpers/enum";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';
import { AutomationReportClientGridFieldName, AutomationReportClientGridColumnName } from "views/Reports/Automation/AutomationReportsGrid/AutomationReportGridConstant";
import moment from 'moment';
import { loadAutomationReportIntoCanvas } from "components/Rappid/RappidCanvasFunctions";

const automationReportClientStore = observable(
  {
    automationId: null,
    automationName: "",
    automationDate: moment.utc(),
    automationState: AutomationStateType.Active,
    automationTotalStep: 1,
    automationCompletion: [],
    automationAudiences: [],
    userJobAdders: [],
    officeJobAdders: [],
    groupJobAdders: [],
    triggerType: null,
    triggerList: [],
    clientSteps: [],
    reportId: null,
    completionStepName: "",
    completionAutomationName: "",
    clientTriggered: 0,

    gridColumn: [
      {
        id: 1,
        colId: AutomationReportClientGridFieldName.AutomationName,
        field: AutomationReportClientGridFieldName.AutomationName,
        headerName: AutomationReportClientGridColumnName.AutomationName,
        width: 150,
        hide: false,
        order: 0,
      },
      {
        id: 2,
        colId: AutomationReportClientGridFieldName.AutomationCreatedDate,
        field: AutomationReportClientGridFieldName.AutomationCreatedDate,
        headerName: AutomationReportClientGridColumnName.AutomationCreatedDate,
        width: 150,
        hide: false,
        sort: 'desc',
        order: 1,
      },
      {
        id: 3,
        colId: AutomationReportClientGridFieldName.AutomationDescription,
        field: AutomationReportClientGridFieldName.AutomationDescription,
        headerName: AutomationReportClientGridColumnName.AutomationDescription,
        width: 150,
        hide: false,
        order: 2,
      },
      {
        id: 4,
        colId: AutomationReportClientGridFieldName.AutomationStatus,
        field: AutomationReportClientGridFieldName.AutomationStatus,
        headerName: AutomationReportClientGridColumnName.AutomationStatus,
        width: 150,
        hide: false,
        order: 3,
      },
      {
        id: 5,
        colId: AutomationReportClientGridFieldName.AutomationAction,
        field: AutomationReportClientGridFieldName.AutomationAction,
        headerName: AutomationReportClientGridColumnName.AutomationAction,
        width: 150,
        hide: false,
        order: 4,
      },
    ],

    resetAutomationReportStore() {
      this.automationId = null;
      this.automationName = "";
      this.automationState = AutomationStateType.Active;
      this.automationTotalStep = 1;
      this.triggerType = null;
      this.triggerList = [];
      this.clientTriggered = 0;
      this.clientSteps = [];
      this.clientTriggered = 0;
      this.automationAudiences = [];
    },

    setCompletionStepName(completionStepName) {
      this.completionStepName = completionStepName;
    },

    setCompletionAutomationName(completionAutomationName) {
      this.completionAutomationName = completionAutomationName;
    },

    setAutomationTotalStep(automationTotalStep) {
      this.automationTotalStep = automationTotalStep;
    },

    setReportId(reportId) {
      this.reportId = reportId;
    },

    setAutomationCompletion(automationCompletion) {
      this.automationCompletion = [];
      this.automationCompletion = [...automationCompletion];
    },

    setAutomationReportDetail(response) {
      const { arrayStructure, triggerList, id, name, state, createdAt, clientTriggered, clientSteps, audiences, userJobAdders, groupJobAdders, officeJobAdders } = response.data.result;

      this.automationId = id;
      this.automationName = name;
      this.automationState = state;
      this.automationDate = createdAt;
      this.automationAudiences = audiences;
      this.userJobAdders = userJobAdders;
      this.groupJobAdders = groupJobAdders;
      this.officeJobAdders = officeJobAdders;

      if (triggerList) {
        const trigger = JSON.parse(triggerList);
        if (trigger.length > 0) {
          const triggerId = trigger[0].firstColumn.id;
          this.triggerType = triggerId === Enum.TriggerListClient.ClientPlacementStartDate
            || triggerId === Enum.TriggerListClient.ClientPlacementEndDate
            || triggerId === Enum.TriggerListClient.ClientPlacementCreatedDate
            ? TriggerTypeClient.PlacementBased : TriggerTypeClient.ClientBased;
          this.triggerList = [...trigger];
        }
      }

      this.clientTriggered = clientTriggered;
      this.clientSteps = clientSteps && [...clientSteps];

      this.automationTotalStep = JSON.parse(arrayStructure).length;
    },

    getAutomationReportClientDetail(id) {
      return (ApiService.getDetail(ApiUrl.getAutomationReportClientDetails, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAutomationReportDetail(response);
              this.setAutomationReportData(response);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            Alert.error(err);
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationReportCompletions(stepId, reportId) {
      const data = {
        stepId,
        reportId,
      };

      return (ApiService.post(ApiUrl.getAutomationReportClientCompletions, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAutomationCompletion(response.data.result);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            Alert.error(err);
            return Promise.reject(err);
          }
        )
      ));
    },

    setAutomationReportData(response, redrawCanvas = true) {
      const { arrayStructure, diagramStructure, triggerList } = response.data.result;

      //TODO: remove all references to diagram structure is no longer needed
      var parsedDiagramStructure = JSON.parse(diagramStructure);

      parsedDiagramStructure.cells.filter(cell => cell.roiNodeType === 'ifelse').forEach(cell =>
        cell.roiData?.forEach(data => {
          data.withinLastOptions = {
            showWeeks: true,
            showDays: true,
            showHours: true,
            showMinutes: data.firstColumn.id !== AutomationIfElseFirstColumnTypeClient.ClientNote,
            ...data.withinLastOptions,
          };
          data.isShowWithinLast ??= false;
        })
      );

      if (redrawCanvas) {
        var trigger = JSON.parse(triggerList);
        loadAutomationReportIntoCanvas(arrayStructure, trigger);
      }
        
    },
  }
);

export default automationReportClientStore;
