import { observable } from "mobx"
import { AddEmptyTriggerNodeToCanvas } from "components/Rappid/RappidCanvasFunctions";
import { RappidNode } from "helpers/Constants";

const rappidStore = observable(
  {
    graph: undefined,
    tree: undefined,
    paper: undefined,
    paperScroller: undefined,
    activeElement: undefined,
    canvasJson: "",
    deletingNode: {},
    newElementViewModel: undefined,

    cleanRappid() {
      this.graph && this.graph.clear();
      this.graph && AddEmptyTriggerNodeToCanvas(true);
      this.graph = undefined;
      this.tree = undefined;
      this.paper = undefined;
      this.paperScroller = undefined;
      this.activeElement = undefined;
      this.newElementViewModel = undefined;
      this.canvasJson = "";
    },

    setGraph(graph) {
      this.graph = graph;
    },

    setTree(tree) {
      this.tree = tree;
    },

    setPaper(paper) {
      this.paper = paper;
    },

    setPaperScroller(paperScroller) {
      this.paperScroller = paperScroller;
    },

    setActiveElement(activeElement) {
      this.activeElement && this.resetActiveElement();
      this.activeElement = activeElement;
      this.activeElement && this.activeElement.attr('body/strokeWidth', RappidNode.ActiveElementBorderStrokeWidth);
    },

    resetActiveElement() {
      this.activeElement && this.activeElement.attr('body/strokeWidth', RappidNode.InactiveElementBorderStrokeWidth);
      this.activeElement = undefined;
    },

    setNewElementViewModel(newElementViewModel) {
      this.newElementViewModel = newElementViewModel;
    },

    resetNewElementViewModel() {
      this.newElementViewModel = undefined;
    },

    setCanvasJson(canvasJson) {
      this.canvasJson = canvasJson;
    },

    setDeletingNode(deletingNode) {
      this.deletingNode = deletingNode;
    }
  }
);

export default rappidStore;
