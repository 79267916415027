import { observable } from "mobx";
import { setCookie, getCookie, removeCookie } from "helpers/CookieHelper";
import { COOKIE_NAME_HIDE_TOOL_TEMPLATE } from "helpers/Constants";

const toolTemplateStore = observable({
    checkHideTomorrow: false,

    toggleCheckHideTomorrow() {
        this.checkHideTomorrow = !this.checkHideTomorrow;
    },
    
    cookieSet() {
      if (this.checkHideTomorrow) {
        setCookie(COOKIE_NAME_HIDE_TOOL_TEMPLATE)
      } else {
        removeCookie(COOKIE_NAME_HIDE_TOOL_TEMPLATE)
      }
    },

    checkCookie() {
        return getCookie(COOKIE_NAME_HIDE_TOOL_TEMPLATE);
    }
})

export default toolTemplateStore;