import { forwardRef } from "react";
import { Button } from "reactstrap";

const TimeInput = forwardRef(({ value, onClick, inputFrom = true, isSideBarForm = false }, ref) => {
  return (
    <Button
      id={`time-variable-display-${inputFrom ? `from` : `to`}-hours`}
      className={`${isSideBarForm ? '' : 'automation-finalise-input-hours'} ml-2 p-2 w-100`}
      onClick={onClick}
      innerRef={isSideBarForm ? null : ref}
    >
      {value}
    </Button>
  );
});

export default TimeInput;
