import { useEffect, useState } from "react";
import { RappidNodeType, RappidText } from "helpers/Constants";
import { AutomationStateType, CampaignBlastMethod, AutomationDelayFirstColumnType, TriggerList, TriggerListClient, AutomationTimeOperator, AutomationType, AutomationRoiActivityList, AutomationDelayFirstColumnTypeClient, AutomationRoiActivityListClient, TriggerType } from "helpers/enum";
import { calculateAddDelayDate, calculateSubstractDelayDate, useInterval, convertDatesToDays, convertDaysToDisplayText, checkFirstDelayFrom } from "helpers/AutomationHelper";
import { observer } from "mobx-react";
import { Col, Input, Row, FormGroup, Label } from "reactstrap";
import { automationStore, lookupStore, generalSettingStore } from "stores";
import SubmitButton from "./SubmitButton";
import moment from "moment";
import stepDelayIcon from "assets/img/automation/step-delay.svg";
import { inputNumberOnlyOnKeypressHandler, inputNumberOnPasteHandler } from "helpers/Validator";
import HeaderForm from "./HeaderForm";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import * as _ from "lodash";
import { EditRappidNode, CheckIfFirstTriggerCanvasIsEmpty, GetArrayStructure } from "components/Rappid/RappidCanvasFunctions";
import { toJS } from "mobx";
import { Enum } from "helpers";

const DelayForm = observer(() => {
  const { formDelay, automationDelayArrayStructure, automationState, currentPreviousDelayId, automationType, isCopiedTemplate, automationId, triggerType, triggerList, isTriggerPlacementEndDate, isTriggerPlacementStartDate } = automationStore;
  const [formDelayTemp, setFormDelayTemp] = useState({ ...formDelay });
  const automationDelayList = typeof automationDelayArrayStructure === "object" ? [] : JSON.parse(automationDelayArrayStructure);
  const delayText = (formDelayTemp?.roiData?.weeks && formDelayTemp?.roiData?.weeks !== 0 ? `${formDelayTemp?.roiData?.weeks} weeks ` : '')
    + (formDelayTemp?.roiData?.days && formDelayTemp?.roiData?.days !== 0 ? `${formDelayTemp?.roiData?.days} days ` : '')
    + (formDelayTemp?.roiData?.hours && formDelayTemp?.roiData?.hours !== 0 ? `${formDelayTemp?.roiData?.hours} hours ` : '')
    + (formDelayTemp?.roiData?.minutes && formDelayTemp?.roiData?.minutes !== 0 ? `${formDelayTemp?.roiData?.minutes} minutes` : '');
  const params = useLocation();
  const [isRunning, setIsRunning] = useState(true);
  const optionAllSelected = { id: 0, name: 'Any' };
  const isDraft = automationState === Enum.AutomationStateType.Draft;

  const setFormDelayTempValue = (formData) => {
    automationStore.setAutomationSidebarFormToDirty();
    setFormDelayTemp(formData);
  }

  const setFormDelayTempValueClean = (formData) => {
    setFormDelayTemp(formData);
  }

  useEffect(() => {
    Promise.all([
      lookupStore.getActivityLogName(),
      generalSettingStore.getCompanyTimeZone()
    ]).then(() => {
      const cloneFormDelay = { ...formDelay };
      if (isCopiedTemplate === true && automationId !== null && cloneFormDelay.roiData?.isDelayCondition && typeof cloneFormDelay.roiData?.isUpdatedFromCopiedTemplate === "undefined") {
        cloneFormDelay.roiData.delayCondition.thirdColumn = {
          ...cloneFormDelay.roiData?.delayCondition.thirdColumn,
          subActivities: [],
          subActivityNames: "",
          emailCampaignId: null,
          emailCampaignLinks: [],
          emailCampaignName: ""
        }
        automationStore.setFormDelay(cloneFormDelay);
        automationStore.setAutomationOnClear(true);
        EditRappidNode(RappidNodeType.Delay, cloneFormDelay.roiData.delayCondition.thirdColumn.subActivityNames, "", "", "", cloneFormDelay, false, true);
      }
    });

    return function cleanup() {
      setIsRunning(false);
      automationStore.resetFormDelay();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const data = { ...formDelay, roiData: { ...formDelay.roiData, } };
    setFormDelayTempValueClean({ ...data });
    // eslint-disable-next-line
  }, [formDelay]);

  console.debug("Delay Form Data: ", toJS(formDelay));

  useEffect(() => {
    const triggerTime = getTimeTriggerList(triggerList, automationType, automationStore,);
    setExecutionDate(triggerTime);
    // eslint-disable-next-line
  }, [formDelayTemp.roiData?.weeks, formDelayTemp.roiData?.days, formDelayTemp.roiData?.hours, formDelayTemp.roiData?.minutes]);

  const isSubmitButtonDisabled =
    (!(formDelayTemp?.roiData?.weeks > 0 || formDelayTemp?.roiData?.days > 0 || formDelayTemp?.roiData?.hours > 0 || formDelayTemp?.roiData?.minutes > 0))
    || (formDelayTemp?.roiData?.isDelayCondition && formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities.length === 0);

  const getFirstDelayStep = () => { return checkFirstDelayFrom(JSON.parse(GetArrayStructure())); };

  const setDelayStepForm = (formData) => {
    let formDataStep = { ...formData };
    formDataStep.isFirstDelay = triggerType !== TriggerType.PlacementBased && (getFirstDelayStep().length === 0 || formDelay?.roiData?.isFirstDelay) ? true : false;
    return formDataStep;
  };

  const setExecutionDate = (triggerTime = 0) => {
    let startedDate = moment();
    let minutes = formDelayTemp?.roiData?.minutes + getPreviousTotalMinutes();

    if (automationState && automationState === AutomationStateType.Active) {
      startedDate = getPreviousDelayDate();
      if (!formDelayTemp?.roiData?.previousDelayId) {
        startedDate = calculateSubstractDelayDate(
          formDelay?.roiData?.weeks,
          formDelay?.roiData?.days,
          formDelay?.roiData?.hours,
          formDelay?.roiData?.minutes,
          formDelay?.roiData?.delayDate,
        );
      }
      minutes = formDelayTemp?.roiData?.minutes;
    }

    const estimationDate = calculateAddDelayDate(
      formDelayTemp?.roiData?.weeks,
      formDelayTemp?.roiData?.days,
      formDelayTemp?.roiData?.hours,
      minutes,
      startedDate
    );

    const data = {
      ...formDelayTemp,
      roiData: {
        ...formDelayTemp?.roiData,
        delayDays: convertDatesToDays(startedDate, estimationDate) + triggerTime,
        delayStartDate: startedDate,
        delayDate: estimationDate,
        previousDelayId: formDelayTemp.roiData?.previousDelayId ?
          formDelayTemp.roiData.previousDelayId : formDelayTemp.id ?
            "" : currentPreviousDelayId,
        previousTotalMinutesTime: countCurrentTotalMinutes()
      }
    };
    setFormDelayTempValueClean({ ...data });
  };

  const countCurrentTotalMinutes = () => {
    return getPreviousTotalMinutes() +
      (formDelayTemp.roiData?.weeks * 10080) +
      (formDelayTemp.roiData?.days * 1440) +
      (formDelayTemp.roiData?.hours * 60) +
      (formDelayTemp.roiData?.minutes);
  };

  const getPreviousTotalMinutes = () => {
    const previousDelayStep = !formDelayTemp.roiData?.previousDelayId && !formDelayTemp.id ? currentPreviousDelayId : formDelayTemp.roiData?.previousDelayId;
    const delayEl = automationDelayList.find(el => el.step === previousDelayStep);
    return delayEl?.roiData?.previousTotalMinutesTime || 0;
  };

  const getPreviousDelayDate = () => {
    const previousDelayStep = !formDelayTemp.roiData.previousDelayId && !formDelayTemp.id ? currentPreviousDelayId : formDelayTemp.roiData.previousDelayId;
    const delayEl = automationDelayList.find(el => el.step === previousDelayStep);
    return delayEl?.roiData?.delayDate;
  };

  const renderDelayEstimation = () => {
    if (params?.pathname.includes("automation-template") || automationState === AutomationStateType.Active) {
      return <></>;
    } else {
      if (!CheckIfFirstTriggerCanvasIsEmpty() && triggerType === TriggerType.PlacementBased && (isTriggerPlacementStartDate || isTriggerPlacementEndDate)) {
        return (
          <>
            {<div className="mt-3 mb-1 text-white text-center font-italic" style={{ fontSize: '13px', fontWeight: 'bold' }}>
              Your delay will mean the next step will begin <b>{delayEstimationDate('days')}</b> {startDateText()}.
            </div>}
          </>
        );
      } else {
        return (
          <>
            {<div className="mt-3 mb-1 text-white text-center font-italic" style={{ fontSize: '13px', fontWeight: 'bold' }}>
              {formDelayTemp?.roiData?.delayDate && `Your delay will mean the next step will begin ${delayEstimationDate('date')} at ${delayEstimationDate('time')}`}
            </div>}
          </>
        );
      }
    }
  };

  const delayEstimationDate = (type) => {
    if (type === 'date') {
      return `${moment(formDelayTemp?.roiData?.delayDate).format("D")} ${moment(formDelayTemp?.roiData?.delayDate).format("MMMM")} ${moment(formDelayTemp?.roiData?.delayDate).format("YYYY")}`;
    } else if (type === 'days') {
      const days = formDelayTemp?.roiData?.delayDays;
      if (days === 0)
        return "";
      if (days > 0 || days < 0)
        return `${Math.abs(formDelayTemp?.roiData?.delayDays)}`;
    } else
      return `${moment(formDelayTemp?.roiData?.delayDate).format("h:mma")}`;
  };

  const startDateText = () => {
    const days = formDelayTemp?.roiData?.delayDays;
    const dateType = isTriggerPlacementStartDate ? "Start Date" : "End Date";
    if (days === 0)
      return `on the ${dateType}`;
    if (days > 0)
      return `days after the ${dateType}`;
    if (days < 0)
      return `days before the ${dateType}`;
  }

  useInterval(() => {
    const triggerTime = getTimeTriggerList(triggerList, automationType, automationStore);
    setExecutionDate(triggerTime);
  }, isRunning ? 30000 : null);

  const renderSubOptions = () => {
    if (automationType === AutomationType.Client) {
      if (formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities.length === 1 &&
        !(formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 15 ||
          formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 22 ||
          formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 25)) {
        let getTypeSubOption = null;
        let subOptions = [];
        if (formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 17) {
          subOptions = lookupStore.clientEmailTemplateList.filter(x => !x.isDeleted).map(({ id, label }) => ({ id, label }));
          return (
            <Row className="text-left mb-3">
              <Col md={12}>
                <Select
                  className="automation-form-dropdown-multi"
                  id="automation-delay-email-link-clicked-email-selection"
                  name="automation-delay-email-link-clicked-email-selection"
                  classNamePrefix="automation-dropdown"
                  placeholder="- Select -"
                  isMulti={false}
                  options={subOptions}
                  isDisabled={!isDraft}
                  onChange={e => {
                    e = e || [];
                    let data = { ...formDelayTemp };
                    data.roiData = { ...formDelayTemp.roiData };
                    data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition };
                    data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
                    data.roiData.delayCondition.thirdColumn.subActivities = [];
                    data.roiData.delayCondition.thirdColumn.subActivityNames = "";
                    data.roiData.delayCondition.thirdColumn.emailCampaignId = e.id;
                    data.roiData.delayCondition.thirdColumn.emailCampaignName = e.label;
                    lookupStore.getEmailCampaignLink(e.id).then(response => {
                      data.roiData.delayCondition.thirdColumn.emailCampaignLinks = response.result.map(item => ({ label: item }));
                    }).finally(() => {
                      setFormDelayTempValue({ ...data });
                    });
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.label}
                  value={subOptions?.slice().filter(x => x.id === formDelayTemp?.roiData?.delayCondition?.thirdColumn?.emailCampaignId) || null}
                />
              </Col>
            </Row>
          );
        } else {
          getTypeSubOption = _.find(formDelayTemp.roiData.delayCondition.thirdColumn.activityOptions, { 'id': formDelayTemp.roiData.delayCondition.thirdColumn.activities[0] })?.type;

          switch (getTypeSubOption) {
            case CampaignBlastMethod.Email:
              subOptions = lookupStore.clientEmailTemplateList.filter(item => !item.isDeleted).filter((item, index, array) => array.findIndex(arr => arr.label === item.label) === index).map(({ label }) => ({ label }));
              break;
            case CampaignBlastMethod.Survey:
              subOptions = lookupStore.surveyList.map((label) => ({ label }));
              break;
            default:
              subOptions = [];
              break;
          }
        }

        return (
          <Select
            className="automation-form-dropdown-multi"
            id={`automation-delay-sub-activities`}
            name={`automation-delay-sub-activities`}
            classNamePrefix="automation-dropdown"
            placeholder="- Select -"
            isMulti={true}
            options={subOptions}
            isDisabled={!isDraft}
            onChange={e => {
              e = e || [];
              let data = { ...formDelayTemp };
              data.roiData = { ...formDelayTemp.roiData };
              data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition };
              data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
              data.roiData.delayCondition.thirdColumn.subActivities = e.map(x => x.label);
              data.roiData.delayCondition.thirdColumn.subActivityNames = e.map(x => x.label).join(', ');
              setFormDelayTempValue({ ...data });
            }}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            value={subOptions?.slice().filter(x => formDelayTemp?.roiData?.delayCondition?.thirdColumn?.subActivities?.slice().includes(x.label)) || null}
          />
        );
      }
    } else {
      if (formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities.length === 1 &&
        !(formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 15 ||
          formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 16 ||
          formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 20 ||
          formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 21 ||
          formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 22)) {
        let getTypeSubOption = null;
        let subOptions = [];
        if (formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 17) {
          subOptions = lookupStore.candidateEmailTemplateList.filter(x => !x.isDeleted).map(({ id, label }) => ({ id, label }));
          return (
            <Row className="text-left mb-3">
              <Col md={12}>
                <Select
                  className="automation-form-dropdown-multi"
                  id="automation-delay-email-link-clicked-email-selection"
                  name="automation-delay-email-link-clicked-email-selection"
                  classNamePrefix="automation-dropdown"
                  placeholder="- Select -"
                  isMulti={false}
                  options={subOptions}
                  isDisabled={!isDraft}
                  onChange={e => {
                    e = e || [];
                    let data = { ...formDelayTemp };
                    data.roiData = { ...formDelayTemp.roiData };
                    data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition };
                    data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
                    data.roiData.delayCondition.thirdColumn.subActivities = [];
                    data.roiData.delayCondition.thirdColumn.subActivityNames = "";
                    data.roiData.delayCondition.thirdColumn.emailCampaignId = e.id;
                    data.roiData.delayCondition.thirdColumn.emailCampaignName = e.label;
                    lookupStore.getEmailCampaignLink(e.id).then(response => {
                      data.roiData.delayCondition.thirdColumn.emailCampaignLinks = response.result.map(item => ({ label: item }));
                    }).finally(() => {
                      setFormDelayTempValue({ ...data });
                    });
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.label}
                  value={subOptions?.slice().filter(x => x.id === formDelayTemp?.roiData?.delayCondition?.thirdColumn?.emailCampaignId) || null}
                />
              </Col>
            </Row>
          );
        } else if (formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities[0] === 26) {
          const { smsLinkClickedList = [] } = lookupStore;
          subOptions = smsLinkClickedList.map(({ campaignTitle: label }) => ({ label }));
        } else {
          getTypeSubOption = _.find(formDelayTemp.roiData.delayCondition.thirdColumn.activityOptions, { 'id': formDelayTemp.roiData.delayCondition.thirdColumn.activities[0] })?.type;
          switch (getTypeSubOption) {
            case CampaignBlastMethod.Email:
              subOptions = lookupStore.candidateEmailTemplateList.filter(item => !item.isDeleted).filter((item, index, array) => array.findIndex(arr => arr.label === item.label) === index).map(({ label }) => ({ label }));
              break;
            case CampaignBlastMethod.Text:
              subOptions = lookupStore.textCampaignList.map((label) => ({ label }));
              break;
            case CampaignBlastMethod.Survey:
              subOptions = lookupStore.surveyList.map((label) => ({ label }));
              break;
            default:
              subOptions = [];
              break;
          }
        }

        return (
          <Select
            className="automation-form-dropdown-multi"
            id={`automation-delay-sub-activities`}
            name={`automation-delay-sub-activities`}
            classNamePrefix="automation-dropdown"
            placeholder="- Select -"
            isMulti={true}
            options={subOptions}
            isDisabled={!isDraft}
            onChange={e => {
              e = e || [];
              let data = { ...formDelayTemp };
              data.roiData = { ...formDelayTemp.roiData };
              data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition };
              data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
              data.roiData.delayCondition.thirdColumn.subActivities = e.map(x => x.label);
              data.roiData.delayCondition.thirdColumn.subActivityNames = e.map(x => x.label).join(', ');
              // reset email link clicked data
              data.roiData.delayCondition.thirdColumn.emailCampaignId = 0;
              data.roiData.delayCondition.thirdColumn.emailCampaignName = "";
              setFormDelayTempValue({ ...data });
            }}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            value={subOptions?.slice().filter(x => formDelayTemp?.roiData?.delayCondition?.thirdColumn?.subActivities?.slice().includes(x.label)) || null}
          />
        );
      }
    }
  };

  const renderEmailLinkClickedOptions = () => {
    return (
      <Select
        className="automation-form-dropdown-multi"
        id={`automation-delay-sub-activities`}
        name={`automation-delay-sub-activities`}
        classNamePrefix="automation-dropdown"
        placeholder="- Select -"
        isMulti={true}
        options={formDelayTemp?.roiData?.delayCondition?.thirdColumn?.emailCampaignLinks || []}
        isDisabled={!isDraft}
        onChange={e => {
          e = e || [];
          let data = { ...formDelayTemp };
          data.roiData = { ...formDelayTemp.roiData };
          data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition };
          data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
          data.roiData.delayCondition.thirdColumn.subActivities = e.map(x => x.label);
          data.roiData.delayCondition.thirdColumn.subActivityNames = e.map(x => x.label).join(', ');
          setFormDelayTempValue({ ...data });
        }}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.label}
        value={formDelayTemp.roiData.delayCondition?.thirdColumn?.emailCampaignLinks?.slice()?.filter(x => formDelayTemp?.roiData?.delayCondition?.thirdColumn?.subActivities?.slice().includes(x.label)) || []}
      />
    );
  };

  const textHeader = triggerType !== TriggerType.PlacementBased && (formDelay?.roiData?.isFirstDelay || getFirstDelayStep().length === 0) ? RappidText.DelayScheduleStep : RappidText.DelayStep;

  return (
    <>
      <HeaderForm
        automationHeaderId="form-step-delay"
        automationHeaderImgSrc={stepDelayIcon}
        automationHeaderImgAlt="icon-delay"
        automationHeaderTextStep={textHeader}
      />

      <Row>
        <Col md={12} className="mt-2">
          <Label className="text-white text-center">Delay Time</Label>
        </Col>
      </Row>
      <Row className="text-center mx-0">
        <Col md={3} className="pl-1 pr-1">
          <Input
            type="number"
            id="automation-delay-weeks"
            min={0}
            onKeyPress={inputNumberOnlyOnKeypressHandler}
            onPaste={inputNumberOnPasteHandler}
            className="rounded-pill input-delay automation-form-input"
            value={formDelayTemp?.roiData?.weeks || 0}
            disabled={!isDraft}
            onChange={e => {
              const data = {
                ...formDelayTemp,
                roiData: { ...formDelayTemp?.roiData, weeks: parseInt(e.target.value) || 0 }
              };
              setFormDelayTempValue({ ...data });
            }}
          />
          <small className="text-white">Week(s)</small>
        </Col>
        <Col md={3} className="pl-1 pr-1">
          <Input
            type="number"
            id="automation-delay-days"
            min={0}
            onKeyPress={inputNumberOnlyOnKeypressHandler}
            onPaste={inputNumberOnPasteHandler}
            className="rounded-pill input-delay automation-form-input"
            value={formDelayTemp?.roiData?.days || 0}
            disabled={!isDraft}
            onChange={e => {
              const data = {
                ...formDelayTemp,
                roiData: { ...formDelayTemp?.roiData, days: parseInt(e.target.value) || 0 }
              };
              setFormDelayTempValue({ ...data });
            }}
          />
          <small className="text-white">Day(s)</small>
        </Col>
        <Col md={3} className="pr-1 pl-1">
          <Input
            type="number"
            id="automation-delay-hours"
            min={0}
            onKeyPress={inputNumberOnlyOnKeypressHandler}
            onPaste={inputNumberOnPasteHandler}
            className="rounded-pill input-delay automation-form-input"
            value={formDelayTemp?.roiData?.hours || 0}
            disabled={!isDraft}
            onChange={e => {
              const data = {
                ...formDelayTemp,
                roiData: { ...formDelayTemp?.roiData, hours: parseInt(e.target.value) || 0 }
              };
              setFormDelayTempValue({ ...data });
            }}
          />
          <small className="text-white">Hour(s)</small>
        </Col>
        <Col md={3} className="pr-1 pl-1">
          <Input
            type="number"
            id="automation-delay-minutes"
            min={0}
            onKeyPress={inputNumberOnlyOnKeypressHandler}
            onPaste={inputNumberOnPasteHandler}
            className="rounded-pill input-delay automation-form-input"
            value={formDelayTemp?.roiData?.minutes || 0}
            disabled={!isDraft}
            onChange={e => {
              const data = {
                ...formDelayTemp,
                roiData: { ...formDelayTemp?.roiData, minutes: parseInt(e.target.value) || 0 }
              };
              setFormDelayTempValue({ ...data });
            }}
          />
          <small className="text-white">Minute(s)</small>
        </Col>
      </Row>
      <div className="my-3 text-white text-center" style={{ fontSize: '10px' }}>Total delay: {delayText}</div>
      {renderDelayEstimation()}
      <FormGroup className="step-body-text">
        <Input
          type="checkbox"
          id="automation-delay-is-delay-condition"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          checked={formDelayTemp?.roiData?.isDelayCondition || false}
          disabled={!isDraft}
          onChange={(e) => {
            let data = { ...formDelayTemp };
            data.roiData = { ...formDelayTemp.roiData };
            data.roiData.isDelayCondition = e.target.checked;

            if (!e.target.checked) {
              data.roiData.delayCondition = {};
            } else {
              data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition }
              data.roiData.delayCondition.firstColumn = { ...formDelayTemp.roiData?.delayCondition?.firstColumn };
              data.roiData.delayCondition.firstColumn.id = automationType === AutomationType.Candidate ? AutomationDelayFirstColumnType.RoiAiActivity.Id : AutomationDelayFirstColumnTypeClient.RoiAiActivity.Id;
              data.roiData.delayCondition.firstColumn.name = automationType === AutomationType.Candidate ? AutomationDelayFirstColumnType.RoiAiActivity.Name : AutomationDelayFirstColumnTypeClient.RoiAiActivity.Name;

              data.roiData.delayCondition.secondColumn = { ...formDelayTemp.roiData?.delayCondition?.secondColumn };
              data.roiData.delayCondition.secondColumn.id = 1;
              data.roiData.delayCondition.secondColumn.name = AutomationTimeOperator.Is;

              data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
              data.roiData.delayCondition.thirdColumn.activities = [];
              data.roiData.delayCondition.thirdColumn.names = "";
              data.roiData.delayCondition.thirdColumn.activityOptions = automationType === AutomationType.Candidate ? AutomationRoiActivityList.filter((x) => x.id !== 15 && x.id !== 16) : AutomationRoiActivityListClient.filter((x) => x.id !== 15);
              data.roiData.delayCondition.thirdColumn.subActivities = [];
              data.roiData.delayCondition.thirdColumn.subActivityNames = "";
            }
            setFormDelayTempValue({ ...data });
          }}
        />
        <Label check htmlFor="automation-delay-is-delay-condition" className="mb-2">
          Skip if specific conditions are met
        </Label>
      </FormGroup>
      <Row className="text-left mb-3">
        <Col md={12}>
          {formDelayTemp?.roiData?.isDelayCondition && formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activityOptions &&
            <Select
              className="automation-form-dropdown-multi"
              id={`automation-delay-type`}
              name={`automation-delay-type`}
              classNamePrefix="automation-dropdown"
              placeholder="- Select Specific Activity Details -"
              isMulti={true}
              options={formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activityOptions}
              isDisabled={!isDraft}
              onChange={e => {
                e = e || [];
                let data = { ...formDelayTemp };
                data.roiData = { ...formDelayTemp.roiData };
                data.roiData.delayCondition = { ...formDelayTemp.roiData?.delayCondition };
                data.roiData.delayCondition.thirdColumn = { ...formDelayTemp.roiData?.delayCondition?.thirdColumn };
                //modify options roi activity 8372
                const isSelectedAll = e.find(x => x.name === 'Any' || x.id === 0);
                const modifyDisableOptions = [...formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activityOptions];
                const newOptionDropdown = modifyDisableOptions.map(i => {
                  const newOptions = {
                    name: i.name,
                    id: i.id,
                    isDisabled: isSelectedAll && i.name !== 'Any' ? true : false,
                    type: i.type
                  };
                  return newOptions
                });
                data.roiData.delayCondition.thirdColumn.activityOptions = [...newOptionDropdown];
                if (e.length > 0) {
                  if (isSelectedAll) {
                    data.roiData.delayCondition.thirdColumn.activities = newOptionDropdown.filter(x => x.name !== optionAllSelected.name).map(x => x.id);
                    data.roiData.delayCondition.thirdColumn.names = newOptionDropdown.filter(x => x.name !== optionAllSelected.name).map(x => x.name).join(', ');
                    data.roiData.delayCondition.thirdColumn.isActivityAllSelected = true;
                  } else {
                    data.roiData.delayCondition.thirdColumn.activities = e.map(x => x.id);
                    data.roiData.delayCondition.thirdColumn.names = e.map(x => x.name).join(', ');
                    data.roiData.delayCondition.thirdColumn.isActivityAllSelected = false;
                  }
                } else {
                  data.roiData.delayCondition.thirdColumn.activities = [];
                  data.roiData.delayCondition.thirdColumn.names = '';
                  data.roiData.delayCondition.thirdColumn.isActivityAllSelected = false;
                }
                // reset email link clicked data
                data.roiData.delayCondition.thirdColumn.emailCampaignId = 0;
                data.roiData.delayCondition.thirdColumn.emailCampaignName = "";
                data.roiData.delayCondition.thirdColumn.subActivities = [];
                data.roiData.delayCondition.thirdColumn.subActivityNames = "";
                setFormDelayTempValue({ ...data });
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={formDelayTemp.roiData.delayCondition.thirdColumn.isActivityAllSelected ? optionAllSelected : formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activityOptions.slice().filter(x => formDelayTemp?.roiData?.delayCondition?.thirdColumn?.activities?.slice().includes(x?.id)) || null}
            />
          }
        </Col>
      </Row>
      {formDelayTemp?.roiData?.isDelayCondition && renderSubOptions()}
      {formDelayTemp?.roiData?.isDelayCondition
        && formDelayTemp?.roiData?.delayCondition?.thirdColumn?.emailCampaignId
        && formDelayTemp?.roiData?.delayCondition?.thirdColumn?.emailCampaignId > 0
        && renderEmailLinkClickedOptions()}
      {isDraft &&
        <SubmitButton
          type={RappidNodeType.Delay}
          text={renderDelayText(isTriggerPlacementStartDate, isTriggerPlacementEndDate, automationState, formDelayTemp)}
          text2={renderConditionText(formDelayTemp?.roiData?.delayCondition?.thirdColumn?.names)}
          roiData={setDelayStepForm(formDelayTemp?.roiData)}
          isDisabled={isSubmitButtonDisabled} />
      }
    </>
  );
});

export const renderDelayText = (isTriggerPlacementStartDate, isTriggerPlacementEndDate, automationState, formDelayTemp) => {
  const isPlacementStartDate = !CheckIfFirstTriggerCanvasIsEmpty() && isTriggerPlacementStartDate && automationState !== AutomationStateType.Active;
  const isPlacementEndDate = !CheckIfFirstTriggerCanvasIsEmpty() && isTriggerPlacementEndDate && automationState !== AutomationStateType.Active;
  return "Wait for: " + convertDaysToDisplayText(formDelayTemp?.roiData, isPlacementStartDate, isPlacementEndDate);
};

export const renderConditionText = (conditionActivities) => { return conditionActivities ? "Skip if: " + conditionActivities : ""; };

//this function does two things, gets the timeResult and sets setIsTriggerPlacementStartDate/setIsTriggerPlacementEndDate
export const getTimeTriggerList = (triggerListData, automationType, automationStore) => {
  let timeResult = 0;
  if (triggerListData.length > 0) {
    automationStore.setIsTriggerPlacementStartDate(false);
    automationStore.setIsTriggerPlacementEndDate(false);
    triggerListData.forEach(item => {
      if ((automationType === AutomationType.Candidate && item.firstColumn.id === TriggerList.CandidatePlacementStartDate) ||
        (automationType === AutomationType.Client && item.firstColumn.id === TriggerListClient.ClientPlacementStartDate) ||
        (automationType === AutomationType.Candidate && item.firstColumn.id === TriggerList.CandidatePlacementEndDate) ||
        (automationType === AutomationType.Client && item.firstColumn.id === TriggerListClient.ClientPlacementEndDate)) {
        const timeHours = item.thirdColumn.hours;
        const timeDays = item.thirdColumn.days;
        const timeWeeks = item.thirdColumn.weeks;
        const estimationDateStartDate = calculateAddDelayDate(timeWeeks, timeDays, timeHours, 0);
        //const startDate = moment();
        const days = convertDatesToDays(moment(), estimationDateStartDate);
        if (item.secondColumn.name === AutomationTimeOperator.After)
          timeResult = timeResult + days;
        else if (item.secondColumn.name === AutomationTimeOperator.Before)
          timeResult = timeResult - days;
        if (triggerListData.length === 1) {
          if ((item.firstColumn.id === TriggerList.CandidatePlacementStartDate) || (item.firstColumn.id === TriggerListClient.ClientPlacementStartDate))
            automationStore.setIsTriggerPlacementStartDate(true);
          else
            automationStore.setIsTriggerPlacementEndDate(true);
        }
      }
    })
  }
  return timeResult;
};

export default DelayForm;