import { observable, action } from "mobx";
import { ApiUrl, ApiService, Constants, Enum } from "helpers";
import { ContactType, AudienceType } from 'helpers/enum';
import Alert from 'react-s-alert';
import moment from 'moment';
import { candidateAudienceStore, clientAudienceStore } from "stores";

var audienceStore = observable({
  audienceId: 0,
  formAudience: {
    audienceName: '',
    audienceType: AudienceType.Manual,
    audienceContact: ContactType.Candidate,
    totalMembers: 0,
    contacted: null,
    description: '',
    isLive: false,
    campaignName: 'CampaignName',
    lastAddedDate: null,
    lastActivityDate: null,
    lastActivity: null,
    audienceLastActivityType: null,
    createdAt: null,
    createdBy: null,
    audienceSource: null,
    audienceSourceString: '',
  },
  isEditMode: false,
  isFormDirty: false,
  activeTab: "",

  listContact: [],
  listSelectedMember: [],
  listAudienceMember: [],
  cleanUpAudience: true,

  setCleanUpAudience(cleanUpAudience) { 
      this.cleanUpAudience = cleanUpAudience;
  },

  setActiveTab(activeTab) {
    this.activeTab = activeTab;
  },

  setAudienceId(audienceId) {
    this.audienceId = audienceId;
  },

  setFormAudience(data) {
    this.formAudience = { ...this.formAudience, ...data }
  },

  setListContact(listContact) {
    this.listContact = []
    this.listContact = [...listContact]
  },

  setListSelectedMember(listSelectedMember) {
    this.listSelectedMember = []
    this.listSelectedMember = [...listSelectedMember]
  },

  setListAudienceMember(listAudienceMember) {
    this.listAudienceMember = []
    this.listAudienceMember = [...listAudienceMember]
  },

  setIsEditMode(isEditMode) {
    this.isEditMode = isEditMode;
  },

  setIsFormDirty(isFormDirty) {
    this.isFormDirty = isFormDirty;
  },

  addAudience() {
    return (ApiService.add(ApiUrl.addAudience, this.formAudience).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            if (this.listSelectedMember.length > 0){
              this.addAudienceMembers(parseInt(response.data.result.id));
            }
            Alert.success("New audience created!");
            this.setAudienceId(parseInt(response.data.result.id));
            this.setIsEditMode(true);
            this.setIsFormDirty(false);
            return this.getAudienceById(response.data.result.id);
          } else {
            if (response && response.data && response.data.message) {
              Alert.error(response.data.message);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
          }
        }
      )
    ).catch(
      action(
        err => {
          Alert.error(err);
          return Promise.reject(err);
        }
      )
    ));
  },

  addAudienceMembers(audienceId) {
    let param = {
      "audienceId": audienceId,
      "contactIds": this.listSelectedMember.map(x => x.contactId),
      "contactType": Enum.TabNameEnum.Candidate,
    };
    return (ApiService.add(ApiUrl.addAudienceMembers, param).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            this.setIsEditMode(true);
            this.setIsFormDirty(false);
            this.setListAudienceMember(this.listSelectedMember);
            this.setListSelectedMember([]);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(audienceId);
        }
      )
    ).catch(
      action(
        err => {
          Alert.error(err);
          return Promise.reject(err);
        }
      )
    ));
  },

  updateAudience() {
    let params = { ...this.formAudience, id: this.audienceId };
    if (this.listSelectedMember.length > 0 && this.audienceId !== 0){
      this.addAudienceMembers(parseInt(this.audienceId));
    }
    return (ApiService.edit(ApiUrl.updateAudience, params).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAudienceId(parseInt(response.data.result.id));
              this.setIsEditMode(true);
              this.setIsFormDirty(false);
              Alert.success("Audience updated!");

              return this.getAudienceById(response.data.result.id);
          } else {
              if (response && response.data && response.data.message) {
                Alert.error(response.data.message);
              } else {
                Alert.error("Oops, Something went wrong! Please try again.");
              }
          }
        }
      )
    ).catch(
      action(
        err => {
          Alert.error(err);
          return Promise.reject(err);
        }
      )
    ));
  },

  getAudienceById(id) {
    return (ApiService.getDetail(ApiUrl.getAudienceDetails, id).then(
      action(
        response => {
          if (response && response.data && !response.data.isError) {
            this.setFormAudience(response.data.result);
            return response.data;
          } else {
            return Promise.reject(response.data.message);
          }
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },

  getLastActivity(id) {
    return (ApiService.getDetail(ApiUrl.getAudienceDetails, id).then(
      action(
        response => {
          if (response && response.data && !response.data.isError) {
            this.setFormAudience({ ...response.data.result, audienceName: this.formAudience.audienceName, description: this.formAudience.description });
            return response.data;
          } else {
            return Promise.reject(response.data.message);
          }
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },

  removeAudiences(ids, type = null) {
    return (
      ApiService.bulkRemove(ApiUrl.deleteAudience, type, ids, Constants.ALERT_DELETE_SUCCESS, '')
        .then(
          action(
            response => {
              if (response && response.data && !response.data.isError) {
                candidateAudienceStore.getAllAudience()
              } else {
                Promise.reject(response.data.message);
              }
            }
          )
        ).catch(
          action(
            err => {
              Alert.error(err);
              return Promise.reject(err);
            }
          )
        )
    );
  },

  removeAudienceClients(ids, type = null) {
    return (
      ApiService.bulkRemove(ApiUrl.deleteAudienceClient, type, ids, Constants.ALERT_DELETE_SUCCESS, '')
        .then(
          action(
            response => {
              if (response && response.data && !response.data.isError) {
                clientAudienceStore.getClientAudiences().then(res => {
                  if (res && res.data && !res.data.isError && res.status === 200) {
                    return res;
                  } else {
                    Promise.reject(res?.data?.message);
                  }
                }).catch(err => {
                  Alert.error(err);
                })
              } else {
                Promise.reject(response.data.message);
              }
            }
          )
        ).catch(
          action(
            err => {
              Alert.error(err);
              return Promise.reject(err);
            }
          )
        )
    )
  },

  onFetchDataAudienceMembers(id) {
    if (id === 0) {
      return Promise.reject("id cannot be null");
    }

    return (
      ApiService.get(ApiUrl.getAudienceMembers + id, "", 'Failed to get Candidates data')
      .then(
        action(
          response => {
            this.setListAudienceMember(response.data.result.map(x => { return { ...x, 'candidateId': x.contactId } }));
            return response.data;
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      )
    );
  },

  onFetchDataContactList(audienceId, contactType) {
    const param = `?audienceId=${audienceId}&contactType=${contactType}`;
    ApiService.get(ApiUrl.getContactList + param, "", 'Failed to get Candidates data')
      .then(response => {
        if (response && response.data && !response.data.isError) {
          let dataAPI = [];
          response.data.result.forEach(element => {
            let convertedObject = {
              ...element,
              updatedAt: moment.utc(element?.updatedAt).local().format('DD/MM/YYYY h:mma')
            }
            dataAPI.push(convertedObject)
          });
          this.setListContact(dataAPI);
        } else {
          return Promise.reject(response.data.message);
        }
      });
  },

  reset() {
    this.audienceId = 0;
    this.formAudience = {
      audienceName: '',
      audienceType: AudienceType.Manual,
      audienceContact: ContactType.Candidate,
      totalMembers: 0,
      contacted: null,
      description: '',
      isLive: false,
      campaignName: 'CampaignName',
      lastAddedDate: null,
      lastActivityDate: null,
      createdAt: null,
      createdBy: null,
      audienceSource: null,
      audienceSourceString: '',
    };
    this.listSelectedMember = [];
    this.listAudienceMember = [];
    this.isEditMode = false;
    this.isFormDirty = false;
    this.activeTab = "";
  }
});

export default audienceStore;
