import { ApiService, ApiUrl, Constants, FormatDate } from "helpers";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';
import { FormatDateUtc } from "helpers/FormatDateTable";

const textReportStore = observable(
    {
        textReports: [],
        filteredTextReports: [],
        filterTextReportModel: {},
        filterSearchText: "",
        textReportChartConfigDataset: [],
        dataId: undefined,
        campaignName: undefined,
        selectedReport: undefined,
        selectedReportLinks: undefined,
        selectedDetailsType: { typeId: 0, label: null },
        textReportDetailsLinkClicked: [],
        textReportDetailPercentage: [],
        textReportDetailsLink: [],
        selectedReportDetail: {},
        selectedDetailsToken: null,
        textReportDetails: [],
        chartInstance: null,
        textReportLinkTitle: "",
        textReportLinkOriginalUrl: "",
        comingFromType: 8,
        textReportDetailsLastSendDate: "-",

        setDataId(id) {
            this.dataId = id
        },

        setTextReportDetailsLastSendDate(date) {
            this.textReportDetailsLastSendDate = FormatDateUtc(date, false)
        },

        setTextReportLinkOriginalUrl(textReportLinkOriginalUrl) {
            this.textReportLinkOriginalUrl = textReportLinkOriginalUrl
        },

        setTextReportLinkTitle(textReportLinkTitle) {
            this.textReportLinkTitle = textReportLinkTitle
        },

        setComingFromType(type) {
            this.comingFromType = type
        },

        setSelectedReport(report) {
            this.selectedReport = report
        },

        setSelectedReportLinks(links) {
            this.selectedReportLinks = links
        },

        setSelectedDetailsType(detailsType) {
            this.selectedDetailsType = detailsType
        },

        setSelectedReportDetail(selectedReportDetail) {
            this.selectedReportDetail = {};
            this.selectedReportDetail = selectedReportDetail
        },

        setTextReportDetailPercentage(surveyReportDetailPercentage) {
            this.textReportDetailPercentage = [];
            this.textReportDetailPercentage = [...surveyReportDetailPercentage];
        },

        setTextReportDetailsLink(textReportDetailsLink) {
            this.textReportDetailsLink = [];
            this.textReportDetailsLink = [...textReportDetailsLink];
        },

        setTextReportDetailsLinkClicked(textReportDetailsLinkClicked) {
            this.textReportDetailsLinkClicked = [];
            this.textReportDetailsLinkClicked = [...textReportDetailsLinkClicked];
        },

        setSelectedDetailsToken(selectedDetailsToken) {
            this.selectedDetailsToken = selectedDetailsToken
        },

        setCampaignName(name) {
            this.campaignName = name
        },

        setTextReports(reports) {
            this.textReports = reports
            this.filteredTextReports = [...reports];
            this.filterTextReportModel = {};
        },

        setTextReportFilterModel(filter) {
            this.filterTextReportModel = filter;
        },

        setTextReportDetails(reportDetails) {
            this.textReportDetails = reportDetails
        },

        setTextReportChartConfigDataset(dataset) {
            this.textReportChartConfigDataset = dataset;
        },

        setChartInstance(chartInstance) {
            this.chartInstance = chartInstance;
        },

        getDataId() {
            return this.dataId
        },

        countDataSet(data) {
            let deliveryRate = data.delivered / data.sent * 100;
            let clickRate = data.linksClicked / data.sent * 100;
            let unsubscribeRate = data.unsubscribed / data.sent * 100;
            return [deliveryRate.toFixed(1), clickRate.toFixed(1), unsubscribeRate.toFixed(1)]
        },

        resetReportType() {
            this.selectedDetailsType = { typeId: 0, label: null };
            this.selectedDetailsToken = null;
        },

        resetTextReportStore() {
            this.textReports = undefined;
            this.textReportChartConfigDataset = [];
            this.dataId = undefined;
            this.campaignName = undefined;
            this.selectedReport = undefined;
            this.selectedReportLinks = undefined;
            this.selectedDetailsType = { typeId: 0, label: null };
            this.textReportDetailsLinkClicked = [];
            this.textReportDetailPercentage = [];
            this.textReportDetailsLink = [];
            this.selectedReportDetail = {};
            this.selectedDetailsToken = null;
            this.textReportDetails = [];
            this.chartInstance = null;
            this.comingFromType = 8;
        },

        getTextReports() {
            return (ApiService.get(ApiUrl.getTextReports).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            if (response.data.result.length !== 0) {
                                this.setTextReports(response.data.result);
                                this.setTextReportChartConfigDataset(this.countDataSet(response.data.result[0]));
                                this.setDataId(response.data.result[0].id);
                                this.setSelectedReport(response.data.result[0]);
                                this.setCampaignName(response.data.result[0].campaignName);
                                this.resetReportType();
                            } else {
                                this.setTextReportChartConfigDataset([0, 0]);
                            }

                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getTextReportLinkDetail(token, linkName, linkUrl) {
            const data = {
                reportToken: token,
                linkName: linkName,
                linkUrl: linkUrl
            };

            return (ApiService.post(ApiUrl.getTextReportLinkDetail, data).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setTextReportDetailsLinkClicked(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getTextReportDetailsLinksClicked(token) {
            return (ApiService.getDetail(ApiUrl.getTextReportLink, token).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setTextReportDetailsLink(response.data.result)
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getTextReportDetailsClicked(token) {
            const data = {
                reportToken: token,
            };

            return (ApiService.post(ApiUrl.getTextReportClicked, data).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setTextReportDetailsLinkClicked(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getTextReportDetails(token) {
            return (ApiService.getDetail(ApiUrl.getTextReportSelected, token).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            if (response.data.result[0]) {
                                this.setSelectedReportDetail(response.data.result[0])
                                this.setTextReportDetailPercentage([
                                    response.data.result[0].deliveredPercentage.toFixed(2),
                                    response.data.result[0].linkClickedPercentage.toFixed(2),
                                    response.data.result[0].unsubscribePercentage.toFixed(2),
                                ]);
                                this.setTextReportDetailsLastSendDate(response.data.result[0]?.reportText?.lastSendDate);
                            } else {
                                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                            }
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getTextReportDetailList(reportType, token) {
            const params = `${reportType}?reportToken=${token}`;
            return (ApiService.getDetail(ApiUrl.getTextReports, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setTextReportDetails(response.data.result)
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getTextReportDeliveredDetails(token) {
            const data = {
                reportToken: token,
            };

            return (ApiService.post(ApiUrl.getTextReportDelivered, data).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setTextReportDetails(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },
        setTextReportSearchFilterString(searchString) {
            const { FormatDateUtc } = FormatDate;
            //TODO: possible extract to helpers for searching strings, or extract to store helpers
            function ApplySearchStringIflastSendDateExists(item, searchString) {
                return !!item.reportText.lastSendDate && FormatDateUtc(item.reportText.lastSendDate).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
            }

            this.filterSearchText = searchString;

            this.filteredTextReports = this.textReports.filter(item =>
                ApplySearchStringIflastSendDateExists(item, searchString) ||
                item.reportText.automationName.toLowerCase().includes(searchString.toLowerCase()) ||
                item.reportText.campaignName.toLowerCase().includes(searchString.toLowerCase()) ||
                // item.category.toLowerCase().includes(searchString.toLowerCase()) ||
                item.reportText.lastSendDate?.toLowerCase().includes(searchString.toLowerCase())
            );
        },
    }
);

export default textReportStore;
