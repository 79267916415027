import { storyFormStore, generalSettingStore } from 'stores';
import { Constants, Enum } from 'helpers';
import * as yup from 'yup';

function isFormTitleValidationPass(title) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (title !== '') {
    isFormValidated.title = true
    return true
  }
  else {
    isFormValidated.title = false
    return false
  }
}

function isFormDescriptionValidationPass(description) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (description !== '') {
    isFormValidated.description = true
    return true
  }
  else {
    isFormValidated.description = false
    return false
  }
}

function isFormCharacterLimitValidationPass(characterLimit) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (characterLimit !== '' && (characterLimit > 0 && characterLimit <= 500)) {
    isFormValidated.characterLimit = true
    return true
  }
  else {
    isFormValidated.characterLimit = false
    return false
  }
}

function isTemplateNameValidationPass(text) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (text.trim() !== '' && text !== undefined) {
    isFormValidated.templateName = false
  }
  else {
    isFormValidated.templateName = true
  }
}

function isTemplateSummaryValidationPass(text) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (text.trim() !== '' && text !== undefined) {
    isFormValidated.templateDescription = false
  }
  else {
    isFormValidated.templateDescription = true
  }
}

function isFormScoreOneValidationPass(text) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (text !== '' && text !== undefined) {
    isFormValidated.scoreOne = true
    return true
  }
  else {
    isFormValidated.scoreOne = false
    return false
  }
}

function isFormScoreTenValidationPass(text) {
  const { isFormValidated } = storyFormStore;
  //add validation here
  if (text !== '' && text !== undefined) {
    isFormValidated.scoreTen = true
    return true
  }
  else {
    isFormValidated.scoreTen = false
    return false
  }
}

function isFormValidationPass(objectType) {
  const { isFormValidated } = storyFormStore;
  // here set the conditional depend on the objectType
  switch (objectType) {
    case Enum.CanvasNodeType.MultipleChoice:
      break;
    case Enum.CanvasNodeType.FreeTextReply:
      if (isFormValidated.description && isFormValidated.title && isFormValidated.characterLimit) {
        return true
      }
      break;
    case Enum.CanvasNodeType.NoReply:
      if (isFormValidated.description && isFormValidated.title) {
        return true
      }
      break;
    case Enum.CanvasNodeType.UserName:
      if (isFormValidated.description && isFormValidated.title && isFormValidated.characterLimit) {
        return true
      }
      break;
    case Enum.CanvasNodeType.EmailAddress:
      if (isFormValidated.description && isFormValidated.title && isFormValidated.characterLimit) {
        return true
      }
      break;
    case Enum.CanvasNodeType.PhoneNumber:
      if (isFormValidated.description && isFormValidated.title && isFormValidated.characterLimit) {
        return true
      }
      break;
    case Enum.CanvasNodeType.DocumentUpload:
      if (isFormValidated.description && isFormValidated.title) {
        return true
      }
      break;
    case Enum.CanvasNodeType.NetPromoterScore:
      if (isFormValidated.description && isFormValidated.title && isFormValidated.scoreOne && isFormValidated.scoreTen) {
        return true
      }
      break;
    default:
      return false
  }
}

const inputNumberOnKeypressHandler = (e) => {
  var keyCode = ('which' in e) ? e.which : e.keyCode;

  if (keyCode === 69 || keyCode === 101 || keyCode === 189 || keyCode === 45 || keyCode === 96 || (keyCode === 48 && e.target.value.length === 0))
    e.preventDefault();
}

const inputNumberOnlyOnKeypressHandler = (e) => {
  const keyCode = e.which || e.keyCode;

  if (keyCode < 48 || keyCode > 57) // only allow 0 - 9
    e.preventDefault();
}

const inputNumberOnPasteHandler = (e) => {
  let clipboardData = e.clipboardData || window.clipboardData;
  let pastedData = clipboardData.getData('Text').toUpperCase();

  if (pastedData.indexOf('E') > -1 || pastedData.indexOf('-') > -1 || pastedData.indexOf('0') > -1 || (pastedData.indexOf('0') > -1 && pastedData.charAt(0) === '0')) {
    e.stopPropagation();
    e.preventDefault();
  }
}

const emptyNumber = (typeErrorMessage = "Please enter a valid number!") => {
  return yup.number().transform(function (value, originalValue) {
    if (this.isType(value)) return value
    if (!originalValue || !originalValue.trim()) {
      return null
    }
    return originalValue
  }).typeError(typeErrorMessage);
}

const inputUrlFormat = (url) => {
  // eslint-disable-next-line
  var expression = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  var regex = new RegExp(expression);
  if (url) {
    if (url.match(regex)) {
      return true
    }
    else {
      return false
    }
  }
}

const checkValidDataValue = (value) => {
  if (value && value !== "null") {
    return true
  } else {
    return false
  }
}

const limitMaxLength = (e, maxLength) =>{
  // only keep character in MAXLENGTH range
  e.target.value = e.target.value.slice(0, maxLength);

  return e;
}

const trimLongText = (text, length = 10, endString = '...') => {
    if(text.length > length) {
        return text.substring(0, length).trimEnd() + endString
    } else {
        return text
    }
}

const countLimitText = (text)=>{
  const { lengthFooterText } = generalSettingStore;

  let textCount = text?.replace(/\s+/g," ").length;
  let totalTextCount = lengthFooterText + textCount;
  let totalMessageCount = Math.ceil(totalTextCount/160);

  return `${totalTextCount} / ${Constants.TEXT_MESSAGE_MAX_CHAR} (${totalMessageCount})`
}

const textWithoutSpace = (str) => {
  return str.replace(/\s/g, '');
}

const getDomainTitle = (linkTitle, linkUrl) => {
  const isTagHtml = linkTitle.indexOf("<") === 0;
  return isTagHtml && linkUrl ? extractRootDomain(linkUrl) : (isValidHttpUrl(linkTitle) ? extractRootDomain(linkTitle) : linkTitle)
}

const extractHostname = (url) => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }
//find & remove port number
hostname = hostname.split(':')[0];
//find & remove "?"
hostname = hostname.split('?')[0];
return hostname;
}

const extractRootDomain = (url) => {
  let domain = extractHostname(url),
      splitArr = domain.split('.'),
      arrLen = splitArr.length;
  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
      //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
      if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
          //this is using a ccTLD
          domain = splitArr[arrLen - 3] + '.' + domain;
      }
  }

  return domain;
}

// [NOTE] adding this to check string is url even http / https not in front of string
const isValidHttpUrl = (string) => {
    var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    if(!regex.test(string)) {
      return false;
    } else {
      return true;
    }
}

const isValidEmailAutomation = (email) => {
  //this regex for validation without domain
  const mailFormat = /^\w+([0-9a-z_.-])+$/;
  return mailFormat.test(email);
}

const isEmpty = (value) => 
  value === undefined || 
  value === null || 
  (typeof value === 'object' && Object.keys(value).length === 0) || 
  (typeof value === 'string' && value.trim().length === 0) ||
  (typeof value === 'string' && value === '[]');

const isValidEmail = (email) => {
  //this regex for validation with domain
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export {
  isFormTitleValidationPass,
  isFormDescriptionValidationPass,
  isFormCharacterLimitValidationPass,
  isFormScoreOneValidationPass,
  isFormScoreTenValidationPass,
  isFormValidationPass,
  isTemplateNameValidationPass,
  isTemplateSummaryValidationPass,
  inputNumberOnKeypressHandler,
  inputNumberOnlyOnKeypressHandler,
  inputNumberOnPasteHandler,
  emptyNumber,
  inputUrlFormat,
  checkValidDataValue,
  limitMaxLength,
  trimLongText,
  countLimitText,
  textWithoutSpace,
  getDomainTitle,
  isValidEmailAutomation,
  isEmpty,
  isValidEmail
}
