import { observable, action } from "mobx";
import { storyType, UploadToS3Type, FinalizeWidgetPlacement } from "helpers/enum";
import { ApiUrl, ApiService, AwsS3Service } from "helpers";
import fabricStore from "./fabricStore";
import Alert from 'react-s-alert';
import defaultAvatarPreview from "assets/img/chatbot/default-icon.svg";
import { DEFAULT_AUTO_CLOSE_ABANDON_BOT_CONVERSATION } from "helpers/Constants";
import { convertCanvasArrayToChatbotArray } from "components/Chatbots/commons";
import { v4 as uuidv4 } from "uuid";
import { Enum } from 'helpers';


var chatbotStore = observable(
  {
    /* some observable state */
    id: null,
    storyName: '',
    storyType: storyType.website,
    botName: '',
    botDescription: '',
    botAvatar: '',
    botAvatarPreview: defaultAvatarPreview,
    botAvatarUrl: null,
    headerColor: "#EF4277",
    fontColor: "#FFFFFF",
    isPublished: false,
    canvasData: undefined,
    isCreateFromScratchSubmitted: false,
    disabledFinalizeAutoCloseBot: true,
    finalizeAutoCloseBotTime: DEFAULT_AUTO_CLOSE_ABANDON_BOT_CONVERSATION,
    finalizeWidgetPlacement: FinalizeWidgetPlacement.BottomRight,
    isFinalizeAutoCloseBotTimeValid: true,
    apiKey: undefined,
    isFinalizeSaved: false,
    testKey: undefined,
    showCopyScript: false,
    showLiveLink: false,

    // chatbot tab
    activeTab: 'tabDetails',

    previewChatbotData: [],
    isPreviewChatbot: false,
    isStoryTabDisabled: true,
    isCanvasSaved: false,
    isNonFileSaved: false,
    isStorySaved: false,
    isBotAvatarEdited: false,

    isUploading: false,
    isUploadFailed: false,
    uploadPercentage: 0,
    botAvatarUploadPercentage: 0,

    // variable for upload purpose
    uploadedId: undefined,
    uploadedBotAvatar: undefined,
    uploadedCanvasData: undefined,
    uploadedCanvasObjects: undefined,
    uploadedCanvasJson: undefined,

    defaultAvatarPreview: defaultAvatarPreview,
    chatbotList: [],

    isCardView: true,

    hasMoreData: true,
    infinityScrollPage: 1,
    isLoadingInfinityScroll: false,
    filterManageChatbot: {
      storyType: {
        all: true,
        website: true,
        dataCleanse: true,
        registration: true,
        survey: true
      },
      status: {
        published: true,
        unpublished: true
      },
      sort: 2,
      searchType: 0,
      search: ''
    },

    // set the action here
    setId(id) {
      this.id = id;
    },
    setStoryName(storyName) {
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.storyName = storyName;
    },
    setStoryType(storyType) {
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.storyType = parseInt(storyType);
    },
    setBotName(botName) {
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.botName = botName;
    },
    setBotDescription(botDescription) {
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.botDescription = botDescription;
    },
    setBotAvatar(botAvatar) {
      this.isCanvasSaved = false;
      this.isStorySaved = false;
      this.botAvatar = botAvatar;
    },
    setBotAvatarPreview(botAvatarPreview) {
      this.botAvatarPreview = botAvatarPreview;
    },
    setBotAvatarUrl(botAvatarUrl) {
      this.botAvatarUrl = botAvatarUrl;
    },
    setHeaderColor(headerColor) {
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.headerColor = headerColor;
    },
    setFontColor(fontColor) {
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.fontColor = fontColor;
    },
    setCanvasData(canvasData) {
      this.canvasData = canvasData;
    },
    setPreviewChatbotData(previewChatbotData) {
      this.previewChatbotData = previewChatbotData
    },
    setIsPreviewChatbot(isPreviewChatbot) {
      this.isPreviewChatbot = isPreviewChatbot
    },
    setIsStoryTabDisabled(isStoryTabDisabled) {
      this.isStoryTabDisabled = isStoryTabDisabled;
    },
    setIsCanvasSaved(isCanvasSaved) {
      this.isCanvasSaved = isCanvasSaved;
    },
    setIsNonFileSaved(isNonFileSaved) {
      this.isNonFileSaved = isNonFileSaved;
    },
    setIsStorySaved(isStorySaved) {
      this.isStorySaved = isStorySaved
    },
    setActiveTab(activeTab) {
      this.isPreviewChatbot = false;
      this.activeTab = activeTab;
    },
    setIsBotAvatarEdited(isBotAvatarEdited) {
      this.isBotAvatarEdited = isBotAvatarEdited;
    },
    setIsUploading(isUploading) {
      this.isUploading = isUploading;
      this.uploadPercentage = 0;
    },
    setIsUploadFailed(isUploadFailed) {
      this.isUploadFailed = isUploadFailed
    },
    setIsCreateFromScratchSubmitted(isCreateFromScratchSubmitted) {
      this.isCreateFromScratchSubmitted = isCreateFromScratchSubmitted;
    },
    setDisabledFinalizeAutoCloseBot(disabledFinalizeAutoCloseBot) {
      this.isNonFileSaved = false;
      this.disabledFinalizeAutoCloseBot = disabledFinalizeAutoCloseBot;
    },
    setFinalizeAutoCloseBotTime(finalizeAutoCloseBotTime) {
      this.isFinalizeSaved = false;
      this.isNonFileSaved = false;
      this.finalizeAutoCloseBotTime = finalizeAutoCloseBotTime;
    },
    setFinalizeWidgetPlacement(finalizeWidgetPlacement) {
      this.isFinalizeSaved = false;
      this.isNonFileSaved = false;
      this.finalizeWidgetPlacement = finalizeWidgetPlacement;
    },
    setIsFinalizeAutoCloseBotTimeValid(isFinalizeAutoCloseBotTimeValid) {
      this.isNonFileSaved = false;
      this.isFinalizeAutoCloseBotTimeValid = isFinalizeAutoCloseBotTimeValid;
    },
    setIsPublished(isPublished) {
      this.isPublished = isPublished;
    },
    setIsFinalizeSaved(isFinalizeSaved) {
      this.isFinalizeSaved = isFinalizeSaved;
    },
    setIsCardView(isCardView) {
      this.resetInfinityScroll();
      this.isCardView = isCardView;
    },
    onClickPreviewChatbot() {
      let chatbotArray = convertCanvasArrayToChatbotArray()

      this.setPreviewChatbotData(chatbotArray)
      this.setIsPreviewChatbot(true)
    },
    showCopyScriptOrLiveLink() {
      if (this.isPublished) {
        if (this.storyType === storyType.website) {
          this.showCopyScript = true;
          this.showLiveLink = false;
        } else {
          this.showCopyScript = false;
          this.showLiveLink = true;
        }
      }
    },
    resetForm() {
      this.id = null;
      this.storyName = '';
      this.storyType = storyType.website;
      this.botName = '';
      this.botDescription = '';
      this.botAvatar = '';
      this.botAvatarPreview = defaultAvatarPreview;
      this.headerColor = "#00D084";
      this.fontColor = "#FFFFFF";
      this.isBotAvatarEdited = false;
      this.isPublished = false;
      this.canvasData = undefined;
      this.isStoryTabDisabled = true;
      this.isCanvasSaved = false;
      this.isNonFileSaved = false;
      this.isStorySaved = false;
      this.isCreateFromScratchSubmitted = false;
      this.activeTab = 'tabDetails';
      this.isPreviewChatbot = false;
      this.disabledFinalizeAutoCloseBot = true;
      this.finalizeAutoCloseBotTime = DEFAULT_AUTO_CLOSE_ABANDON_BOT_CONVERSATION;
      this.finalizeWidgetPlacement = FinalizeWidgetPlacement.BottomRight;
      this.isFinalizeAutoCloseBotTimeValid = true;
      this.isPreviewManage = false;
      this.isRowSelectedPreviewManage = null;
      this.apiKey = undefined;
      this.testKey = undefined;
      this.showCopyScript = false;
      this.showLiveLink = false;
    },

    loadForm(data) {
      this.id = data.id;
      this.storyName = data.storyName;
      this.storyType = data.storyType;
      this.botName = data.botName;
      if (data.botAvatarUrl === defaultAvatarPreview) {
        this.botAvatar = '';
        this.botAvatarPreview = defaultAvatarPreview;
        this.botAvatarUrl = null;
      } else {
        this.botAvatar = '';
        this.botAvatarPreview = data.botAvatarUrl;
        this.botAvatarUrl = data.botAvatarUrl;
      }
      this.botDescription = data.botDescription;
      this.headerColor = data.botHeaderColor;
      this.fontColor = data.botFontColor;
      this.isBotAvatarEdited = false;
      this.isPublished = data.isPublished;
      this.isStoryTabDisabled = false;
      this.isCanvasSaved = true;
      this.isNonFileSaved = true;
      this.isStorySaved = true;
      this.isCreateFromScratchSubmitted = true;
      this.activeTab = 'tabStory';
      this.isPreviewChatbot = false;
      this.disabledFinalizeAutoCloseBot = !data.isAutoCloseBotEnabled;
      this.finalizeAutoCloseBotTime = data.autoCloseBot;
      this.finalizeWidgetPlacement = data.widgetPlacement;
      this.isFinalizeAutoCloseBotTimeValid = true;
      this.isPreviewManage = false;
      this.isRowSelectedPreviewManage = null;
      this.apiKey = data.apiKey;
      this.testKey = data.testKey;
      this.showCopyScriptOrLiveLink();
    },

    resetUploadObservable() {
      this.uploadedId = undefined;
      this.uploadedBotAvatar = undefined;
      this.uploadedCanvasData = undefined;
      this.uploadedCanvasObjects = undefined;
      this.uploadedCanvasJson = undefined;
    },

    setUploadPercentage(event, type) {
      // check if internet is offline
      if (!navigator.onLine) {
        this.setIsUploadFailed(true);
        this.setIsUploading(false);
      }
      switch (type) {
        case UploadToS3Type.Avatar:
          this.botAvatarUploadPercentage = Math.floor((100 * event.loaded) / event.total);
          break;
        default:
          break;
      }
      this.uploadPercentage = Math.floor((this.botAvatarUploadPercentage));
    },

    save(data) {
      return (ApiService.add(ApiUrl.addChatbot, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.id = response.data.result.id;
              this.isPublished = response.data.result.isPublished;
              this.showCopyScriptOrLiveLink();
              window.history.pushState("", "", "/admin/chatbot/edit/" + response.data.result.id);
              // do upload if have changes on canvas
              if (!this.isCanvasSaved) {
                // let this become background processing
                this.prepareDataUploadChatbotAttachment();
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    liveChatbot(apiKey) {
      return (ApiService.getDetail(ApiUrl.detailLiveChatbot, apiKey, '').then(response => {
        return Promise.resolve(response);
      }).catch(err => {
        return Promise.reject(err)
      }));
    },

    widgetChatbot(apiKey) {
      return (ApiService.getDetail(ApiUrl.widgetChatbot, apiKey, '').then(response => {
        return Promise.resolve(response);
      }).catch(err => {
        return Promise.reject(err)
      }));
    },

    testChatbot(testKey) {
      return (ApiService.getDetail(ApiUrl.detailTestChatbot, testKey, '').then(response => {
        return Promise.resolve(response);
      }).catch(err => {
        return Promise.reject(err)
      }));
    },

    update(data) {
      const updateData = {
        ...data,
        id: this.id
      };

      return (ApiService.edit(ApiUrl.updateChatbot, updateData).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.isPublished = response.data.result.isPublished;
              this.showCopyScriptOrLiveLink();
              // do upload if have changes on canvas
              if (!this.isCanvasSaved) {
                // let this become background processing
                this.prepareDataUploadChatbotAttachment();
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    publish(isPublished = false) {
      const data = {
        id: this.id,
        isPublished: isPublished
      };

      return (ApiService.edit(ApiUrl.updatePublishStatus, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.isPublished = response.data.result.isPublished;
              this.showCopyScriptOrLiveLink();
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    delete() {
      return (ApiService.remove(ApiUrl.deleteChatbot, this.id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.chatbotList = this.chatbotList.filter(item => item.id !== this.id);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ).finally(
        action(
          () => this.setId(null))
      ));
    },

    manageChatbot() {
      return (ApiService.get(ApiUrl.manageChatbot).then(response => {
        this.chatbotList = response.data.result
        return Promise.resolve(response);
      }).catch(err => {
        return Promise.reject(err)
      }));
    },

    submit(isPublished = false) {
      if (!this.id) {
        this.apiKey = uuidv4();
        this.testKey = uuidv4();
      } else {
        if (!this.apiKey) this.apiKey = uuidv4();
        if (!this.testKey) this.testKey = uuidv4();
      }

      const data = {
        storyName: this.storyName,
        storyType: this.storyType,
        botName: this.botName,
        botDescription: this.botDescription,
        botHeaderColor: this.headerColor,
        botFontColor: this.fontColor,
        isPublished: isPublished,
        autoCloseBot: this.finalizeAutoCloseBotTime,
        widgetPlacement: this.finalizeWidgetPlacement,
        isAutoCloseBotEnabled: !this.disabledFinalizeAutoCloseBot,
        apiKey: this.apiKey,
        testKey: this.testKey
      };

      if (this.id) {
        return this.update(data);
      } else {
        return this.save(data);
      }
    },

    prepareDataUploadChatbotAttachment() {
      const { canvas, canvasObjects } = fabricStore;

      // observable use for upload
      this.uploadedId = this.id;
      this.uploadedBotAvatar = this.botAvatar;
      this.uploadedCanvasData = { ...canvas };
      this.uploadedCanvasJson = JSON.stringify(canvas.toJSON());
      this.uploadedCanvasObjects = [...canvasObjects];

      this.uploadChatbotAttachment();
    },

    uploadChatbotAttachment() {
      this.setIsUploadFailed(false);
      this.setIsUploading(true);
      try {
        // Set actual percentage here, if by any chance, user didn't change bot avatar / choose template design, directly set the percentage to 100% for them
        this.botAvatarUploadPercentage = this.uploadedBotAvatar !== '' ? 0 : 100;
        const moveCanvasAttachments = Promise.all(this.uploadedCanvasObjects.map((data, index) => {
          let promiseImage = false,
            promiseGif = false,
            promiseVideo = false;
          if (data.attImage) {
            promiseImage = AwsS3Service.moveFileS3(data.attImage, 'chatbot/attachments');
          }
          if (data.attGif) {
            promiseGif = AwsS3Service.moveFileS3(data.attGif, 'chatbot/attachments');
          }
          if (data.attVideo) {
            promiseVideo = AwsS3Service.moveFileS3(data.attVideo, 'chatbot/attachments');
          }

          return Promise.all([promiseImage, promiseGif, promiseVideo]).then(response => {
            if (response[0]) {
              this.uploadedCanvasJson = this.uploadedCanvasJson.replace(data.attImage.toString(), response[0].Location.toString());
            }
            if (response[1]) {
              this.uploadedCanvasJson = this.uploadedCanvasJson.replace(data.attGif.toString(), response[1].Location.toString());
            }
            if (response[2]) {
              this.uploadedCanvasJson = this.uploadedCanvasJson.replace(data.attVideo.toString(), response[2].Location.toString());
            }

            return Promise.resolve(data);
          });
        })).then(result => {
          return result;
        });
        const uploadAvatar = this.isBotAvatarEdited ? AwsS3Service.uploadToS3(this.uploadedBotAvatar, UploadToS3Type.Avatar) : { Location: this.botAvatarUrl !== null ? this.botAvatarUrl : defaultAvatarPreview };

        return (Promise.all([uploadAvatar, moveCanvasAttachments]).then(response => {
          const data = {
            id: this.uploadedId,
            botAvatarUrl: response[0].Location,
            canvasData: this.uploadedCanvasJson
          };

          return (ApiService.editAsync(ApiUrl.updateChatbotAttachment, data).then(
            action(
              response => {
                // reset observable data after successful upload and update data into database
                this.resetUploadObservable();
                this.setIsUploading(false);
                return Promise.resolve(response);
              }
            )
          ).catch(
            action(
              err => {
                // failed update database here
                return Promise.reject(err);
              }
            )
          ));
        }).catch(err => {
          // failed upload data to S3 here
          Alert.error("Oops, Something went wrong! Please try to upload again.");
          this.setIsUploadFailed(true);
          this.setIsUploading(false);
          return Promise.reject(err);
        }));
      } catch (err) {
        console.log(err);
      }
    },

    load(id) {
      return (ApiService.getDetail(ApiUrl.getChatbotById, id).then(
        action(
          response => {
            if (response && response.data && !response.data.isError) {
              this.loadForm(response.data.result);
              return response.data;
            } else {
              return Promise.reject(response.data.message);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    loadCanvasData(id) {
      return (ApiService.get(`${ApiUrl.getChatbotById}${id}?isCanvasData=${true}`).then(
        action(
          response => {
            this.canvasData = response.data.result.canvasData;
            return this.canvasData;
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getExistingChatbot(page = 1) {
      let filterStoryType = []
      let filterStatusType = []

      filterStoryType.push(this.filterManageChatbot.storyType.website ? Enum.storyType.Website : null)
      filterStoryType.push(this.filterManageChatbot.storyType.dataCleanse ? Enum.storyType.DataCleanse : null)
      filterStoryType.push(this.filterManageChatbot.storyType.registration ? Enum.storyType.Registration : null)
      filterStoryType.push(this.filterManageChatbot.storyType.survey ? Enum.storyType.Survey : null)
      filterStoryType = filterStoryType.filter(x => Number.isInteger(x)) // removes NULL from array

      filterStatusType.push(this.filterManageChatbot.status.published ? 1 : null)
      filterStatusType.push(this.filterManageChatbot.status.unpublished ? 0 : null)
      filterStatusType = filterStatusType.filter(x => Number.isInteger(x)) // removes NULL from array

      let data = {
        storyType: filterStoryType,
        storyStatus: filterStatusType,
        searchType: this.filterManageChatbot.searchType,
        search: this.filterManageChatbot.search,
        sort: this.filterManageChatbot.sort,
        page: page
      }

      this.isLoadingInfinityScroll = true;
      return (ApiService.get(`${ApiUrl.filter}?storyType=${data.storyType}&storyStatus=${data.storyStatus}&searchType=${data.searchType}&search=${data.search}&sort=${data.sort}&page=${data.page}`)
        .then(
          action(response => {
            let array = [];
            response.data.result.chatbotCards.forEach(data => {
              array.push(data)
            });

            this.hasMoreData = response.data.result.hasNextPage ? true : false;
            this.pushChatbotList(array);
            this.infinityScrollPage = this.infinityScrollPage + 1;
            return Promise.resolve(this.hasMoreData);
          }))
        .catch(
          action(err => {
            return Promise.reject(err);
          })
        ).finally(
          action(() => {
            this.isLoadingInfinityScroll = false;
          })
        ));
    },

    onClickFilterStoryType(isChecked, storyType) {
      let newFilterManageChatbot = this.filterManageChatbot
      switch (storyType) {
        case Enum.storyType.All:
          if (isChecked) {
            newFilterManageChatbot.storyType.all = true
            newFilterManageChatbot.storyType.dataCleanse = true
            newFilterManageChatbot.storyType.registration = true
            newFilterManageChatbot.storyType.survey = true
            newFilterManageChatbot.storyType.website = true
          }
          else {
            newFilterManageChatbot.storyType.all = false
            newFilterManageChatbot.storyType.dataCleanse = false
            newFilterManageChatbot.storyType.registration = false
            newFilterManageChatbot.storyType.survey = false
            newFilterManageChatbot.storyType.website = false
          }
          break;
        case Enum.storyType.Website:
          isChecked ? newFilterManageChatbot.storyType.website = true : newFilterManageChatbot.storyType.website = false
          break;
        case Enum.storyType.DataCleanse:
          isChecked ? newFilterManageChatbot.storyType.dataCleanse = true : newFilterManageChatbot.storyType.dataCleanse = false
          break;
        case Enum.storyType.Registration:
          isChecked ? newFilterManageChatbot.storyType.registration = true : newFilterManageChatbot.storyType.registration = false
          break;
        case Enum.storyType.Survey:
          isChecked ? newFilterManageChatbot.storyType.survey = true : newFilterManageChatbot.storyType.survey = false
          break;
        default:
          break
      }

      if (
        newFilterManageChatbot.storyType.dataCleanse &&
        newFilterManageChatbot.storyType.registration &&
        newFilterManageChatbot.storyType.survey &&
        newFilterManageChatbot.storyType.website
      ) {
        newFilterManageChatbot.storyType.all = true
      }
      else {
        newFilterManageChatbot.storyType.all = false
      }

      this.filterManageChatbot = newFilterManageChatbot

      this.resetInfinityScroll()
    },

    onClickFilterStatusType(isChecked, statusType) {
      let newFilterManageChatbot = this.filterManageChatbot
      switch (statusType) {
        case 1:
          isChecked ? newFilterManageChatbot.status.published = true : newFilterManageChatbot.status.published = false
          break;
        case 0:
          isChecked ? newFilterManageChatbot.status.unpublished = true : newFilterManageChatbot.status.unpublished = false
          break;
        default:
          break
      }

      this.filterManageChatbot = newFilterManageChatbot

      this.resetInfinityScroll()
    },

    onClickFilterSorting(sort) {
      this.filterManageChatbot.sort = sort
      this.resetInfinityScroll()
    },

    setSearchFilter(search) {
      this.filterManageChatbot.search = search

      this.chatbotList = []
      this.hasMoreData = true
      this.infinityScrollPage = 1
    },

    setSearchTypeFilter(searchType) {
      this.filterManageChatbot.searchType = searchType
    },

    resetInfinityScroll() {
      this.filterManageChatbot.search = ''
      this.chatbotList = []
      this.hasMoreData = true
      this.infinityScrollPage = 1
    },

    setInfinityScroll(infinityScrollPage) {
      this.infinityScrollPage = infinityScrollPage
    },

    pushChatbotList(chatbotList) {
      // this.chatbotList.push(chatbotList)
      this.chatbotList = this.chatbotList.concat(chatbotList);
    },

    emptyChatbotList() {
      this.chatbotList = []
    }
  },
);

/* a function that observes the state */
// autorun(function () {
// })

export default chatbotStore;
