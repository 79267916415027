import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { observer } from "mobx-react";
import HeaderForm from "./HeaderForm";
import stepTextIcon from "assets/img/automation/step-text.svg";
import { RappidNodeType, RappidText } from "helpers/Constants";
import { Col, FormGroup, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import { automationStore, atsStore, surveyStore, generalSettingStore, campaignStore } from "stores";
import SubmitButton from "./SubmitButton";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import { Constants, Enum } from "helpers";
import "react-phone-input-2/lib/style.css";
import { countLimitText } from "helpers/Validator"
import { NoBorderSelect } from '_globalStyle';
import { TextCount, AutomationLabelSchedule, AutomationDateSpan, DateFormGroup } from "../Styled";
import TimeInput from "./TimeInput";
import DatePicker from 'react-datepicker';
import { SwitchColor } from "_variable";
import Switch from "react-switch";
import { ListDaysShort, AutomationSpecificDays, UpdateRecordType, TriggerType, UpdateRecordTypeClient, AutomationType } from "helpers/enum";
import Select from 'react-select';
import moment from "moment";
import { toJS } from "mobx";
import { getScheduleText, getRecordUpdate } from "helpers/AutomationHelper";
import SurveyDropdown from "../components/SurveyDropdown";
import MergeTagsDropdown from "../components/MergeTagsDropdown";


const TextForm = observer(forwardRef((props, ref) => {
  const { formText, triggerList, triggerType, automationType, automationState } = automationStore;
  const { atsCategorisedFieldForTextMergeTags, isJobScopeDisabled } = atsStore;
  const { surveyTags } = surveyStore;
  const disableButton = formText.title === "" ||
    formText.message === "" ||
    formText.messageTextOnly === "" ||
    (formText.isSpecificTime && (!formText.startHour || formText.specificDays.length === 0));
  const editorRef = useRef(null);
  const optionsUpdateRecord = automationType === AutomationType.Candidate ? UpdateRecordType : UpdateRecordTypeClient;
  const updateRecordDefaultValue = optionsUpdateRecord[0];
  const [updateRecordOption, setUpdateRecordOption] = useState(optionsUpdateRecord);
  const isPlacementOrJobBasedTrigger = triggerType === TriggerType.PlacementBased || triggerType === TriggerType.JobBased;
  const isDraft = automationState === Enum.AutomationStateType.Draft;
  const [isEditorLinkOpen, setIsEditorLinkOpen] = useState(false);

  console.debug("Text Form Data: ", toJS(formText));

  const isShowPlacementAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.PlacementJobApplicationReceived
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [
        Enum.TriggerListClient.ClientPlacementCreatedDate,
        Enum.TriggerListClient.ClientPlacementStartDate,
        Enum.TriggerListClient.ClientPlacementEndDate
      ].includes(item.firstColumn.id));
    }
  }

  const isShowApplicationAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.ApplicationStatus,
        Enum.TriggerList.JobStatus,
        Enum.TriggerList.JobJobApplicationReceived,
        Enum.TriggerList.PlacementJobApplicationReceived,
        Enum.TriggerList.InterviewDate
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [].includes(item.firstColumn.id));
    }
  }

  const isShowJobAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.ApplicationStatus,
        Enum.TriggerList.JobStatus,
        Enum.TriggerList.JobJobApplicationReceived,
        Enum.TriggerList.PlacementJobApplicationReceived,
        Enum.TriggerList.InterviewDate
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [
        Enum.TriggerListClient.ClientPlacementCreatedDate,
        Enum.TriggerListClient.ClientPlacementStartDate,
        Enum.TriggerListClient.ClientPlacementEndDate,
        Enum.TriggerListClient.JobStatus,
        Enum.TriggerListClient.ClientJobInterviewDate
      ].includes(item.firstColumn.id));
    }
  }

  useEffect(() => {
    Promise.all([atsStore.getCompanyInfo(), atsStore.getAtsField(), atsStore.getAtsCategorisedFields(automationType), surveyStore.getSurveyTags(), generalSettingStore.getLengthFooterText()]).then(() => {
      //add new properties for handle old data text and reset specific time #8293
      if (typeof formText.isSpecificTime === "undefined" || !automationStore.formText.isSpecificTime) {
        let formData = { ...automationStore.formText };
        formData.isSpecificTime = false;
        formData.specificDays = [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday];
        formData.startHour = null;
        formData.convertedStartHour = null;
        automationStore.setFormText(formData, true);
      }

      //add new properties for handle old data #8292
      if (typeof formText.isUpdateRecord === "undefined") {
        let data = { ...formText };
        data.isUpdateRecord = false;
        data.updateRecordType = 1;
        automationStore.setFormText(data, true);
      }
      if (!isPlacementOrJobBasedTrigger || triggerList.length === 0) {
        //reset owner when trigger list is null or trigger type is not placement based #8292
        if (!automationStore.formText.updateRecordType || automationStore.formText.updateRecordType === optionsUpdateRecord[1].value) {
          automationStore.setFormText({ ...automationStore.formText, updateRecordType: updateRecordDefaultValue.value }, true);
        }
        setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== optionsUpdateRecord[1].value));
      }

      if (!isPlacementOrJobBasedTrigger && automationStore.formText.isUpdateRecord) {
        if (automationStore.formText.additionalNoteType ?? false !== Enum.UpdateRecordNoteType.CandidateNote) {
          automationStore.setFormText({ ...automationStore.formText, additionalNoteType: Enum.UpdateRecordNoteType.CandidateNote });
        }
      }

      if (isPlacementOrJobBasedTrigger && automationStore.formText.isUpdateRecord) {
        if (automationStore.formText.additionalNoteType ?? false !== Enum.UpdateRecordNoteType.CandidateNote) {
          setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser));
        }
      }
    });

    return function cleanup() {
      automationStore.resetFormText();
    }
    // eslint-disable-next-line
  }, []);

  const handleAutomationTextTokenSelected = (token) => {
    const automationTextToken = ` {{${token}}}`;
    if (editorRef.current) {
      editorRef.current.insertContent(automationTextToken);
      automationStore.setFormText({
        ...formText,
        message: editorRef.current.getContent(),
        messageTextOnly: editorRef.current.getContent({ format: "text" })
      });
    }
  };

  const renderDaysComponent = (ListDays) => {
    let data = { ...automationStore.formText };
    data.specificDays = [...automationStore.formText.specificDays];
    data.timeVariableDays = data.specificDays.toString();

    const daysComponent = ListDays.map((item, index) => {
      let days = [...automationStore.formText.specificDays];
      let isChecked = days.find(x => x === item.value);

      return (
        <AutomationDateSpan key={index}>
          <Input
            type="checkbox"
            id={`text-form-time-variable-${item.day}`}
            className="automation-time-variable-checkbox-days text-white"
            name={`text-form-time-variable-${item.day}`}
            checked={isChecked ? true : false}
            disabled={!isDraft}
            onChange={(e) => {
              let data = { ...formText };
              data.specificDays = [...formText.specificDays];
              data.timeVariableDays = data.specificDays.slice();
              const checked = e.target.checked;
              if (checked) {
                data.specificDays = [...data.specificDays, item.value];
                data.timeVariableDays = data.specificDays.toString();
              } else {
                const removeValue = data.specificDays.indexOf(item.value);
                data.specificDays.splice(removeValue, 1);
                data.timeVariableDays = data.specificDays.toString();
              }
              automationStore.setFormText(data);
            }}
          />
          <Label htmlFor={`text-form-time-variable-${item.day}`} >
            {item.day}
          </Label>
        </AutomationDateSpan>
      );
    });

    return daysComponent;
  };

  const isShowAdditionalNotes = () => !isJobScopeDisabled && isShowJobAdditionalNotes();

  useImperativeHandle(ref, () => ({
    isModalOpen: () => isEditorLinkOpen,
    openModal: () => setIsEditorLinkOpen(true),
    closeModal: () => setIsEditorLinkOpen(false)
  }));

  return (
    <>
      <HeaderForm
        automationHeaderId="form-step-text"
        automationHeaderImgSrc={stepTextIcon}
        automationHeaderImgAlt="icon-text"
        automationHeaderTextStep={RappidText.TextStep}
      />
      <FormGroup className="step-body-text">
        <span className="mb-3"><strong><Label id="messageNameTitle" className="text-white">Message name</Label></strong></span>
        <UncontrolledTooltip placement="bottom" target="messageNameTitle">
          The name used for internal reporting
        </UncontrolledTooltip>
        <Input
          type="text"
          id="automation-text-form-title"
          className="rounded-pill automation-email-input"
          value={formText.title}
          disabled={!isDraft}
          onChange={e => {
            automationStore.setFormText({ ...formText, title: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup className="step-body-text">
        <Input
          type="checkbox"
          id="automation-text-update-record-input"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          checked={formText.isUpdateRecord}
          disabled={!isDraft}
          onChange={(e) => {
            automationStore.setFormText({ ...formText, isUpdateRecord: !formText.isUpdateRecord });
          }}
        />
        <Label check id="update-record-text" htmlFor={`automation-text-update-record-input`} className="mb-2">
          Update database using Message Name
        </Label>
        <UncontrolledTooltip placement="bottom" target="update-record-text" style={{ backgroundColor: "white", color: "black" }}>
          Use the message name to update the database notes
        </UncontrolledTooltip>
        <Select
          isDisabled={!formText.isUpdateRecord || !isDraft}
          id="automation-text-from-update-record-type"
          className={`automation-text-template col-md-12 px-0 mb-2 ${!formText.isUpdateRecord && `automation-disable-dropdown`}`}
          classNamePrefix="automation-dropdown"
          placeholder="Select Update Record"
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll={true}
          onChange={(e) => {
            automationStore.setFormText({ ...formText, updateRecordType: e.value });
          }}
          options={updateRecordOption}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          value={formText.updateRecordType ? updateRecordOption.find(x => x.value === formText.updateRecordType) : updateRecordDefaultValue}
        />
      </FormGroup>

      {formText.isUpdateRecord && isShowAdditionalNotes() &&
        <>
          <FormGroup className="step-body-text">
            <span className="mb-3"><b><Label className="text-white">Add note to</Label></b></span><br />
            <Input
              type="checkbox"
              id="automation-update-to-candidate-note"
              className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
              checked={formText.isUpdateRecord && formText.additionalNoteType === Enum.UpdateRecordNoteType.CandidateNote}
              disabled={!isDraft}
              onChange={(e) => {
                automationStore.setFormText({
                  ...formText,
                  isUpdateRecord: formText.isUpdateRecord,
                  isUpdateAdditionalNotes: formText.isUpdateRecord,
                  additionalNoteType: Enum.UpdateRecordNoteType.CandidateNote
                });
                setUpdateRecordOption(UpdateRecordType);
              }}
            />
            <Label check htmlFor={`automation-update-to-candidate-note`} className="mb-1 mr-3">
              {automationType === Enum.AutomationType.Candidate ? 'Candidate' : 'Contact'}
            </Label>
            {isShowPlacementAdditionalNotes() &&
              <>
                <Input
                  type="checkbox"
                  id="automation-update-to-placement-note"
                  className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                  checked={formText.isUpdateRecord && formText.additionalNoteType === Enum.UpdateRecordNoteType.PlacementNote}
                  disabled={!isDraft}
                  onChange={(e) => {
                    automationStore.setFormText({
                      ...formText,
                      isUpdateRecord: formText.isUpdateRecord,
                      isUpdateAdditionalNotes: formText.isUpdateRecord,
                      additionalNoteType: Enum.UpdateRecordNoteType.PlacementNote,
                      updateRecordType: formText.updateRecordType === Enum.UpdateRecordOwnerType.NoUser ? Enum.UpdateRecordOwnerType.AdminUser : formText.updateRecordType
                    });
                    setUpdateRecordOption(UpdateRecordType.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser));
                  }}
                />
                <Label check htmlFor={`automation-update-to-placement-note`} className="mb-1 mr-3">
                  Placement
                </Label>
              </>
            }
            <Input
              type="checkbox"
              id="automation-update-to-job-note"
              className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
              checked={formText.isUpdateRecord && formText.additionalNoteType === Enum.UpdateRecordNoteType.JobNote}
              disabled={!isDraft}
              onChange={(e) => {
                automationStore.setFormText({
                  ...formText,
                  isUpdateRecord: formText.isUpdateRecord,
                  isUpdateAdditionalNotes: formText.isUpdateRecord,
                  additionalNoteType: Enum.UpdateRecordNoteType.JobNote,
                  updateRecordType: formText.updateRecordType === Enum.UpdateRecordOwnerType.NoUser ? Enum.UpdateRecordOwnerType.AdminUser : formText.updateRecordType
                });
                setUpdateRecordOption(UpdateRecordType.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser));
              }}
            />
            <Label check htmlFor={`automation-update-to-job-note`} className="mb-1 mr-3">
              Job
            </Label>
            {isShowApplicationAdditionalNotes() &&
              <>
                <Input
                  type="checkbox"
                  id="automation-update-to-application-note"
                  className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                  checked={formText.isUpdateRecord && formText.additionalNoteType === Enum.UpdateRecordNoteType.ApplicationNote}
                  disabled={!isDraft}
                  onChange={(e) => {
                    automationStore.setFormText({
                      ...formText,
                      isUpdateRecord: formText.isUpdateRecord,
                      isUpdateAdditionalNotes: formText.isUpdateRecord,
                      additionalNoteType: Enum.UpdateRecordNoteType.ApplicationNote,
                      updateRecordType: formText.updateRecordType === Enum.UpdateRecordOwnerType.NoUser ? Enum.UpdateRecordOwnerType.AdminUser : formText.updateRecordType
                    });
                    setUpdateRecordOption(UpdateRecordType.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser));
                  }}
                />
                <Label check htmlFor={`automation-update-to-application-note`} className="mb-1 mr-3">
                  Application
                </Label>
              </>
            }
          </FormGroup>
        </>}

      <FormGroup className="step-body-text position-relative">
        <span className="mb-3"><strong><Label className="text-white">Message</Label></strong></span>
        <RichTextEditor
          onInit={(evt, editor) => {
            editorRef.current = editor;
            
            editorRef.current.on('OpenWindow', function(e) {
              if (e.dialog) {
                setIsEditorLinkOpen(true);
              } 
            });

            editorRef.current.on('CloseWindow', function(e) {
              if (e.dialog) {
                setIsEditorLinkOpen(false);
              }
            });
          }}
          name="automation-text-form-message"
          id="automation-text-form-message"
          initialValue={formText.message}
          disabled={!isDraft}
          handleEditorChange={e => {
            // get the limit input after calculating with footer text
            let limitTextInput = Constants.TEXT_MESSAGE_MAX_CHAR - generalSettingStore.lengthFooterText;
            automationStore.setFormText({ ...formText, message: e.substring(0, limitTextInput) })
          }}
          onChange={e => {
            automationStore.setFormText({ ...formText, messageTextOnly: e.target.getContent({ format: "text" }) });
          }}
          restInit={{
            toolbar: 'link unlink',
            height: "250",
            placeholder: "Type Here...",
            link_list: function (success) {
              success();
              setTimeout(() => {
                campaignStore.getConvertLink();
                document.getElementsByClassName("tox-form__group")[1].hidden = true;
                document.getElementsByClassName("tox-form__group")[3].hidden = true;
                document.getElementsByClassName("tox-textfield")[0].addEventListener("blur", () => {
                  document.getElementsByClassName("tox-textfield")[1].value = campaignStore.showConvertLink;
                });
              }, 100);
            },
            content_style:
              "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');" +
              "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap')" +
              `html, body {
            			height: 100%;
            		}

            		html {
            			display: table;
            			margin: 0px !important;
            		}

                .mce-content-body[data-mce-placeholder] {
                  position: initial !important;
                }

                .mce-content-body[data-mce-placeholder]::before {
                  padding-left: 10px;
                }

            		body {
            			display: table-cell;
            			vertical-align: middle;
                  background-color: #ffffff;
                  width: 100vw;
                  font-size: 14px;
                  padding-left: 10px;
            		}`,
          }}
        />
        <TextCount>{countLimitText(formText.messageTextOnly)}</TextCount>
        <Row className="my-2">
          <Col sm={6} md={6}>
            <MergeTagsDropdown
              atsCategorisedFieldsForMergeTags={atsCategorisedFieldForTextMergeTags}
              onTagSelected={(tokenVariable) => handleAutomationTextTokenSelected(tokenVariable)}
              isDisabled={!isDraft}
            />
          </Col>
          <Col sm={6} md={6}>
            <SurveyDropdown
              surveyItems={surveyTags}
              onSurveySelected={(tokenVariable) => handleAutomationTextTokenSelected(tokenVariable)}
              isDisabled={!isDraft}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="step-body-text">
        <span className="mb-3"><strong><Label className="text-white">To (Country Code)</Label></strong></span>
        <NoBorderSelect
          type="select"
          name="campaignCountryCode"
          value={formText.countryCode}
          disabled={!isDraft}
          onChange={e => {
            automationStore.setFormText({
              ...formText,
              countryCode: e.target.value,
            });
          }}
        >
          {Constants.CountryCode.map((item, index) => <option key={index} value={item.value}>{item.name}</option>)}
        </NoBorderSelect>
      </FormGroup>
      <FormGroup className="step-body-text">
        <Label sm={1} md={9} className="mb-1 w-75">Text schedule </Label>
        <Switch
          id="time-variable-switch"
          className="float-right automation-sidebar-form-switch"
          uncheckedIcon={
            <div className="switch-specific-time">
              OFF
            </div>
          }
          checkedIcon={
            <div className="switch-specific-time">
              ON
            </div>
          }
          onChange={() => {
            let data = { ...formText };
            data.isSpecificTime = !formText.isSpecificTime;
            if (data.isSpecificTime) {
              data.startHour = new Date(moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }));
              data.convertedStartHour = automationStore.convertStepTimeToString(data.startHour);
            } else {
              data.startHour = null;
              data.convertedStartHour = null;
            }
            automationStore.setFormText(data);
          }}
          checked={formText.isSpecificTime}
          disabled={!isDraft}
          onColor={SwitchColor}
          onHandleColor="#EF4277"
          offHandleColor="#EF4277"
          height={23}
          width={42}
        />
        <hr className="hr-style mb-1" />
        <Label className="text-white pl-3">Set a specific time for this step to send.</Label>
        {formText.isSpecificTime && (
          <FormGroup row>
            <DateFormGroup row check>
              {renderDaysComponent(ListDaysShort)}
            </DateFormGroup>
            <FormGroup row className="ml-3 mb-1 w-100" >
              <Col md={12} lg={6} className="w-100 d-flex mt-2">
                <AutomationLabelSchedule className="text-white mt-2">
                  at
                </AutomationLabelSchedule>
                <DatePicker
                  id="text-form-input-start-hours"
                  autoComplete="off"
                  selected={new Date(moment().set(automationStore.convertStringTimeToObject(formText.convertedStartHour)))}
                  disabled={!isDraft}
                  onChange={date => {
                    let data = { ...formText };
                    data.startHour = date;
                    data.convertedStartHour = automationStore.convertStepTimeToString(date)
                    automationStore.setFormText(data);
                  }}
                  className="w-100 font-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="hh:mm aa"
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  customInput={<TimeInput isSideBarForm={true} icon={true} />}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-1 w-100" >
              <p className="small ml-4 font-italic text-white"> Your app's timezone is {generalSettingStore.timeZoneName}.</p>
            </FormGroup>
          </FormGroup>
        )}
      </FormGroup>
      {isDraft &&
        <SubmitButton
          type={RappidNodeType.Text}
          text={renderTextNodeLine1(formText.title)}
          text2={renderTextNodeLine2(formText)}
          text3={renderTextNodeLine3(formText)}
          roiData={formText}
          isDisabled={disableButton} />
      }
    </>
  );
}));

export default TextForm;

export const renderTextNodeLine1 = (title) => "Message name: " + title;

export const renderTextNodeLine2 = (formText) => {
  if (formText?.isSpecificTime)
    return getScheduleText(formText.specificDays, formText.startHour);
  else if (formText?.isUpdateRecord)
    return getRecordUpdate(formText.updateRecordType, automationStore.automationType);
  return '';
};

export const renderTextNodeLine3 = (formText) => {
  return formText?.isSpecificTime && formText?.isUpdateRecord
    ? getRecordUpdate(formText.updateRecordType, automationStore.automationType)
    : '';
};