import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { observer } from "mobx-react";
import { automationStore, emailStore, generalSettingStore, atsStore, candidateOwnerSettingStore } from "stores";
import stepAlertIcon from "assets/img/automation/step-alert.svg";
import SubmitButton from "./SubmitButton";
import { RappidNodeType, RappidText } from "helpers/Constants";
import { Enum } from "helpers";
import Select from 'react-select';
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import HeaderForm from "./HeaderForm";
import { toJS } from "mobx";
import { EditRappidNode } from "components/Rappid/RappidCanvasFunctions";
import { EmailEditorButton, SidebarFormSelect } from "components/Automation/Styled";
import Alert from 'react-s-alert';
import { AutomationType, AutomationSpecificDays, TriggerType } from "helpers/enum";
import { AutomationLabelSchedule, AutomationDateSpan, DateFormGroup } from "../Styled";
import TimeInput from "./TimeInput";
import DatePicker from 'react-datepicker';
import { SwitchColor } from "_variable";
import Switch from "react-switch";
import moment from "moment";
import { getScheduleText, getRecordUpdate } from "helpers/AutomationHelper";
import MergeTagsDropdown from "components/Automation/components/MergeTagsDropdown";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import SendTestEmailButton from "./SendTestEmailButton";

const AlertForm = observer(forwardRef((props, ref) => {
  const { formAlert, automationAlertUserIds, isCopiedTemplate, automationId, automationType, triggerType, automationState, companyDomainDefault } = automationStore;
  const { atsCategorisedFieldsForMergeTags } = atsStore;
  const { emailsList } = emailStore;
  const { candidateOwners } = candidateOwnerSettingStore;
  const alertEmailList = emailsList.filter(x => x.category && x.category.toLowerCase().includes("alert"));
  const isCandidatePlacementOrJobBasedTrigger = triggerType === TriggerType.PlacementBased || triggerType === TriggerType.JobBased;
  const subjectAlertRef = useRef();
  const editorRef = useRef(null);
  const sendTestEmailRef = useRef(null);
  const isDraft = automationState === Enum.AutomationStateType.Draft;
  const [isEditorLinkOpen, setIsEditorLinkOpen] = useState(false);

  const isDisabledButton = (automationStore.isLegacyAlert && (!formAlert.emailCampaignId || !formAlert.name)) ||
    !formAlert.subject ||
    (formAlert.userIds.length === 0 && !formAlert.isSendToOwner && !formAlert.isSendToPlacementOwner) ||
    (formAlert.isSpecificTime && (!formAlert.startHour || formAlert.specificDays.length === 0)) ||
    (!automationStore.isLegacyAlert && !formAlert.message);

  const isDisabledTest = !isDraft || !formAlert.subject || !formAlert.message;

  useEffect(() => {
    Promise.all([emailStore.getEmails(), automationStore.getUsers(), atsStore.getAtsField(), atsStore.getAtsCategorisedFields(automationType), automationStore.getCompanyDomainDefault(), candidateOwnerSettingStore.getCandidateOwners()]).then(() => {
      const cloneFormAlert = { ...formAlert };
      if (isCopiedTemplate === true && automationId !== null && typeof cloneFormAlert.isUpdatedFromCopiedTemplate === "undefined") {
        cloneFormAlert.emailTemplateId = null;
        cloneFormAlert.emailCampaignId = null;
        cloneFormAlert.name = "";
        cloneFormAlert.userIds = [];
        automationStore.setFormAlert(cloneFormAlert, true)
        automationStore.setAutomationOnClear(true)
        EditRappidNode(RappidNodeType.Alert, cloneFormAlert.name, "", "", "", cloneFormAlert, false, true);
      }
      //add new properties for handle old data alert and reset specific time #8293
      if (typeof formAlert.isSpecificTime === "undefined" || !automationStore.formAlert.isSpecificTime) {
        let formData = { ...automationStore.formAlert };
        formData.isSpecificTime = false;
        formData.specificDays = [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday];
        formData.startHour = null;
        formData.convertedStartHour = null;
        automationStore.setFormAlert(formData, true);
      }
    });

    if (!isCandidatePlacementOrJobBasedTrigger)
      automationStore.setFormAlert({ ...automationStore.formAlert, isSendToPlacementOwner: false }, true);

    return function cleanup() {
      automationStore.resetFormAlert();
    }
    // eslint-disable-next-line
  }, []);

  console.debug("Alert Form Data: ", toJS(formAlert));

  const selectedUser = automationAlertUserIds.filter((user) => {
    return formAlert.userIds ? formAlert.userIds.indexOf(user.id) !== -1 : null;
  });

  const redirectToEmailEditor = (event) => {
    if (!formAlert.emailCampaignId)
      Alert.error('Select email first to continue')
    else
      window.open(`/admin/emails/editor/${formAlert.emailCampaignId}?fromAutomation=1`, '_blank', 'noopener,noreferrer')
  };

  const renderDaysComponent = (ListDays) => {
    let data = { ...automationStore.formAlert };
    data.specificDays = [...automationStore.formAlert.specificDays];
    data.timeVariableDays = data.specificDays.toString();

    const daysComponent = ListDays.map((item, index) => {
      let days = [...automationStore.formAlert.specificDays];
      let isChecked = days.find(x => x === item.value);

      return (
        <AutomationDateSpan key={index}>
          <Input
            type="checkbox"
            id={`text-form-time-variable-${item.day}`}
            className="automation-time-variable-checkbox-days text-white"
            name={`text-form-time-variable-${item.day}`}
            disabled={!isDraft}
            checked={isChecked ? true : false}
            onChange={(e) => {
              let data = { ...formAlert };
              data.specificDays = [...formAlert.specificDays];
              data.timeVariableDays = data.specificDays.slice();
              const checked = e.target.checked;
              if (checked) {
                data.specificDays = [...data.specificDays, item.value];
                data.timeVariableDays = data.specificDays.toString();
              } else {
                const removeValue = data.specificDays.indexOf(item.value);
                data.specificDays.splice(removeValue, 1);
                data.timeVariableDays = data.specificDays.toString();
              }
              automationStore.setFormAlert(data);
            }}
          />
          <Label htmlFor={`text-form-time-variable-${item.day}`} >
            {item.day}
          </Label>
        </AutomationDateSpan>
      );
    });

    return daysComponent;
  };

  const handleAutomationSubjectAlertTokenSelected = (token) => {
    const automationTextToken = `{{${token}}}`;

    if (subjectAlertRef.current) {
      subjectAlertRef.current.value = formAlert.subject + automationTextToken;
      automationStore.setFormAlert({ ...formAlert, subject: subjectAlertRef.current.value });
      console.log('ref: ', subjectAlertRef.current);
    }
  }

  const handleAlertMessageTokenSelected = (token) => {
    const messageToken = `{{${token}}}`;

    if (editorRef.current) {
      editorRef.current.insertContent(messageToken);
      automationStore.setFormAlert({ ...formAlert, message: editorRef.current.getContent() });
    }
  }

  useImperativeHandle(ref, () => ({
    isModalOpen: () => sendTestEmailRef.current?.isModalOpen() || isEditorLinkOpen || false,
    openModal: () => {
      sendTestEmailRef.current?.openModal();
      
      if (editorRef.current) {
        editorRef.current.execCommand('mceLink');
      }
    },
    closeModal: () => {
      sendTestEmailRef.current?.closeModal();

      if (editorRef.current && isEditorLinkOpen) {
        editorRef.current.windowManager.close();
      }
    },
  }));

  return (
    <>
      <HeaderForm
        automationHeaderId="form-step-alert"
        automationHeaderImgSrc={stepAlertIcon}
        automationHeaderImgAlt="icon-alert"
        automationHeaderTextStep={RappidText.AlertStep}
      />
      <FormGroup className="step-body-text">
        <span className="mb-3">Send to one or more specific users</span>
        <Select
          isMulti
          className="automation-alert-template automation-form-dropdown-multi"
          id="automation-alert-users-ids"
          name="automation-alert-users-ids"
          classNamePrefix="automation-dropdown"
          placeholder="- Select -"
          options={automationAlertUserIds}
          isDisabled={!isDraft}
          onChange={selectedOptions => {
            automationStore.setFormAlert({ ...formAlert, userIds: selectedOptions ? selectedOptions.map(selected => selected.id) : [] })
          }}
          getOptionLabel={(option) => option.fullName}
          getOptionValue={(option) => option.id}
          value={selectedUser}
        />
      </FormGroup>
      <FormGroup className="step-body-text">
        <Input
          type="checkbox"
          id="automation-alert-default-owner"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          disabled={!isDraft}
          checked={formAlert.isSendToOwner}
          onChange={(e) => {
            automationStore.setFormAlert({ ...formAlert, isSendToOwner: !formAlert.isSendToOwner });
          }}
        />
        <Label check htmlFor={`automation-alert-default-owner`}>
          {automationType === AutomationType.Client ? 'Send alert to the Contact Owner(s)' : 'Send alert to the Recruiter(s)'}
        </Label>
      </FormGroup>
      {isCandidatePlacementOrJobBasedTrigger && <FormGroup className="step-body-text">
        <Input
          type="checkbox"
          id="automation-alert-placement-owner"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          disabled={!isDraft}
          checked={formAlert.isSendToPlacementOwner}
          onChange={(e) => {
            automationStore.setFormAlert({ ...formAlert, isSendToPlacementOwner: !formAlert.isSendToPlacementOwner });
          }}
        />
        <Label check htmlFor={`automation-alert-placement-owner`}>
          {automationType === AutomationType.Client ? 'Send alert to the Client Job Owner(s)' : 'Send alert to the Candidate Job Owner(s)'}
        </Label>
      </FormGroup>}
      <FormGroup className="step-body-text">
        {automationStore.isLegacyAlert ? 
        <>
          <Row className="ml-0 mr-0">
            <SidebarFormSelect
              className="automation-alert-template col-md-12 px-0 mb-2"
              id="automation-alert-email-template"
              name="automation-alert-email-template"
              classNamePrefix="automation-dropdown"
              placeholder="- Select email -"
              isDisabled={!isDraft}
              onChange={e => {
                const emailCampaignId = parseInt(e.id);
                const emailCampaignName = e.name;
                automationStore.setFormAlert({ ...formAlert, emailCampaignId: emailCampaignId, name: emailCampaignName });
              }}
              options={alertEmailList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={alertEmailList.find(x => x.id === formAlert.emailCampaignId) || 0}
            />
            <EmailEditorButton
              id="automation-alert-form-redirect-editor-button"
              className="col-md-6 col-lg-4 mt-3 btn-roi secondary outline-white my-3 pr-5"
              disabled={!formAlert.emailCampaignId}
              onClick={redirectToEmailEditor}>
              View Alert
            </EmailEditorButton>
            <Label className="ml-1">
              To view your Alert templates here, please ensure they are categorised as 'Alert'
            </Label>
          </Row>
        </>
        :
        <>
          <span className="mb-3"><strong><Label className="text-white">Message</Label></strong></span>
          <RichTextEditor 
            onInit={(evt, editor) => {
              editorRef.current = editor;

              editorRef.current.on('OpenWindow', function(e) {
                if (e.dialog) {
                  setIsEditorLinkOpen(true);
                } 
              });
  
              editorRef.current.on('CloseWindow', function(e) {
                if (e.dialog) {
                  setIsEditorLinkOpen(false);
                }
              });
            }}
            name="automation-alert-form-message"
            id="automation-alert-form-message"
            initialValue={formAlert.message}
            disabled={!isDraft}
            handleEditorChange={e => {
              automationStore.setFormAlert({ ...formAlert, message: e });
            }}
            restInit={{
              toolbar: 'bold italic underline alignleft alignright aligncenter alignjustify | link unlink',
              height: "250",
              placeholder: "Type Here...",
              content_style:
                "@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');" +
                "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap')" +
                `html, body {
                  height: 100%;
                }
                
                html {
                  display: table;
                  margin: 0px !important;
                }
                
                .mce-content-body[data-mce-placeholder] {
                  position: initial !important;
                }
                
                .mce-content-body[data-mce-placeholder]::before {
                  padding-left: 10px;
                }

                body {
                  display: table-cell;
                  vertical-align: middle;
                  background-color: #fff;
                  width: 100vw;
                  font-size: 14px;
                  padding-left: 10px;
                }`
            }}
          />
          <Row className="my-2">
            <Col sm={12} md={12}>
              <MergeTagsDropdown
                atsCategorisedFieldsForMergeTags={atsCategorisedFieldsForMergeTags}
                onTagSelected={(tokenVariable) => handleAlertMessageTokenSelected(tokenVariable)}
                isDisabled={!isDraft}
                isAutomationContact={automationType !== AutomationType.Candidate && triggerType === TriggerType.PlacementBased}
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col sm={12} md={12}>
              <Input
                type="checkbox"
                id="automation-alert-include-view-button"
                className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                disabled={!isDraft}
                checked={formAlert.isIncludeViewButton}
                onChange={(e) => {
                  automationStore.setFormAlert({ ...formAlert, isIncludeViewButton: !formAlert.isIncludeViewButton });
                }}
              />
              <Label check htmlFor={`automation-alert-include-view-button`}>
                Include button <strong>View {automationType === AutomationType.Client ? 'Contact' : 'Candidate'} in JobAdder</strong>
              </Label>
            </Col>
          </Row>
        </>
      }
      </FormGroup>
      <FormGroup className="step-body-text">
        <span className="mb-3"><strong><Label className="text-white">Subject</Label></strong></span>
        <Input
          ref={subjectAlertRef}
          type="textarea"
          rows='3'
          id="automation-alert-subject"
          className="automation-alert-input border-radius-normal"
          disabled={!isDraft}
          value={formAlert.subject}
          onChange={e => {
            automationStore.setFormAlert({ ...formAlert, subject: e.target.value })
          }}
        />  
        <Row className="my-2">
          <Col sm={12} md={12}>
            <MergeTagsDropdown
              atsCategorisedFieldsForMergeTags={atsCategorisedFieldsForMergeTags}
              onTagSelected={(tokenVariable) => handleAutomationSubjectAlertTokenSelected(tokenVariable)}
              isDisabled={!isDraft}
              isAutomationContact={automationType !== AutomationType.Candidate && triggerType === TriggerType.PlacementBased}
            />
          </Col>
        </Row>
      </FormGroup>
      {!automationStore.isLegacyAlert && <FormGroup className="step-body-text">
        <SendTestEmailButton
          ref={sendTestEmailRef}
          formEmail={formAlert}
          candidateOwners={candidateOwners}
          contactType={automationType}
          companyDomain={companyDomainDefault}
          isDisabled={isDisabledTest}
          isAlert={true}
        />
      </FormGroup>}
      <FormGroup className="step-body-text">
        <Label sm={1} md={9} className="mb-1 w-75">Alert Schedule </Label>
        <Switch
          id="time-variable-switch"
          className="float-right automation-sidebar-form-switch"
          uncheckedIcon={
            <div className="switch-specific-time">
              OFF
            </div>
          }
          checkedIcon={
            <div className="switch-specific-time">
              ON
            </div>
          }
          onChange={() => {
            let data = { ...formAlert };
            data.isSpecificTime = !formAlert.isSpecificTime;
            if (data.isSpecificTime) {
              data.startHour = new Date(moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }));
              data.convertedStartHour = automationStore.convertStepTimeToString(data.startHour);
            } else {
              data.startHour = null;
              data.convertedStartHour = null;
            }
            automationStore.setFormAlert(data);
          }}
          disabled={!isDraft}
          checked={formAlert.isSpecificTime}
          onColor={SwitchColor}
          onHandleColor="#EF4277"
          offHandleColor="#EF4277"
          height={23}
          width={42}
        />
        <hr className="hr-style mb-1" />
        <Label className="text-white pl-3">Set a specific time for this step to send.</Label>
        {formAlert.isSpecificTime && (
          <FormGroup row>
            <DateFormGroup row check>
              {renderDaysComponent(Enum.ListDaysShort)}
            </DateFormGroup>
            <FormGroup row className="ml-3 mb-1 w-100" >
              <Col md={12} lg={6} className="w-100 d-flex mt-2">
                <AutomationLabelSchedule className="text-white mt-2">
                  at
                </AutomationLabelSchedule>
                <DatePicker
                  id="text-form-input-start-hours"
                  autoComplete="off"
                  selected={new Date(moment().set(automationStore.convertStringTimeToObject(formAlert.convertedStartHour)))}
                  onChange={date => {
                    let data = { ...formAlert };
                    data.startHour = date;
                    data.convertedStartHour = automationStore.convertStepTimeToString(date)
                    automationStore.setFormAlert(data);
                  }}
                  className="w-100 font-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="hh:mm aa"
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  customInput={<TimeInput isSideBarForm={true} icon={true} />}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-1 w-100" >
              <p className="small ml-4 font-italic text-white"> Your app's timezone is {generalSettingStore.timeZoneName}.</p>
            </FormGroup>
          </FormGroup>
        )}
      </FormGroup>
      {isDraft &&
        <SubmitButton
          type={RappidNodeType.Alert}
          text={renderAlertNodeLine1(formAlert)}
          text2={renderAlertNodeLine2(formAlert)}
          text3={renderAlertNodeLine3(formAlert)}
          text4={renderAlertNodeLine4(formAlert)}
          roiData={formAlert}
          isDisabled={isDisabledButton} />
      }
    </>
  );
}));

export const renderAlertNodeLine1 = (formAlert) => {
  var line1 = "To: ";
  if (formAlert?.isSendToOwner)
    line1 += "recruiter";
  if (formAlert?.isSendToPlacementOwner)
    line1 += "Job owner";
  if (formAlert?.userIds && formAlert?.userIds?.length > 0) {
    if (line1 !== "To: ")
      line1 += " & ";
    line1 += formAlert?.userIds?.length + " user(s)";
  }
  return line1;
}

export const renderAlertNodeLine2 = (formAlert) => {
  return "Alert Email: " + formAlert?.name;
}

export const renderAlertNodeLine3 = (formAlert) => {
  if (formAlert?.isSpecificTime)
    return getScheduleText(formAlert?.specificDays, formAlert?.startHour);
  else if (formAlert?.isUpdateRecord)
    return getRecordUpdate(formAlert?.updateRecordType, automationStore.automationType);
  return '';
}

// not sure form alert is ever update record, think this is redundant and should be removed
export const renderAlertNodeLine4 = (formAlert) => {
  if (formAlert.isSpecificTime && formAlert?.isUpdateRecord)
    return getRecordUpdate(formAlert?.updateRecordType, automationStore.automationType);
  return '';
}

export default AlertForm;
