import { ApiService, ApiUrl, Constants, Enum } from "helpers";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';
import { FormatDateUtc } from "helpers/FormatDateTable";
import { SurveyReportsFieldName, SurveyClientResponsesDefaultHeaderCsv, SurveyReportsDefaultHeaderCsv } from "helpers/Constants";
import { SurveyReportsGridColumnName } from "views/Reports/Survey/SurveyReportsGrid/SurveyReportsGridConstant";

const surveyReportClientStore = observable(
    {
        surveyReportsClient: undefined,
        surveyReportChartConfigDataset: [],
        surveyReportDetailPercentage: [],
        chartInstance: null,
        selectedReport: undefined,
        selectedReportLastSendDate: '-',
        comingFromType: 5,
        fromClientDetail: false,
        surveyResponses: [],
        surveyCompletions: [],
        surveyOpened: [],
        responseClientName: null,
        responseCampaignName: null,
        responseSurveyName: null,
        responseCompletionDate: null,
        reponseAutomationName: null,
        filterGrid: [],
        sortGrid: [],
        agGridRowsPage: 0,
        agGridTotalRow: 0,
        agGridTotalPage: 0,
        surveyReportSelectedAutomation: 0,
        surveyReportSelectedCampaign: null,
        surveyReportDropdownAutomation: [{value: 0, label: 'ALL'}],
        surveyReportDropdownCampaign: [{value: null, label: 'ALL'}],
        surveyCount: { 
            totalUsers: 0, 
            totalOpened: 0, 
            totalCompletions: 0, 
            openedPercentage: 0,
            completionPercentage: 0
        },
        surveyName: undefined,
        surveyCompletionResponses: [],
        surveyCompletionResponsesHeader: [],
        exportDataSurveyReport: [],
        exportHeaderSurveyReport: [],

        gridColumn: [
            {
                id: 1,
                colId: SurveyReportsFieldName.SURVEY_NAME,
                headerName: SurveyReportsGridColumnName.SurveyName,
                field: SurveyReportsFieldName.SURVEY_NAME,
                minWidth: 220,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 2,
                colId: SurveyReportsFieldName.CAMPAIGN_NAME,
                headerName: SurveyReportsGridColumnName.EmailOrTextName,
                field: SurveyReportsFieldName.CAMPAIGN_NAME,
                minWidth: 220,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 3,
                colId: SurveyReportsFieldName.AUTOMATION_NAME,
                headerName: SurveyReportsGridColumnName.AutomationName,
                field: SurveyReportsFieldName.AUTOMATION_NAME,
                minWidth: 220,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 4,
                colId: SurveyReportsFieldName.LAST_SEND_DATE,
                headerName: SurveyReportsGridColumnName.LastSendDate,
                field: SurveyReportsFieldName.LAST_SEND_DATE,
                minWidth: 180,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 5,
                colId: SurveyReportsFieldName.TOTAL_USERS,
                headerName: SurveyReportsGridColumnName.TotalUsers,
                field: SurveyReportsFieldName.TOTAL_USERS,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 6,
                colId: SurveyReportsFieldName.TOTAL_OPENED,
                headerName: SurveyReportsGridColumnName.Opened,
                field: SurveyReportsFieldName.TOTAL_OPENED,
                minWidth: 50,
                filter: true,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 7,
                colId: SurveyReportsFieldName.TOTAL_COMPLETIONS,
                headerName: SurveyReportsGridColumnName.Completions,
                field: SurveyReportsFieldName.TOTAL_COMPLETIONS,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
        ],

        setExportDataSurveyReport(exportDataSurveyReport){
            this.exportDataSurveyReport = [...exportDataSurveyReport];
        },
        
        setExportHeaderSurveyReport(exportHeaderSurveyReport){
            this.exportHeaderSurveyReport = [...exportHeaderSurveyReport];
        },

        setSurveyCompletionResponses(surveyCompletionResponses){
            this.surveyCompletionResponses = [...surveyCompletionResponses];
        },
        
        setSurveyCompletionResponsesHeader(surveyCompletionResponsesHeader){
            this.surveyCompletionResponsesHeader = [...surveyCompletionResponsesHeader];
        },

        setSurveyName(name) {
            this.surveyName = name
        },

        setSurveyReportSelectedAutomation(surveyReportSelectedAutomation){
            this.surveyReportSelectedAutomation = surveyReportSelectedAutomation;
        },
       
        setSurveyReportSelectedCampaign(surveyReportSelectedCampaign){
            this.surveyReportSelectedCampaign = surveyReportSelectedCampaign;
        },

        setFilterGrid(filterGrid) {
          this.filterGrid = filterGrid;
        },

        setSortGrid(sortGrid) {
          this.sortGrid = sortGrid;
        },

        setAgGridRowsPage(agGridRowsPage) {
          this.agGridRowsPage = agGridRowsPage;
        },

        setAgGridTotalRow(agGridTotalRow) {
          this.agGridTotalRow = agGridTotalRow;
        },

        setAgGridTotalPage(agGridTotalPage) {
          this.agGridTotalPage = agGridTotalPage;
        },

        updateGridColumn(gridColumnData) {
          this.gridColumn = gridColumnData;
        },

        setResponseClientName(responseClientName){
            this.responseClientName = responseClientName;
        },

        setSurveyResponses(surveyResponses){
            this.surveyResponses = [...surveyResponses];
        },

        setResponseCampaignName(responseCampaignName){
            this.responseCampaignName = responseCampaignName;
        },

        setResponseSurveyName(responseSurveyName){
            this.responseSurveyName = responseSurveyName;
        },

        setResponseAutomationName(reponseAutomationName){
            this.reponseAutomationName = reponseAutomationName;
        },

        setCompletionDate(responseCompletionDate){
            this.responseCompletionDate = responseCompletionDate;
        },

        setSurveyCompletions(surveyCompletions) {
            this.surveyCompletions = [...surveyCompletions];
        },

        setSurveyOpened(surveyOpened) {
            this.surveyOpened = [...surveyOpened];
        },

        setSurveyReportsClient(reports) {
            this.surveyReportsClient = reports
        },

        setSurveyReportChartConfigDataset(dataset) {
            this.surveyReportChartConfigDataset = [];
            this.surveyReportChartConfigDataset = dataset;
        },

        setSurveyReportDetailPercentage(surveyReportDetailPercentage) {
            this.surveyReportDetailPercentage = [];
            this.surveyReportDetailPercentage = [...surveyReportDetailPercentage];
        },

        setChartInstance(chartInstance) {
            this.chartInstance = chartInstance;
        },

        setSelectedReport(report) {
            this.selectedReport = report;
        },

        setSelectedReportLastSendDate(date) {
            this.selectedReportLastSendDate = FormatDateUtc(date, false);
        },

        setComingFromType(type) {
            this.comingFromType = type;
        },

        setFromClientDetail(fromClientDetail) {
            this.fromClientDetail = fromClientDetail;
        }, 

        setSurveyReportDropdownAutomation(surveyReportDropdownAutomation) {
            const automationOptions = surveyReportDropdownAutomation.map((x) => ({ value: x.automationId, label: x.automationName }));
            this.surveyReportDropdownAutomation = [this.surveyReportDropdownAutomation[0], ...automationOptions];
        },

        setSurveyCount(surveyCount) {
            this.surveyCount = {...surveyCount};
        },

        setSurveyReportDropdownCampaign(campaignReports) {
            this.setSurveyReportSelectedCampaign(null); //reset selected campaign 
            let campaignDropdown = campaignReports.length > 0 ? campaignReports.map((x) => ({ value: x.campaignReportToken, label: x.campaignName })) : [];
            this.surveyReportDropdownCampaign = [this.surveyReportDropdownCampaign[0], ...campaignDropdown];
        },

        resetSurveyReportStore(){
            this.surveyReportsClient= undefined;
            this.surveyReportDetailPercentage= [];
            this.surveyResponses = [];
            this.surveyCompletions = [];
            this.surveyOpened = [];
            this.responseClientName = null;
            this.responseCampaignName = null;
            this.responseSurveyName = null;
            this.responseCompletionDate = null;
            this.reponseAutomationName = null;
            this.comingFromType = Enum.SurveyReportType.Opened.typeId;
            this.surveyReportSelectedAutomation = 0;
            this.surveyReportSelectedCampaign = null;
            this.surveyReportDropdownAutomation = [{value: 0, label: 'ALL'}];
            this.surveyReportDropdownCampaign = [{value: null, label: 'ALL'}];
            this.surveyCount = { 
                totalUsers: 0, 
                totalOpened: 0, 
                totalCompletions: 0, 
                openedPercentage: 0,
                completionPercentage: 0
            };
            this.surveyName = undefined;
            this.surveyCompletionResponses = [];
            this.surveyCompletionResponsesHeader = [];
            this.exportDataSurveyReport = [];
            this.exportHeaderSurveyReport = [];
        },

        getSurveyReportDetail(surveyId, surveyReportToken){
            return (ApiService.get(ApiUrl.getSurveyReportsClient + `/${surveyId}/${surveyReportToken}`).then(
                action(
                    response => {
                        if (response
                          && response.status === 200
                          && response.data
                          && !response.data.isError
                          && response.data.result) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setSelectedReport(response.data.result);
                            this.setSurveyReportDetailPercentage([
                              response.data.result.totalOpenedPercentage.toFixed(2),
                              response.data.result.totalCompletionsPercentage.toFixed(2),
                            ]);
                            this.setSelectedReportLastSendDate(response.data.result?.reports.lastSendDate)

                            return Promise.resolve(response.data);
                        }

                        return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        // getSurveyReportCompletion(surveyId, surveyReportToken){
        //     return (ApiService.get(ApiUrl.getSurveyCompletionReportClient+ `/${surveyId}/${surveyReportToken}`).then(
        //         action(
        //             response => {
        //                 if (response && response.status === 200 && response.data && !response.data.isError) {
        //                     // TODO HERE if something necessary, for now just return data exactly from API
        //                     this.setSurveyCompletions(response.data.result);
        //                 } else {
        //                     Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
        //                 }
        //                 return Promise.resolve(response.data);
        //             }
        //         )
        //     ).catch(
        //         action(
        //             err => {
        //                 Alert.error(err);
        //                 return Promise.reject(err);
        //             }
        //         )
        //     ));
        // },

        // getSurveyReportOpened(surveyId, surveyReportToken){
        //     return (ApiService.get(ApiUrl.getSurveyOpenReportClient + `/${surveyId}/${surveyReportToken}`).then(
        //         action(
        //             response => {
        //                 if (response && response.status === 200 && response.data && !response.data.isError) {
        //                     // TODO HERE if something necessary, for now just return data exactly from API
        //                     this.setSurveyOpened(response.data.result)
        //                 } else {
        //                     Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
        //                 }
        //                 return Promise.resolve(response.data);
        //             }
        //         )
        //     ).catch(
        //         action(
        //             err => {
        //                 Alert.error(err);
        //                 return Promise.reject(err);
        //             }
        //         )
        //     ));
        // },

        getSurveyResponses(clientCompletionId, token, surveyId, clientId){
            return (ApiService.get(ApiUrl.getSurveyClientCompletion + `${clientCompletionId}/${surveyId}/${token}/${clientId}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            const data = response.data.result;
                            this.setSurveyResponses(data.surveyResponses);
                            this.setResponseClientName(data.clientName);
                            this.setResponseCampaignName(data.campaignName);
                            this.setResponseSurveyName(data.surveyName);
                            this.setResponseAutomationName(data.automationName);
                            this.setCompletionDate(data.completedAt);
                            return Promise.resolve(response.data);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));

        },

        getSurveyReportServerSide(request) {
          const body = {
            param: JSON.stringify(request)
          };

          return (ApiService.post(`${ApiUrl.getSurveyReportsClient}`, body).then(
            action(
              response => {
                if (response && response.status === 200 && response.data && !response.data.isError) {
                  // TODO HERE if something necessary, for now just return data exactly from API
                  this.setAgGridTotalRow(response.data.result.totalCount);
                  return Promise.resolve(response.data);
                } else {
                  Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                  return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                }
              }
            )
          ).catch(
            action(
              err => {
                return Promise.reject(err);
              }
            )
          ))
        },

        getSurveyGroupReportServerSide(request) {
            const body = {
                param: JSON.stringify(request)
            };

            return (ApiService.post(`${ApiUrl.getSurveyGroupReportsClient}`, body).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setAgGridTotalRow(response.data.result.totalCount);
                            return Promise.resolve(response.data);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);

                            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ))
        },

        getSurveyReportDetailById(surveyId, automationId = null, campaignReportToken = null, isCampaignChange = false){
            const params = `/${surveyId}${automationId ? '/'+automationId : ''}${campaignReportToken ? '/'+campaignReportToken : ''}`;

            return (ApiService.get(ApiUrl.getSurveyGroupReportsClient + params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            if (response.data.result) {
                                const { totalUsers, totalOpened, totalCompletions, totalOpenedPercentage, totalCompletionsPercentage, automations, reports, lastSendDate, surveyName } = response.data.result;
                                
                                if(!isCampaignChange){
                                    this.setSurveyReportDropdownCampaign(reports);
                                }
                                this.setSurveyReportDropdownAutomation(automations);
                                this.setSurveyReportDetailPercentage([totalOpenedPercentage.toFixed(2), totalCompletionsPercentage.toFixed(2)]);
                                this.setSurveyName(surveyName);
                                this.setSelectedReportLastSendDate(lastSendDate);
                                this.setSurveyCount({
                                    totalUsers: totalUsers, 
                                    totalOpened: totalOpened, 
                                    totalCompletions: totalCompletions,
                                    openedPercentage: totalOpenedPercentage,
                                    completionPercentage: totalCompletionsPercentage
                                });
                            } else {
                                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                            }
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyReportTotalOpened(surveyId, automationId, campaignReportToken){
            const campaignTokenSelected = campaignReportToken ? "/" + campaignReportToken : "";
            const automationIdSelected = automationId || 0;

            return (ApiService.get(ApiUrl.getSurveyClientReportTotalOpened+ `/${surveyId}/${automationIdSelected}${campaignTokenSelected}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setSurveyOpened(response.data.result)
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyReportTotalCompletion(surveyId, automationId, campaignReportToken){
            const campaignTokenSelected = campaignReportToken ? "/" + campaignReportToken : "";
            const automationIdSelected = automationId || 0;

            return (ApiService.get(ApiUrl.getSurveyClientReportTotalCompletion+ `/${surveyId}/${automationIdSelected}${campaignTokenSelected}`).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setSurveyCompletions(response.data.result);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getSurveyCompletionResponses(surveyId){
            return (ApiService.get(ApiUrl.getSurveyClientCompletionResponses + surveyId).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            const data = response.data.result;
                            let csvData = [];
                            let csvHeader = [...SurveyClientResponsesDefaultHeaderCsv];
                            data.forEach(item => {
                                const {clientId, clientName = "", email = "", mobile = "", completionDate = "", responses = []} = item;
                                let clientDetails = { clientId, clientName, email, mobile, completionDate };

                                //for add question in csv header
                                if(csvHeader.length <= 5){
                                    for(var i = 0; i < responses.length; i++){
                                        const header = {
                                            label: responses[i].surveyQuestion,
                                            key: `answer-${i}`
                                        }
                                        csvHeader.push(header);
                                    }
                                }
                                //for add answer in csv data
                                for(let i = 0; i < responses.length; i++) {
                                    const answer = responses[i].surveyAnswer || "";
                                    const propertyName = `answer-${i}`;
                                    clientDetails[propertyName] = answer;
                                }
                                clientDetails.completionDate = FormatDateUtc(completionDate);
                                csvData.push(clientDetails);
                            });
                            this.setSurveyCompletionResponses(csvData);
                            this.setSurveyCompletionResponsesHeader(csvHeader);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));

        },

        modifySurveyReportsExportToCsv(defaultHeader, data){
            let csvData = [];
            let csvHeader = [...defaultHeader];
            data.forEach(item => {
                const {totalCompletionsPercentage = 0, totalOpenedPercentage = 0, totalUsers = 0, reports = undefined} = item;
                let surveyReports = {};
                if(reports){
                    surveyReports["surveyName"] = reports.surveyName;
                    surveyReports["lastSendDate"] = FormatDateUtc(reports.lastSendDate);
                    surveyReports["totalUsers"] = reports.totalUsers;
                    surveyReports["totalOpened"] = reports.totalOpened;
                    surveyReports["totalCompletions"] = reports.totalCompletions;
                    surveyReports["totalCompletionsPercentage"] = totalCompletionsPercentage.toFixed(2)
                    surveyReports["totalOpenedPercentage"] = totalOpenedPercentage.toFixed(2)
                    surveyReports["totalUsers"] = totalUsers
                }
                csvData.push(surveyReports);
            });
            this.setExportDataSurveyReport(csvData);
            this.setExportHeaderSurveyReport(csvHeader);
        },

        getSurveyReportsClientToCsv(){
            return (ApiService.get(ApiUrl.getSurveyReportClientCsv).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.modifySurveyReportsExportToCsv(SurveyReportsDefaultHeaderCsv, response.data.result);
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },
    }
);

export default surveyReportClientStore;
