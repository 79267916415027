import { observer } from "mobx-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { FirstRow } from "../FilterPopUp/Styled";
import { PinkButton } from "atoms/ROIButtons";
import CreatableSelect from "react-select/creatable";
import { isValidEmail } from "helpers/Validator";
import { emailStore } from "stores";

/* Send Test Email Button handles test email */
const SendTestEmailButton = observer(forwardRef(({
  formEmail,
  candidateOwners,
  contactType,
  companyDomain,
  isDisabled = false,
  isAlert = false,
}, ref) => {
  formEmail = formEmail || {};
  candidateOwners = candidateOwners || [];

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [options, setOptions] = useState([]);
  
  const owners = candidateOwners.map(item => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item.email
  }));

  const formatLabel = (option) => `Add ${option}`;

  const handleChanges = (option) => {
    setOptions(option.map(x => x.value));
  }

  const handleSendTest = () => {
    const testData = {
      campaignId: formEmail.emailCampaignId ?? 0,
      senderName: formEmail.senderName,
      senderEmail: formEmail.senderEmail,
      senderDomain: companyDomain?.senderDomain ?? formEmail.senderDomain,
      companyDomainId: companyDomain?.companyDomainId ?? formEmail.companyDomainId,
      subject: formEmail.subject,
      recipientEmails: options,
      contactType: contactType,
      isFromOwner: formEmail.isFromOwner,
      ownerType: formEmail.ownerType ?? 0,
      ...(isAlert && {
        isAlert: isAlert,
        isIncludeViewButton: formEmail.isIncludeViewButton,
        campaignMessage: formEmail.message
      })
    };

    isAlert ? emailStore.newSendTestAlert(testData) : emailStore.newSendTestEmail(testData);
    closeModal();
  }
  
  const closeModal = () => {
    setOptions([]);
    setIsOpenModal(false);
  }

  useImperativeHandle(ref, () => ({
    isModalOpen: () => isOpenModal,
    openModal: () => setIsOpenModal(true),
    closeModal: () => setIsOpenModal(false)
  }));
  
  return (
    <>
      <Button
        id="btn-send-test-email"
        name="btn-send-test-email"
        className="col-md-12 col-lg-12 btn-roi secondary outline-white"
        disabled={isDisabled}
        onClick={() => setIsOpenModal(true)}
      >
        Send test email
      </Button>

      <Modal isOpen={isOpenModal} returnFocusAfterClose={true} zIndex={1020} centered>
        <ModalBody style={{ background: '#2b2b2b' }}>
          <FirstRow>
            <div className="div">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <label>Email test recipient/s</label>
              </div>
              <CreatableSelect
                id="select-owner-send-test-email"
                name="select-owner-send-test-email"
                className="automation-trigger-template"
                placeholder="Select one or more users or manually enter email addresses"
                isMulti
                onChange={handleChanges}
                formatCreateLabel={formatLabel}
                isValidNewOption={(newOption) => isValidEmail(newOption)}                
                options={owners}
              />
            </div>
          </FirstRow>
          <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <PinkButton
              onClick={handleSendTest}
              disabled={options.length === 0}
            >
              Send
            </PinkButton>
            <Button onClick={closeModal}>Cancel</Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}));

export default SendTestEmailButton;