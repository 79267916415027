export const SurveyGridColumnName = {
  SurveyName: "Survey name",  
  SurveyDescription: "Description",
  SurveyCategory: "Category",
  SurveyCreatedDate: "Date created",
  SurveyStatus: "Status",
  SurveyAction: "",
};

export const SurveyGridFieldName = {
  SurveyName: "name",
  SurveyDescription: "description",
  SurveyCategory: "category",
  SurveyCreatedDate: "createdAt",
  SurveyStatus: "state",
  SurveyAction: "action",
};

export const SurveyExistingMessage = {
  DeleteMessage : "Delete the selected survey record?",
  DeleteSecondMessage : "All of your survey and data will be deleted!",
  DeleteThirdMessage : "Please note this survey could be in a live campaign. If you delete the survey here please be sure to re-link it in your campaign.",
  DeleteTitle : "Delete Surveys",
};