import { observable } from "mobx";

const commonStore = observable(
  {
    isLoading: false,
    isSidebarMinimize: false,

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setIsSidebarMinimize(isSidebarMinimize) {
      this.isSidebarMinimize = isSidebarMinimize;
    }
  }
);

export default commonStore;
