export const AutomationTemplateGridColumnName = {
    AutomationName: "Template name",
    AutomationDescription: "Description",
    AutomationCategory: "Category",
    AutomationCreatedDate: "Date created",
    AutomationUpdatedDate: "Updated date",
    AutomationUpdatedBy: "Updated by",
    AutomationAction: "",
    AutomationVisibleToRecruiter: "Recruiter visibility"
};

export const AutomationTemplateGridFieldName = {
    AutomationName: "name",
    AutomationDescription: "description",
    AutomationCategory: "category",
    AutomationCreatedDate: "createdAt",
    AutomationUpdatedDate: "updatedAt",
    AutomationUpdatedBy: "updatedBy",
    AutomationAction: "",
    AutomationVisibleToRecruiter: "Recruiter Visibility"
};

export const AutomationTemplateMessage = {
    DeleteMessage: "Delete the selected automation template record?",
    DeleteTitle: "Delete Automation Template",
};
