import { observable, action } from "mobx";
import { ApiUrl, ApiService, Constants } from "helpers";
import { ContactType, AudienceType } from 'helpers/enum';

var audienceStore = observable({
  audienceId: 0,
  formAudience: {
    audienceName: '',
    audienceType: AudienceType.Manual,
    audienceContact: ContactType.Client,
    totalMembers: 0,
    contacted: null,
    description: '',
    isLive: false,
    campaignName: 'CampaignName',
    lastAddedDate: null,
    lastActivityDate: null,
    lastActivity: null,
    audienceLastActivityType: null,
    createdAt: null,
    createdBy: null,
    audienceSource: null,
    audienceSourceString: '',
  },
  isFormDirty: false,
  activeTab: "",

  listContact: [],
  listSelectedMember: [],
  listAudienceMember: [],


  setActiveTab(activeTab) {
    this.activeTab = activeTab;
  },

  setAudienceId(audienceId) {
    this.audienceId = audienceId;
  },

  setFormAudience(data) {
    this.formAudience = { ...this.formAudience, ...data }
  },

  setListContact(listContact) {
    this.listContact = []
    this.listContact = [...listContact]
  },

  setListSelectedMember(listSelectedMember) {
    this.listSelectedMember = []
    this.listSelectedMember = [...listSelectedMember]
  },

  setListAudienceMember(listAudienceMember) {
    this.listAudienceMember = []
    this.listAudienceMember = [...listAudienceMember]
  },

  setIsFormDirty(isFormDirty) {
    this.isFormDirty = isFormDirty;
  },

  getAudienceById(id) {
    return (ApiService.getDetail(ApiUrl.getAudienceClientDetails, id).then(
      action(
        response => {
          if (response && response.data && !response.data.isError) {
            this.setFormAudience(response.data.result);
            return response.data;
          } else {
            return Promise.reject(response.data.message);
          }
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },

  
  onFetchDataAudienceMembers(id) {
    if (id === 0) {
      return Promise.reject("id cannot be null");
    }
    
    return (
      ApiService.get(ApiUrl.getAudienceClientMembers + id, "", 'Failed to get Candidates data')
      .then(
        action(
          response => {
            if (response && response.data && !response.data.isError) {
              this.setListAudienceMember(response.data.result.map(x => { return { ...x, 'candidateId': x.contactId } }));
              return response.data;
            } else {
              return Promise.reject(response.data.message);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      )
    );
  },

  unsubscribeAudienceClientMembers(ids) {
    
    if (ids.length === 0) {
      return Promise.resolve()
    }
    const params = {
      ids: ids,
    }
    return (
      ApiService.edit(ApiUrl.unsubscribeClientFromAudience, params, Constants.ALERT_UNSUBSCRIBE_SUCCESS, '')
        .then(
          action(
            response => {
              return Promise.resolve(response)
            }
          )
        ).catch(
          action(
            err => {
              return Promise.reject(err);
            }
          )
        )
    );
  },

  reset() {
    this.audienceId = 0;
    this.formAudience = {
      audienceName: '',
      audienceType: AudienceType.Manual,
      audienceContact: ContactType.Candidate,
      totalMembers: 0,
      contacted: null,
      description: '',
      isLive: false,
      campaignName: 'CampaignName',
      lastAddedDate: null,
      lastActivityDate: null,
      createdAt: null,
      createdBy: null,
      audienceSource: null,
      audienceSourceString: '',
    };
    this.listSelectedMember = [];
    this.listAudienceMember = [];
    this.isFormDirty = false;
    this.activeTab = "";
  }
});

export default audienceStore;
