import { observable, action } from "mobx";
import { ApiService, ApiUrl } from "helpers";
import Alert from 'react-s-alert';
import _ from "lodash";
import { atsStore, lookupStore, userStore } from "stores";
import { CandidateColumnName } from "helpers/Constants";
import { CandidateColumnName as EnumCandidateColumnName } from "helpers/enum";

const candidateStore = observable({
  candidateId: null,
  candidateName: "",
  candidateFullName: "",
  jobTitle: "",
  currentEmployer: "",
  location: "",
  resume: "",
  phoneNumber: "",
  emailAddress: "",
  recruiterRating: 0,
  lastContacted: "",
  recentActivity: "",
  roiAiScore: 0,

  candidateData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    mobile: "",
    address: {},
    otherEmail: [],
    social: {},
    employment: {},
    education: [],
    skillTags: [],
    links: {},
    skills: [],
    attachmentsPreview: "",
    attachments: {},
    photoPreview: "",
    photo: {},
    roiAiScore: 0,
    source: "",
    summary: "",
    rating: "",
    scale: null,
    isSubscribe: undefined,
    isValidEmail: undefined
  },
  updatedCandidateData: {},
  activityLogData: [],
  isFormDirty: false,
  filterGrid: [],
  sortGrid: [],
  countRowCandidates: 0,
  agGridRowsPage: 0,
  agGridTotalRow: 0,
  agGridTotalPage: 0,

  gridColumn: [
    {
      id: 1,
      colId: CandidateColumnName.CANDIDATE_NAME,
      headerName: EnumCandidateColumnName.CandidateName,
      field: CandidateColumnName.CANDIDATE_NAME,
      minWidth: 150,
      hide: false,
      order: 0,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 2,
      colId: CandidateColumnName.JOB_TITLE,
      headerName: EnumCandidateColumnName.CurrentJobTitle,
      field: CandidateColumnName.JOB_TITLE,
      minWidth: 150,
      hide: false,
      order: 1,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 3,
      colId: CandidateColumnName.CURRENT_EMPLOYER,
      headerName: EnumCandidateColumnName.CurrentEmployer,
      field: CandidateColumnName.CURRENT_EMPLOYER,
      minWidth: 150,
      hide: false,
      order: 2,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 4,
      colId: CandidateColumnName.LOCATION,
      headerName: EnumCandidateColumnName.Location,
      field: CandidateColumnName.LOCATION,
      minWidth: 150,
      hide: false,
      order: 3,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 5,
      colId: CandidateColumnName.RESUME,
      headerName: EnumCandidateColumnName.Resume,
      field: CandidateColumnName.RESUME,
      minWidth: 220,
      hide: true,
      order: 4,
      hideColConfig: true,
      checkedColConfig: true,
    },
    {
      id: 6,
      colId: CandidateColumnName.PHONE_NUMBER,
      headerName: EnumCandidateColumnName.PhoneNumber,
      field: CandidateColumnName.PHONE_NUMBER,
      minWidth: 130,
      hide: true,
      order: 5,
      hideColConfig: true,
      checkedColConfig: true,
    },
    {
      id: 7,
      colId: CandidateColumnName.MOBILE_NUMBER,
      headerName: EnumCandidateColumnName.MobileNumber,
      field: CandidateColumnName.MOBILE_NUMBER,
      minWidth: 130,
      hide: false,
      order: 6,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 8,
      colId: CandidateColumnName.EMAIL_ADDRESS,
      headerName: EnumCandidateColumnName.EmailAddress,
      field: CandidateColumnName.EMAIL_ADDRESS,
      minWidth: 150,
      hide: false,
      order: 7,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 9,
      colId: CandidateColumnName.LAST_CONTACTED,
      headerName: EnumCandidateColumnName.LastContacted,
      field: CandidateColumnName.LAST_CONTACTED,
      minWidth: 130,
      hide: true,
      order: 8,
      hideColConfig: true,
      checkedColConfig: true,
    },
    {
      id: 10,
      colId: CandidateColumnName.RECENT_ACTIVITY,
      headerName: EnumCandidateColumnName.RecentActivity,
      field: CandidateColumnName.RECENT_ACTIVITY,
      minWidth: 130,
      hide: true,
      order: 9,
      hideColConfig: true,
      checkedColConfig: true,
    },
    {
      id: 10,
      colId: CandidateColumnName.ROI_AI_SCORE,
      headerName: EnumCandidateColumnName.RoiAiScore,
      field: CandidateColumnName.ROI_AI_SCORE,
      minWidth: 100,
      hide: false,
      order: 10,
      hideColConfig: false,
      checkedColConfig: true,
    },
    {
      id: 10,
      colId: CandidateColumnName.RECRUITER_RATING,
      headerName: EnumCandidateColumnName.RecruiterRating,
      field: CandidateColumnName.RECRUITER_RATING,
      minWidth: 130,
      hide: true,
      order: 11,
      hideColConfig: true,
      checkedColConfig: true,
    }
  ],

  updateGridColumn(gridColumnData) {
    this.gridColumn = gridColumnData;
  },

  setCandidateId(candidateId) {
    this.candidateId = candidateId;
  },
  setFirstOrder(data) {
    this.firstOrder = data;
  },
  setColumnOrder(data) {
    this.columnOrder = data;
  },
  setColumnOrderTemp(data) {
    this.columnOrderTemp = data;
  },
  setOrderDisable(data) {
    this.columnOrderDisable = data;
  },
  setOrderDisableTemp(data) {
    this.columnOrderDisableTemp = data;
  },
  addItemColumnOrderDisableTemp(data) {
    this.columnOrderDisableTemp.push(data);
  },
  removeItemColumnOrderDisableTemp(data) {
    var index = this.columnOrderDisableTemp.indexOf(data);
    if (index > -1) {
      this.columnOrderDisableTemp.splice(index, 1);
    }
  },
  setCandidateName(candidateName) {
    this.candidateName = candidateName;
  },
  setJobTitle(jobTitle) {
    this.jobTitle = jobTitle;
  },
  setCurrentEmployer(currentEmployer) {
    this.currentEmployer = currentEmployer;
  },
  setLocation(location) {
    this.location = location;
  },
  setResume(resume) {
    this.resume = resume;
  },
  setLastContacted(lastContacted) {
    this.lastContacted = lastContacted;
  },
  setRecentActivity(recentActivity) {
    this.recentActivity = recentActivity;
  },
  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  },
  setEmailAddress(emailAddress) {
    this.emailAddress = emailAddress;
  },
  setRoiAiScore(roiAiScore) {
    this.roiAiScore = roiAiScore;
  },
  setRecruiterRating(recruiterRating) {
    this.recruiterRating = recruiterRating;
  },
  setShowColumn(data) {
    this.showColumn = { ...this.showColumn, ...data };
  },
  setShowColumnConfigure(data) {
    this.showColumnConfigure = {
      ...this.showColumnConfigure,
      ...data
    };
  },
  setShowColumnConfigureDefault(data) {
    this.showColumnConfigure = data
  },
  setCountRowCandidates(countRowCandidates) {
    this.countRowCandidates = countRowCandidates;
  },
  setFilterGrid(filterGrid) {
    this.filterGrid = filterGrid;
  },
  setSortGrid(sortGrid) {
    this.sortGrid = sortGrid;
  },
  setAgGridRowsPage(agGridRowsPage) {
    this.agGridRowsPage = agGridRowsPage;
  },
  setAgGridTotalRow(agGridTotalRow) {
    this.agGridTotalRow = agGridTotalRow;
  },
  setAgGridTotalPage(agGridTotalPage) {
    this.agGridTotalPage = agGridTotalPage;
  },

  setCandidateDataValue(key, value) {
    _.set(this.candidateData, key, value);
    _.set(this.updatedCandidateData, key, value);
    this.candidateFullName = this.candidateData?.firstName + " " + this.candidateData?.lastName;
    this.isFormDirty = true;
  },

  resetCandidateData() {
    this.candidateId = null;
    this.candidateFullName = null;
    this.candidateData = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      mobile: "",
      address: {},
      otherEmail: [],
      social: {},
      employment: {},
      education: [],
      skillTags: [],
      links: {},
      skills: [],
      attachmentsPreview: "",
      attachments: {},
      photoPreview: "",
      photo: {},
      roiAiScore: 0,
      source: "",
      summary: "",
      rating: "",
      scale: null,
      isSubscribe: undefined,
      isValidEmail: undefined
    };
    this.updatedCandidateData = {};
    this.isFormDirty = false;
  },

  initializeCandidateData(data, isFormUpdate = false) {
    this.candidateId = data.candidateId;
    this.candidateFullName = data.firstName + ' ' + data.lastName;
    this.candidateData = {
      ...this.candidateData,
      candidateId: data.candidateId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      mobile: data.mobile,
      address: data.address,
      otherEmail: data.otherEmail,
      social: data.social,
      employment: data.employment,
      education: data.education,
      skillTags: data.skillTags,
      links: data.links,
      skills: data.skills,
      roiAiScore: data.roiAiScore,
      source: data?.source,
      summary: data?.summary,
      rating: data?.rating,
      scale: data?.scale,
      isSubscribe: data?.isSubscribe,
      isValidEmail: data?.isValidEmail
    };

    if (isFormUpdate) {
      // isFormUpdate, reset updatedCandidateData, set attachments and photo accordingly (if user update, then re-set photo/attachment)
      this.updatedCandidateData = {};
      // let attachmentsPreview = "", attachments = {}, photoPreview = "", photo = {}; // related PBI 6317 Task 6321
      let attachmentsPreview = "", attachments = {};
      if (_.isObject(data.attachments) && !_.isEmpty(data.attachments)) {
        attachmentsPreview = `data:${data.attachments?.contentType};base64, ${data.attachments?.fileContent}`;
        attachments = data.attachments;

        this.candidateData = {
          ...this.candidateData,
          attachmentsPreview,
          attachments
        };
      }
      // related PBI 6317 Task 6321
      // if (_.isObject(data.photo) && !_.isEmpty(data.photo.contentType)) {
      //   photoPreview = `data:${data.photo?.contentType};base64, ${data.photo?.fileContent}`;
      //   photo = data.photo;

      //   this.candidateData = {
      //     ...this.candidateData,
      //     photoPreview,
      //     photo
      //   };
      // }
    } else {
      this.candidateData = {
        ...this.candidateData,
        attachmentsPreview: _.isObject(data.attachments) && !_.isEmpty(data.attachments) && data?.attachments?.attachmentId !== 0 ? `data:${data.attachments?.contentType};base64, ${data.attachments?.fileContent}` : "",
        attachments: data.attachments,
        // related PBI 6317 Task 6321
        // photoPreview: _.isObject(data.photo) && !_.isEmpty(data.photo.contentType) ? `data:${data.photo?.contentType};base64, ${data.photo?.fileContent}` : "",
        // photo: data.photo
      };
    }

    this.isFormDirty = false;
  },

  setActivityLogData(data) {
    this.activityLogData = data;
  },

  getCandidates() {
    return (ApiService.get(`${ApiUrl.getAllCandidates}`).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  getCandidatesServerSide(request) {
    const body = {
      param: JSON.stringify(request)
    };

    return (ApiService.add(`${ApiUrl.getAllCandidates}`, body).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.setCountRowCandidates(response.data.result.totalCount);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  getCandidateDetail() {
    const getCandidateDetail = (ApiService.getDetail(ApiUrl.getCandidateAtsDetails, this.candidateId).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.initializeCandidateData(response.data.result);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
    const getAtsSetting = atsStore.getAtsField();
    const getCandidateWorkType = lookupStore.getCurrentWorkTypes();
    //const getCandidateSources = lookupStore.getSources();
    const getCandidateRatings = lookupStore.getRatings();

    return (Promise.all([getCandidateDetail, getAtsSetting, getCandidateWorkType, getCandidateRatings]).then(
      action(
        response => {
          return Promise.resolve(response[0]);  // only return getCandidateDetail to maintain process in UI
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },


  getCandidateDetailByToken(token, type) {
    return (ApiService.getDetailWithParams(ApiUrl.getCandidateAtsDetailsByToken, token, type).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.initializeCandidateData(response.data.result);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },

  getCandidateActivityLog() {
    return (ApiService.getDetail(ApiUrl.getContactActivity, this.candidateId).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.setActivityLogData(response.data.result);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ));
  },

  updateCandidateDetail() {
    const candidate = {
      candidate: {
        ...this.updatedCandidateData,
        candidateId: this.candidateId
      }
    };

    return (ApiService.edit(ApiUrl.updateCandidateAts + this.candidateId, candidate).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.initializeCandidateData(response.data.result, true);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  updateCandidateDetailFromPublic(token, type) {
    const candidate = {
      token: token,
      type: type,
      candidate: {
        ...this.updatedCandidateData,
        candidateId: this.candidateId
      }
    };
    return (ApiService.edit(ApiUrl.updateCandidateAtsFromPublic, candidate).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
            this.initializeCandidateData(response.data.result, true);
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  unsubscribeCandidateFromPublic(token, type) {
    const candidate = {
      token: token,
      type: type,
    };
    return (ApiService.edit(ApiUrl.unsubscribeAudienceMemberFromPublic, candidate).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  },

  unsubscribeCandidate() {
    const { user } = userStore;
    const payload = {
      candidateId: this.candidateId,
      userId: user.id
    }
    return (ApiService.edit(ApiUrl.unsubscribeCandidate, payload).then(
      action(
        response => {
          if (response && response.status === 200 && response.data && !response.data.isError) {
            // TODO HERE if something necessary, for now just return data exactly from API
          } else {
            Alert.error("Oops, Something went wrong! Please try again.");
          }
          return Promise.resolve(response.data);
        }
      )
    ).catch(
      action(
        err => {
          return Promise.reject(err);
        }
      )
    ))
  }
});

export default candidateStore;
