import { fabricStore, storyFormStore } from "stores";
import { PRIMARY_COLOR } from "helpers/Constants";

const showIsAddStep = e => {
  fabricStore.showAddStep(true);
  storyFormStore.emptyTempFormData();
};

const addStepMouseOver = e => {
  const { canvas } = fabricStore;
  if (e && e.target && e.target.id && e.target.id.startsWith("add-step")) {
    // if mouse over change border color, etc
    e.target._objects[0].set("stroke", PRIMARY_COLOR);
    canvas.renderAll();
  }
};

const addStepMouseOut = e => {
  const { canvas } = fabricStore;
  if (e && e.target && e.target.id && e.target.id.startsWith("add-step")) {
    // if mouse out revert border color, etc
    e.target._objects[0].set("stroke", "#2C2C2C");
    canvas.renderAll();
  }
};

const removeActiveObjectOnClickCanvas = e => {
  if (!e.target) fabricStore.emptyActiveObject();
}

export { showIsAddStep, addStepMouseOver, addStepMouseOut, removeActiveObjectOnClickCanvas };
