import { observable } from "mobx";

var leadAudienceStore = observable({
    leadName: "",
    jobTitle: "",
    currentEmployer: "",
    emailAddress: "",
    phoneNumber: "",
    location: "",
    industry: "",
    recruiterRating: 0,
    lastContacted: "",
    recentActivity: "",
    roiAiScore: 0,

    showColumn: {
        leadName: true,
        jobTitle: true,
        currentEmployer: true,
        emailAddress: true,
        phoneNumber: true,
        location: true,
        industry: true,
        recruiterRating: true,
        lastContacted: false,
        recentActivity: false,
        roiAiScore: false
    },

    showColumnConfigure: {
        leadName: true,
        jobTitle: true,
        currentEmployer: true,
        emailAddress: true,
        phoneNumber: true,
        location: true,
        industry: true,
        recruiterRating: true,
        lastContacted: false,
        recentActivity: false,
        roiAiScore: false
    },

    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7],
    columnOrderTemp: [0, 1, 2, 3, 4, 5, 6, 7],

    columnOrderDisable: [],
    columnOrderDisableTemp: [],
    searchCandidateData: {},
    filterCandidateData: {},
    firstOrder: false,
    filterGrid: [],
    sortGrid: [],
    countRowLeads: '0',

    setFirstOrder(data) {
        this.firstOrder = data;
    },
    setColumnOrder(data) {
        this.columnOrder = data;
    },
    setColumnOrderTemp(data) {
        this.columnOrderTemp = data;
    },
    setOrderDisable(data) {
        this.columnOrderDisable = data;
    },
    setOrderDisableTemp(data) {
        this.columnOrderDisableTemp = data;
    },
    addItemColumnOrderDisableTemp(data) {
        this.columnOrderDisableTemp.push(data);
    },
    removeItemColumnOrderDisableTemp(data) {
        var index = this.columnOrderDisableTemp.indexOf(data);
        if (index > -1) {
            this.columnOrderDisableTemp.splice(index, 1);
        }
    },
    setcandidateName(leadName) {
        this.leadName = leadName;
    },
    setJobTitle(industry) {
        this.industry = industry;
    },
    setCompany(currentEmployer) {
        this.currentEmployer = currentEmployer;
    },
    setLocation(location) {
        this.location = location;
    },
    setResume(resume) {
        this.resume = resume;
    },
    setLastContacted(lastContacted) {
        this.lastContacted = lastContacted;
    },
    setRecentActivity(recentActivity) {
        this.recentActivity = recentActivity;
    },
    setPhoneNumber(phoneNumber) {
        this.phoneNumber = phoneNumber;
    },
    setEmailAddress(emailAddress) {
        this.emailAddress = emailAddress;
    },
    setRoiAiScore(roiAiScore) {
        this.roiAiScore = roiAiScore;
    },
    setRecruiterRating(recruiterRating) {
        this.recruiterRating = recruiterRating;
    },
    setShowColumn(data) {
        this.showColumn = { ...this.showColumn, ...data };
    },
    setShowColumnConfigure(data) {
        this.showColumnConfigure = { ...this.showColumnConfigure, ...data };
    },
    setShowColumnConfigureDefault(data) {
        this.showColumnConfigure = data
    },
    setSearchLeadData(searchleadData) {
        this.searchleadData = searchleadData;
    },
    setFilterLeadData(filterleadData) {
        this.filterleadData = filterleadData;
    },
    setCountRowLeads(countRowLeads) {
        this.countRowLeads = countRowLeads;
    },
    setFilterGrid(filterGrid) {
        this.filterGrid = filterGrid;
    },
    setSortGrid(sortGrid) {
        this.sortGrid = sortGrid;
    },
});

export default leadAudienceStore;
