import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { observer } from "mobx-react";
import { automationStore, emailStore, userStore, generalSettingStore, atsStore, candidateOwnerSettingStore } from "stores";
import stepEmailIcon from "assets/img/automation/step-email.svg";
import SubmitButton from "./SubmitButton";
import Select from 'react-select';
import { RappidNodeType, RappidText } from "helpers/Constants";
import { FormGroup, Input, InputGroupAddon, InputGroup, Label, Row, Col, UncontrolledTooltip } from "reactstrap";
import HeaderForm from "./HeaderForm";
import { toJS } from "mobx";
import { EditRappidNode } from "components/Rappid/RappidCanvasFunctions";
import { Constants, Enum, Validator } from "helpers";
import { SelectAutomation } from "_globalStyle";
import { EmailEditorButton, LabelField, SidebarFormSelect } from "components/Automation/Styled";
import { AutomationLabelSchedule, AutomationDateSpan, DateFormGroup } from "../Styled";
import Alert from 'react-s-alert';
import DatePicker from 'react-datepicker';
import { SwitchColor } from "_variable";
import Switch from "react-switch";
import TimeInput from "./TimeInput";
import moment from "moment";
import MergeTagsDropdown from "components/Automation/components/MergeTagsDropdown";
import { getScheduleText, getRecordUpdate } from "helpers/AutomationHelper";
import SendTestEmailButton from "./SendTestEmailButton";

const EmailForm = observer(forwardRef((props, ref) => {
  const { AutomationType, AutomationTypeText, OwnerTypeCandidate, OwnerTypeClient, TriggerType, EmailCategories, EmailCategory, EmailSources, EmailSource, AutomationSpecificDays, UpdateRecordType, UpdateRecordTypeClient, UpdateRecordOwnerType } = Enum;
  const { EmailSenderDefault } = Constants;
  const { formEmail, isCopiedTemplate, listSenderDomain, automationType, triggerList, triggerType, automationId, automationState } = automationStore;
  const { emailsList, emailTemplatesList } = emailStore;
  const { atsCategorisedFieldsForMergeTags, isJobScopeDisabled } = atsStore;
  const { candidateOwners } = candidateOwnerSettingStore;
  const isValidEmail = Validator.isValidEmailAutomation(formEmail.senderEmail);
  const disableButton = !isValidEmail ||
    formEmail.emailCampaignId === 0 ||
    formEmail.subject === "" ||
    formEmail.senderDomain === "" ||
    ((formEmail.senderEmail === "" || formEmail.senderName === "") &&
      !formEmail.isFromOwner) ||
    (formEmail.isSpecificTime && (!formEmail.startHour || formEmail.specificDays?.length === 0));
  const disableSendTestButton = !isValidEmail ||
    formEmail.emailCampaignId === 0 ||
    formEmail.subject === "" ||
    formEmail.senderDomain === "" ||
    formEmail.senderEmail === "" || formEmail.senderName === "";
  const optionsType = automationType === AutomationType.Candidate ? AutomationTypeText.Candidate : AutomationTypeText.Contact;
  const emailOptions = emailsList?.filter(x => x.category && x.category.toLowerCase().includes(optionsType.toLowerCase()));
  const emailTemplateOptions = emailTemplatesList?.filter(x => x.category && x.category.toLowerCase().includes(optionsType.toLowerCase()));
  const roleEmailSources = userStore.user.roleId === Enum.RoleName.Recruiter ? EmailSources.filter(x => x.id !== EmailSource.Scratch) : EmailSources;
  const isPlacementOrJobBasedTrigger = [TriggerType.PlacementBased, TriggerType.JobBased].includes(triggerType);
  const ownerType = automationType === AutomationType.Candidate ? OwnerTypeCandidate : OwnerTypeClient;
  const ownerDefaultValue = isPlacementOrJobBasedTrigger ? ownerType[1] : ownerType[0];
  const optionsUpdateRecord = automationType === AutomationType.Candidate ? UpdateRecordType : UpdateRecordTypeClient;
  const updateRecordDefaultValue = isPlacementOrJobBasedTrigger ? optionsUpdateRecord[1] : optionsUpdateRecord[0];
  const [updateRecordOption, setUpdateRecordOption] = useState(optionsUpdateRecord);
  const subjectEmailRef = useRef();
  const sendTestEmailRef = useRef(null);
  const isDraft = automationState === Enum.AutomationStateType.Draft;

  const isShowPlacementAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.PlacementJobApplicationReceived
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [
        Enum.TriggerListClient.ClientPlacementCreatedDate,
        Enum.TriggerListClient.ClientPlacementStartDate,
        Enum.TriggerListClient.ClientPlacementEndDate
      ].includes(item.firstColumn.id));
    }
  }

  const isShowApplicationAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.ApplicationStatus,
        Enum.TriggerList.JobStatus,
        Enum.TriggerList.JobJobApplicationReceived,
        Enum.TriggerList.PlacementJobApplicationReceived,
        Enum.TriggerList.InterviewDate
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [].includes(item.firstColumn.id));
    }
  }

  const isShowJobAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.ApplicationStatus,
        Enum.TriggerList.JobStatus,
        Enum.TriggerList.JobJobApplicationReceived,
        Enum.TriggerList.PlacementJobApplicationReceived,
        Enum.TriggerList.InterviewDate
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [
        Enum.TriggerListClient.ClientPlacementCreatedDate,
        Enum.TriggerListClient.ClientPlacementStartDate,
        Enum.TriggerListClient.ClientPlacementEndDate,
        Enum.TriggerListClient.JobStatus,
        Enum.TriggerListClient.ClientJobInterviewDate
      ].includes(item.firstColumn.id));
    }
  }

  useEffect(() => {
    atsStore.getCompanyInfo();
    atsStore.getAtsField();
    atsStore.getAtsCategorisedFields(automationType);
    candidateOwnerSettingStore.getCandidateOwners();
    emailStore.getEmailTemplates();
    emailStore.getEmails().then(() => {
      const cloneFormEmail = { ...automationStore.formEmail };
      if (isCopiedTemplate === true && automationId !== null && typeof cloneFormEmail.isUpdatedFromCopiedTemplate === "undefined") {

        cloneFormEmail.emailCategoryId = 0;
        cloneFormEmail.emailCategoryName = "";
        cloneFormEmail.emailSourceId = 0;
        cloneFormEmail.emailSourceName = "";
        cloneFormEmail.emailNewTemplateName = "";
        cloneFormEmail.emailCampaignId = 0;
        cloneFormEmail.emailCampaignName = "";
        cloneFormEmail.oriEmailCampaignId = 0;
        cloneFormEmail.oriEmailCampaignName = "";
        cloneFormEmail.senderName = EmailSenderDefault.Name;
        cloneFormEmail.senderEmail = EmailSenderDefault.Email;
        cloneFormEmail.senderDomain = EmailSenderDefault.Domain;

        automationStore.setFormEmail(cloneFormEmail, true);
        automationStore.setIsAutomationFormDirty(true);
        automationStore.setAutomationOnClear(true);
        EditRappidNode(RappidNodeType.Email, cloneFormEmail.emailCampaignName, "", "", "", cloneFormEmail, false, true);
      }
      else if (typeof formEmail.emailCategoryId === "undefined") {
        // Patch #7584 #7585
        const cloneFormEmail = { ...automationStore.formEmail };
        cloneFormEmail.emailCategoryId = EmailCategory.Existing;
        cloneFormEmail.emailCategoryName = EmailCategories.find(x => x.id === EmailCategory.Existing).name;
        if (typeof cloneFormEmail.emailSourceId === "undefined") {
          cloneFormEmail.emailSourceId = 0;
          cloneFormEmail.emailSourceName = "";
        }
        automationStore.setFormEmail(cloneFormEmail, true);
      }

      if (formEmail.emailCategoryId === EmailCategory.New)
        automationStore.setIsOpenNewTab(true);

      if ((triggerList.length > 0 && !isPlacementOrJobBasedTrigger) || triggerList.length === 0) {
        //reset owner when trigger list is null or trigger type is not placement based
        automationStore.setFormEmail({ ...automationStore.formEmail, ownerType: ownerDefaultValue.value }, true);
        setUpdateRecordOption(UpdateRecordType.filter(item => item.value !== 2));
      }

      //add new properties for handle old data email and reset specific time #8293
      if (typeof formEmail.isSpecificTime === "undefined" || !formEmail.isSpecificTime) {
        let data = { ...automationStore.formEmail };
        data.isSpecificTime = false;
        data.specificDays = [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday];
        data.startHour = null;
        data.convertedStartHour = null;
        automationStore.setFormEmail(data, true);
      }

      //add new properties for handle old data #8292
      if (typeof formEmail.isUpdateRecord === "undefined") {
        let data = { ...automationStore.formEmail };
        data.isUpdateRecord = false;
        data.updateRecordType = 1;
        automationStore.setFormEmail(data, true);
      }
      if (!isPlacementOrJobBasedTrigger || triggerList.length === 0) {
        if (!automationStore.formEmail.updateRecordType || automationStore.formEmail.updateRecordType === optionsUpdateRecord[1].value)
          automationStore.setFormEmail({ ...automationStore.formEmail, updateRecordType: updateRecordDefaultValue.value }, true);
        setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== optionsUpdateRecord[1].value));
      }

      if (!isPlacementOrJobBasedTrigger && automationStore.formEmail.isUpdateRecord) {
        if (automationStore.formEmail.additionalNoteType ?? false !== Enum.UpdateRecordNoteType.CandidateNote) {
          automationStore.setFormEmail({ ...automationStore.formEmail, additionalNoteType: Enum.UpdateRecordNoteType.CandidateNote });
        }
      }

      if (isPlacementOrJobBasedTrigger && automationStore.formEmail.isUpdateRecord) {
        if (automationStore.formEmail.additionalNoteType ?? false !== Enum.UpdateRecordNoteType.CandidateNote) {
          setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser));
        }
      }
    });

    return function cleanup() {
      automationStore.resetFormEmail();
    }
    // eslint-disable-next-line
  }, []);

  console.debug("Email Form Data: ", toJS(formEmail));

  // remove spaces from email input field
  const handleRemoveSpace = (value) => value.replace(/ /g, "");
  const handleKeyUpSpaceInput = (event) => { event.target.value = handleRemoveSpace(event.target.value); };
  const handlePasteSpaceInput = (event) => {
    event.preventDefault();
    const text = handleRemoveSpace((event.clipboardData || window.clipboardData).getData('text'));
    event.target.value = text;
    automationStore.setFormEmail({ ...formEmail, senderEmail: text })
  };

  const redirectToEmailEditor = (event) => {
    if (!formEmail.emailCampaignId) {
      Alert.error('Select email first to continue')
    } else
      openEmailInNewTab(EmailSource.ViewEmail, formEmail.emailCampaignId);
  }

  const setExistingEmailCampaign = (event) => {
    const selectId = parseInt(event.id);
    const selectName = event.name;
    const emailCampaignData = {
      ...formEmail,
      emailCampaignId: selectId,
      emailCampaignName: selectName
    };
    automationStore.setFormEmail(emailCampaignData);
  }

  const setNewEmailCampaign = (event) => {
    const selectId = parseInt(event.id);
    const selectName = event.name;
    const emailCampaignData = {
      ...formEmail,
      oriEmailCampaignId: selectId,
      oriEmailCampaignName: selectName,
      emailCampaignId: 0,
      emailCampaignName: ""
    };
    automationStore.setFormEmail(emailCampaignData);
  }

  const resetEmailForm = () => {
    automationStore.setFormEmail({
      ...formEmail,
      emailCategoryId: 0,
      emailCategoryName: "",
      emailSourceId: 0,
      emailSourceName: "",
      emailCampaignId: 0,
      emailCampaignName: "",
      subject: "",
    });
  }

  const openNewTabSwitcher = (emailSourceId, selectedId) => {
    // eslint-disable-next-line default-case
    switch (emailSourceId) {
      case EmailSource.CopyExisting:
        return window.open(`/admin/emails/editor/${selectedId}?fromAutomation=1&fromScratch=1&sourceId=${selectedId}&candidateAutomation=${automationStore.automationType === AutomationType.Candidate ? 1 : 0}`, "_blank");
      case EmailSource.Template:
        return window.open(`/admin/emails/editor?tid=${selectedId}&fromAutomation=1&fromScratch=1&sourceTid=${selectedId}&candidateAutomation=${automationStore.automationType === AutomationType.Candidate ? 1 : 0}`, "_blank");
      case EmailSource.Scratch:
        return window.open(`/admin/emails/editor?fromAutomation=1&fromScratch=1&candidateAutomation=${automationStore.automationType === AutomationType.Candidate ? 1 : 0}`, "_blank");
      case EmailSource.ViewEmail:
        return window.open(`/admin/emails/editor/${selectedId}?fromAutomation=1&candidateAutomation=${automationStore.automationType === AutomationType.Candidate ? 1 : 0}`, '_blank');
    }
  }

  const openEmailInNewTab = (emailSourceId, selectedId) => {
    const newTab = openNewTabSwitcher(emailSourceId, selectedId);

    newTab.addEventListener("message", response => {
      const { data } = response;
      if (data.isSave) {
        if (!data.isFormCopyToExisting) {
          if (emailSourceId === EmailSource.ViewEmail) {
            emailStore.getEmails().then(() => {
              automationStore.setFormEmail({
                ...formEmail,
                emailCampaignId: data.emailCampaignId,
                emailCampaignName: data.emailCampaignName
              });
            });            
          }
          else {
            automationStore.setFormEmail({
              ...formEmail,
              emailSourceId: emailSourceId,
              oriEmailCampaignId: selectedId,
              emailCampaignId: data.emailCampaignId,
              emailCampaignName: data.emailCampaignName
            });
          }
          emailStore.setIsCopyToExisting(false);
          emailStore.setIsUseEmailTemplate(false);
        }
        else {
          resetEmailForm();
          emailStore.setIsCopyToExisting(false);
          emailStore.setIsFromCopyToExisting(false);
          emailStore.setIsUseEmailTemplate(false);
          automationStore.setIsOpenNewTab(false);
        }
      }
      else if (data.isCancel && emailSourceId !== EmailSource.ViewEmail) {
        resetEmailForm();
        automationStore.setIsOpenNewTab(false);
      }
    });
  };

  const renderDaysComponent = (ListDays) => {
    if (automationStore.formEmail.specificDays === undefined)
      automationStore.formEmail.specificDays = [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday];

    let data = { ...automationStore.formEmail };
    data.specificDays = [...automationStore.formEmail.specificDays];
    data.timeVariableDays = data.specificDays.toString();

    const daysComponent = ListDays.map((item, index) => {
      let days = [...automationStore.formEmail.specificDays];
      let isChecked = days.find(x => x === item.value);

      return (
        <AutomationDateSpan key={index}>
          <Input
            type="checkbox"
            id={`email-form-time-variable-${item.day}`}
            className="automation-time-variable-checkbox-days text-white"
            name={`email-form-time-variable-${item.day}`}
            checked={isChecked ? true : false}
            disabled={!isDraft}
            onChange={(e) => {
              let data = { ...formEmail };
              data.specificDays = [...formEmail.specificDays];
              data.timeVariableDays = data.specificDays.slice();
              const checked = e.target.checked;
              if (checked) {
                data.specificDays = [...data.specificDays, item.value];
                data.timeVariableDays = data.specificDays.toString();
              } else {
                const removeValue = data.specificDays.indexOf(item.value);
                data.specificDays.splice(removeValue, 1);
                data.timeVariableDays = data.specificDays.toString();
              }
              automationStore.setFormEmail(data);
            }}
          />
          <Label htmlFor={`email-form-time-variable-${item.day}`} >
            {item.day}
          </Label>
        </AutomationDateSpan>
      );
    });
    return daysComponent;
  };

  const handleAutomationSubjectEmailTokenSelected = (token) => {
    const automationTextToken = ` {{${token}}}`;

    if (subjectEmailRef.current) {
      subjectEmailRef.current.value = formEmail.subject + automationTextToken;
      automationStore.setFormEmail({ ...formEmail, subject: subjectEmailRef.current.value });
    }
  }

  const isShowAdditionalNotes = () => !isJobScopeDisabled && isShowJobAdditionalNotes();

  useImperativeHandle(ref, () => ({
    isModalOpen: () => sendTestEmailRef.current?.isModalOpen() || false,
    openModal: () => sendTestEmailRef.current?.openModal(),
    closeModal: () => sendTestEmailRef.current?.closeModal(),
  }));

  return (
    <>
      <HeaderForm
        automationHeaderId="form-step-email"
        automationHeaderImgSrc={stepEmailIcon}
        automationHeaderImgAlt="icon-email"
        automationHeaderTextStep={RappidText.EmailStep}
      />
      <Row className="ml-0 mr-0">
        <SidebarFormSelect
          className="automation-email-source col-md-12 px-0 mb-2"
          id="automation-email-category"
          name="automation-email-category"
          classNamePrefix="automation-dropdown"
          placeholder="- Select email source -"
          isDisabled={!isDraft}
          onChange={e => {
            if (formEmail.emailCategoryId !== e.id) {
              const data = {
                ...formEmail,
                emailCategoryId: e.id,
                emailCategoryName: e.name,
                emailSourceId: 0,
                emailSourceName: "- Select source -",
                emailCampaignId: 0,
                emailCampaignName: ""
              };
              automationStore.setFormEmail(data);
              automationStore.setIsOpenNewTab(false);
            }
          }}
          options={EmailCategories}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={EmailCategories.find(x => x.id === formEmail.emailCategoryId) || 0}
        />
      </Row>
      {formEmail.emailCategoryId === EmailCategory.New && (
        <Row className="ml-0 mr-0">
          <SidebarFormSelect
            className="automation-email-template col-md-12 px-0 mb-2"
            id="automation-email-source"
            name="automation-email-source"
            classNamePrefix="automation-dropdown"
            placeholder="- Select source -"
            isDisabled={!isDraft}
            onChange={e => {
              if (formEmail.emailSourceId !== e.id) {
                const selectedId = parseInt(e.id);
                const selectedName = e.name;
                const emailCampaignData = {
                  ...formEmail,
                  emailSourceId: selectedId,
                  emailSourceName: selectedName,
                  emailCampaignName: ""
                };
                automationStore.setFormEmail(emailCampaignData);

                if (e.id === EmailSource.CopyExisting) {
                  automationStore.setIsOpenNewTab(true);
                } else if (e.id === EmailSource.Template) {
                  automationStore.setIsOpenNewTab(true);
                } else {
                  automationStore.setIsOpenNewTab(true);
                  openEmailInNewTab(selectedId, null);
                }
              }
            }}
            options={roleEmailSources}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            value={roleEmailSources.find(x => x.id === formEmail.emailSourceId) || 0}
          />
        </Row>
      )}
      {(formEmail.emailCategoryId === EmailCategory.Existing || formEmail.emailSourceId === EmailSource.CopyExisting || formEmail.emailSourceId === EmailSource.Template || formEmail.emailSourceId === EmailSource.Scratch) &&
        <>
          {(formEmail.emailCategoryId === EmailCategory.Existing || formEmail.emailSourceId === EmailSource.CopyExisting) && (
            <Row className="ml-0 mr-0">
              <SidebarFormSelect
                className="automation-email-template col-md-12 px-0 mb-2"
                id="automation-email-source"
                name="automation-email-source"
                classNamePrefix="automation-dropdown"
                placeholder="- Select email -"
                isDisabled={!isDraft}
                onChange={e => {
                  if (formEmail.emailSourceId === EmailSource.CopyExisting) {
                    setNewEmailCampaign(e)
                    if (automationStore.isOpenNewTab) {
                      openEmailInNewTab(formEmail.emailSourceId, e.id);
                    }
                  }
                  else {
                    setExistingEmailCampaign(e)
                  }
                }}
                options={emailOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={formEmail.emailCategoryId === EmailCategory.Existing ? emailOptions.find(x => x.id === formEmail.emailCampaignId) : emailOptions.find(x => x.id === formEmail.oriEmailCampaignId)}
              />
            </Row>
          )}
          {formEmail.emailSourceId === EmailSource.Template && (
            <Row className="ml-0 mr-0">
              <SidebarFormSelect
                className="automation-email-template col-md-12 px-0 mb-2"
                id="automation-email-source"
                name="automation-email-source"
                classNamePrefix="automation-dropdown"
                placeholder="- Select template -"
                isDisabled={!isDraft}
                onChange={e => {
                  setNewEmailCampaign(e);
                  openEmailInNewTab(formEmail.emailSourceId, e.id);
                }}
                options={emailTemplateOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={emailTemplateOptions.find(x => x.id === formEmail.oriEmailCampaignId)}
              />
            </Row>
          )}
          {automationStore.isOpenNewTab && (
            <Row className="ml-0 mr-0 mb-2">
              <Input
                type="text"
                id="automation-email-new-template-name"
                className="rounded-pill automation-email-input"
                placeholder="Save your email to view it here"
                disabled={!isDraft}
                value={automationStore.formEmail.emailCampaignName}
                readOnly={true}
              />
            </Row>
          )}
          <Row className="ml-0 mr-0">
            <EmailEditorButton
              id="automation-email-form-redirect-editor-button"
              className="col-md-12 mt-3 btn-roi secondary outline-white pr-5"
              disabled={!formEmail.emailCampaignId}
              onClick={redirectToEmailEditor}
            >
              View email
            </EmailEditorButton>
          </Row>
        </>
      }
      <LabelField className="m-1">
        To view your {optionsType} emails here, please ensure they are tagged as '{optionsType}'.
      </LabelField>
      <FormGroup className="step-body-text mt-1">
        <span className="mb-3"><b><Label id="senderNameLabel" className="text-white">Sender name</Label></b></span>
        <UncontrolledTooltip placement="bottom" target="senderNameLabel">
          The name the recipient sees in their inbox
        </UncontrolledTooltip>
        <Input
          type="text"
          id="automation-email-form-name"
          className={`rounded-pill automation-email-input ${formEmail.isFromOwner && `automation-input-disabled`}`}
          value={automationStore.formEmail.senderName}
          onChange={e => { automationStore.setFormEmail({ ...formEmail, senderName: e.target.value }) }}
          disabled={formEmail.isFromOwner || !isDraft}
        />
      </FormGroup>
      <FormGroup className="step-body-text">
        <span className="mb-3"><b><Label id="senderEmailLabel" className="text-white">Sender email</Label></b></span>
        <UncontrolledTooltip placement="bottom" target="senderEmailLabel">
          The email address recipients see and reply to
        </UncontrolledTooltip>
        <InputGroup>
          <Input
            type="text"
            id="automation-email-form-email"
            className={`rounded-pill automation-email-input automation-email-input-form-email ${formEmail.isFromOwner && `automation-input-disabled`}`}
            placeholder={formEmail.senderEmail}
            value={formEmail.senderEmail}
            onPaste={e => handlePasteSpaceInput(e)}
            onKeyUp={e => handleKeyUpSpaceInput(e)}
            onChange={e => { automationStore.setFormEmail({ ...formEmail, senderEmail: e.target.value }); }}
            disabled={formEmail.isFromOwner || !isDraft}
          />
          <InputGroupAddon addonType="append">
            <Select
              styles={SelectAutomation}
              id="automation-email-form-sender-domain"
              classNamePrefix="custom-input-dropdown"
              placeholder="Select Domain"
              menuPosition="fixed"
              menuPlacement="auto"
              label="Single select"
              menuShouldBlockScroll={true}
              isDisabled={formEmail.isFromOwner || !isDraft}
              onChange={e => { automationStore.setFormEmail({ ...formEmail, senderDomain: e.label, companyDomainId: e.value }); }}
              options={listSenderDomain}
              getOptionLabel={(option) => option?.label}
              getOptionValue={(option) => option?.value}
              value={listSenderDomain.find(x => x.value === formEmail.companyDomainId)}
            />
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <FormGroup className="step-body-text">
        <Input
          type="checkbox"
          id="automation-email-from-owner"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          checked={formEmail.isFromOwner}
          disabled={!isDraft}
          onChange={(e) => { automationStore.setFormEmail({ ...formEmail, isFromOwner: !formEmail.isFromOwner, ownerType: e.target.checked ? ownerDefaultValue.value : null, updateRecordType: updateRecordDefaultValue.value }); }}
        />
        <Label check htmlFor={`automation-email-from-owner`} className="mb-2">
          {triggerList.length > 0 && isPlacementOrJobBasedTrigger ? "Send from" : automationStore.automationType === Enum.AutomationType.Candidate ? "Send from Recruiter" : "Send from Owner"}
        </Label>
        {triggerList.length > 0 && isPlacementOrJobBasedTrigger &&
          <Select
            isDisabled={!formEmail.isFromOwner || !isDraft}
            id="automation-email-from-ownertype"
            className={`automation-email-template col-md-12 px-0 mb-2 ${!formEmail.isFromOwner && `automation-disable-dropdown`}`}
            classNamePrefix="automation-dropdown"
            placeholder="Select Owner"
            menuPosition="fixed"
            menuPlacement="auto"
            menuShouldBlockScroll={true}
            onChange={(e) => {
              automationStore.setFormEmail({ ...formEmail, ownerType: e.value, updateRecordType: e.value });
            }}
            options={ownerType.filter(item => ![Enum.UpdateRecordOwnerType.NoUser, Enum.UpdateRecordOwnerType.AdminUser].includes(item.value))} // #10164: remove 'No User' when IsFromOwner selected
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={formEmail.ownerType ? ownerType.find(x => x.value === formEmail.ownerType) : ownerDefaultValue}
          />
        }
      </FormGroup>

      <FormGroup className="step-body-text">
        <span className="mb-3"><b><Label className="text-white">Subject</Label></b></span>
        <Input
          ref={subjectEmailRef}
          type="textarea"
          rows='3'
          id="automation-email-subject"
          className="automation-email-input border-radius-normal"
          value={formEmail.subject}
          disabled={!isDraft}
          onChange={e => {
            automationStore.setFormEmail({ ...formEmail, subject: e.target.value });
          }}
        />
        <Row className="my-2">
          <Col sm={12} md={12}>
            <MergeTagsDropdown
              atsCategorisedFieldsForMergeTags={atsCategorisedFieldsForMergeTags}
              onTagSelected={(tokenVariable) => handleAutomationSubjectEmailTokenSelected(tokenVariable)}
              isDisabled={!isDraft}
              isAutomationContact={automationType !== AutomationType.Candidate && triggerType === TriggerType.PlacementBased}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup className="step-body-text">
        <SendTestEmailButton
          ref={sendTestEmailRef}
          formEmail={formEmail}
          candidateOwners={candidateOwners}
          contactType={automationType}
          isDisabled={disableSendTestButton} />
      </FormGroup>

      <FormGroup className="step-body-text">
        <Input
          type="checkbox"
          id="automation-email-update-record-input"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          checked={formEmail.isUpdateRecord}
          disabled={!isDraft}
          onChange={(e) => {
            automationStore.setFormEmail({ ...formEmail, isUpdateRecord: !formEmail.isUpdateRecord });
          }}
        />
        <Label check id="update-record-id" htmlFor={`automation-email-update-record-input`} className="mb-2">
          Update database note using the Subject Line
        </Label>
        <UncontrolledTooltip placement="bottom" target="update-record-id" style={{ backgroundColor: "white", color: "black" }}>
          Use the email subject line to update the database notes
        </UncontrolledTooltip>
        <Select
          isDisabled={!formEmail.isUpdateRecord || formEmail.isFromOwner || !isDraft}
          id="automation-email-from-update-record-type"
          className={`automation-email-template col-md-12 px-0 mb-2 ${!formEmail.isUpdateRecord && `automation-disable-dropdown`}`}
          classNamePrefix="automation-dropdown"
          placeholder="Select Update Record"
          menuPosition="fixed"
          menuPlacement="auto"
          menuShouldBlockScroll={true}
          onChange={(e) => {
            automationStore.setFormEmail({ ...formEmail, updateRecordType: e.value });
          }}
          options={updateRecordOption}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          value={formEmail.updateRecordType ? updateRecordOption.find(x => x.value === formEmail.updateRecordType) : updateRecordDefaultValue}
        />
      </FormGroup>
      {formEmail.isUpdateRecord && isShowAdditionalNotes() &&
        <>
          <FormGroup className="step-body-text">
            <span className="mb-3"><b><Label className="text-white">Add note to</Label></b></span><br />
            <Input
              type="checkbox"
              id="automation-update-to-candidate-note"
              className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
              checked={formEmail.isUpdateRecord && formEmail.additionalNoteType === Enum.UpdateRecordNoteType.CandidateNote}
              disabled={!isDraft}
              onChange={(e) => {
                automationStore.setFormEmail({
                  ...formEmail,
                  isUpdateRecord: formEmail.isUpdateRecord,
                  isUpdateAdditionalNotes: formEmail.isUpdateRecord,
                  additionalNoteType: Enum.UpdateRecordNoteType.CandidateNote
                });
                setUpdateRecordOption(optionsUpdateRecord);
              }}
            />
            <Label check htmlFor={`automation-update-to-candidate-note`} className="mb-1 mr-3">
              {automationType === Enum.AutomationType.Candidate ? 'Candidate' : 'Contact'}
            </Label>
            {isShowPlacementAdditionalNotes() &&
              <>
                <Input
                  type="checkbox"
                  id="automation-update-to-placement-note"
                  className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                  checked={formEmail.isUpdateRecord && formEmail.additionalNoteType === Enum.UpdateRecordNoteType.PlacementNote}
                  disabled={!isDraft}
                  onChange={(e) => {
                    automationStore.setFormEmail({
                      ...formEmail,
                      isUpdateRecord: formEmail.isUpdateRecord,
                      isUpdateAdditionalNotes: formEmail.isUpdateRecord,
                      additionalNoteType: Enum.UpdateRecordNoteType.PlacementNote,
                      updateRecordType: formEmail.updateRecordType === UpdateRecordOwnerType.NoUser ? UpdateRecordOwnerType.AdminUser : formEmail.updateRecordType
                    });
                    setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== UpdateRecordOwnerType.NoUser));
                  }}
                />
                <Label check htmlFor={`automation-update-to-placement-note`} className="mb-1 mr-3">
                  Placement
                </Label>
              </>
            }
            <Input
              type="checkbox"
              id="automation-update-to-job-note"
              className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
              checked={formEmail.isUpdateRecord && formEmail.additionalNoteType === Enum.UpdateRecordNoteType.JobNote}
              disabled={!isDraft}
              onChange={(e) => {
                automationStore.setFormEmail({
                  ...formEmail,
                  isUpdateRecord: formEmail.isUpdateRecord,
                  isUpdateAdditionalNotes: formEmail.isUpdateRecord,
                  additionalNoteType: Enum.UpdateRecordNoteType.JobNote,
                  updateRecordType: formEmail.updateRecordType === UpdateRecordOwnerType.NoUser ? UpdateRecordOwnerType.AdminUser : formEmail.updateRecordType
                });
                setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== UpdateRecordOwnerType.NoUser));
              }}
            />
            <Label check htmlFor={`automation-update-to-job-note`} className="mb-1 mr-3">
              Job
            </Label>
            {isShowApplicationAdditionalNotes() &&
              <>
                <Input
                  type="checkbox"
                  id="automation-update-to-application-note"
                  className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                  checked={formEmail.isUpdateRecord && formEmail.additionalNoteType === Enum.UpdateRecordNoteType.ApplicationNote}
                  disabled={!isDraft}
                  onChange={(e) => {
                    automationStore.setFormEmail({
                      ...formEmail,
                      isUpdateRecord: formEmail.isUpdateRecord,
                      isUpdateAdditionalNotes: formEmail.isUpdateRecord,
                      additionalNoteType: Enum.UpdateRecordNoteType.ApplicationNote,
                      updateRecordType: formEmail.updateRecordType === UpdateRecordOwnerType.NoUser ? UpdateRecordOwnerType.AdminUser : formEmail.updateRecordType
                    });
                    setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== UpdateRecordOwnerType.NoUser));
                  }}
                />
                <Label check htmlFor={`automation-update-to-application-note`} className="mb-1 mr-3">
                  Application
                </Label>
              </>
            }
          </FormGroup>
        </>}

      <FormGroup className="step-body-text">
        <Label sm={1} md={9} className="mb-1 w-75">Email schedule</Label>
        <Switch
          id="time-variable-switch"
          className="float-right automation-sidebar-form-switch"
          uncheckedIcon={
            <div className="switch-specific-time">
              OFF
            </div>
          }
          checkedIcon={
            <div className="switch-specific-time">
              ON
            </div>
          }
          onChange={() => {
            let data = { ...formEmail };
            data.isSpecificTime = !formEmail.isSpecificTime;
            if (data.isSpecificTime) {
              data.startHour = new Date(moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }));
              data.convertedStartHour = automationStore.convertStepTimeToString(data.startHour);
            } else {
              data.startHour = null;
              data.convertedStartHour = null;
            }
            automationStore.setFormEmail(data);
          }}
          checked={formEmail.isSpecificTime}
          disabled={!isDraft}
          onColor={SwitchColor}
          onHandleColor="#EF4277"
          offHandleColor="#EF4277"
          height={23}
          width={42}
        />
        <hr className="hr-style mb-1" />
        <Label className="text-white pl-3">Set a specific time for this step to send.</Label>
        {formEmail.isSpecificTime && (
          <FormGroup row>
            <DateFormGroup row check>
              {renderDaysComponent(Enum.ListDaysShort)}
            </DateFormGroup>
            <FormGroup row className="ml-3 mb-1 w-100" >
              <Col md={12} lg={6} className="w-100 d-flex mt-2">
                <AutomationLabelSchedule className="text-white mt-2">
                  at
                </AutomationLabelSchedule>
                <DatePicker
                  id="email-form-input-start-hours"
                  autoComplete="off"
                  disabled={!isDraft}
                  selected={new Date(moment().set(automationStore.convertStringTimeToObject(formEmail.convertedStartHour)))}
                  onChange={date => {
                    let data = { ...formEmail };
                    data.startHour = date;
                    data.convertedStartHour = automationStore.convertStepTimeToString(date)
                    automationStore.setFormEmail(data);
                  }}
                  className="w-100 font-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="hh:mm aa"
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  customInput={<TimeInput isSideBarForm={true} icon={true} />}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-1 w-100" >
              <p className="small ml-4 font-italic text-white"> Your app's timezone is {generalSettingStore.timeZoneName}.</p>
            </FormGroup>
          </FormGroup>
        )}
      </FormGroup>
      {isDraft &&
        <SubmitButton
          type={RappidNodeType.Email}
          text={renderEmailNodeLine1(formEmail)}
          text2={renderEmailNodeLine2(formEmail)}
          text3={renderEmailNodeLine3(formEmail)}
          text4={renderEmailNodeLine4(formEmail)}
          roiData={formEmail}
          isDisabled={disableButton} />
      }
    </>
  );
}));

export const renderEmailNodeLine1 = (formEmail) => "Email: " + formEmail.emailCampaignName;

export const renderEmailNodeLine2 = (formEmail) => {
  if (formEmail.isFromOwner) {
    if (automationStore.automationType === Enum.AutomationType.Candidate) {
      if (formEmail.ownerType === Enum.UpdateRecordOwnerType.JobOwner) {
        return "From: Job owner";
      } else {
        return "From: Recruiter"
      }
    } else {
      return "From: Owner"
    }
  }
  return "From: " + formEmail.senderName;
}

export const renderEmailNodeLine3 = (formEmail) => {
  if (formEmail.isSpecificTime) {
    return getScheduleText(formEmail.specificDays, formEmail.startHour);
  } else {
    if (formEmail.isUpdateRecord)
      return getRecordUpdate(formEmail.updateRecordType, automationStore.automationType);
  }
}

export const renderEmailNodeLine4 = (formEmail) => {
  return formEmail.isSpecificTime && formEmail.isUpdateRecord
    ? getRecordUpdate(formEmail.updateRecordType, automationStore.automationType)
    : "";
}


export default EmailForm;
