import styled, { createGlobalStyle } from 'styled-components';
import {
  Row,
  Button,
  Input,
  UncontrolledDropdown,
  Col,
  Card,
  NavLink
} from 'reactstrap';
import {
  HeaderAutomationsColor,
  SecondaryColor,
  DisabledColor,
  LightGrayColor,
  PrimaryColor,
  WhiteColor
} from '_variable';
import PhoneInput from "react-phone-input-2";

export const ButtonHref = styled.button`
    text-align : left;
    color : ${SecondaryColor};
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
`;

export const HiddenMainOverflowX = createGlobalStyle`
    .main {
      overflow-x : hidden;
    }
`;

export const HideScrollbar = createGlobalStyle`
    body::-webkit-scrollbar {
      display :none;
    }
`;

export const GrayCol = styled(Col)`
    background-color : ${LightGrayColor};
    border : solid;
    height : 100%;
    padding : 20px;
    code {
        color : black !important;
    }
`;

export const AllSelectWhiteOnWrite = createGlobalStyle`
    .automation-dropdown__input-container {
        color : white !important;
    }
`;

export const HideTripettoHelp = createGlobalStyle`
  body {
      .tripetto-builder {
        a[href^="https://tripetto.com"] {
            pointer-events : none;
            color : ${DisabledColor};
        }
      }

      div[role="menu"] {
        a[href^="https://tripetto.com"] {
            display : none;
        }
        a[href^="https://tripetto.com"] + li {
          display : none;
        }
      }
  }
`;

export const ButtonRoundedBack = styled(Button)`
    border-radius: 20px;
    padding: 4px 30px;
    margin: 0 5px;

    &.bg-disabled {
      background-color: #fff !important;
    }
`;

export const ButtonRoundedSave = styled(Button)`
    border-radius: 20px;
    padding: 4px 30px;
    margin: 0 5px;

    &.bg-disabled {
      background-color: #fff !important;
    }
`;

export const FullWhiteCol = styled(Col)`
  background-color : white;
  margin-bottom : 0px !important;
`

export const FullCol = styled(Col)`
    ${(props) => props.contentheight ? `height : ${props.contentheight}` : `height : 83.7vh !important;`}
`

export const WhiteListButton = styled(Button)`
    color : #C3C3C3 !important;
    border-color : #C3C3C3 !important;
`

export const CustomHeightNavLink = styled(NavLink)`
    ${(props) => props.navlinkheight && `height : ${props.navlinkheight} !important`}
`

export const CardNoMargin = styled(Card)`
    margin : 0px;
    height : 53px;
    background-color : ${SecondaryColor};
    ${(props) => props.hidden && `display:none`};
    div.p-0.col-md-2 {
      background-color : ${SecondaryColor};
      ul {
        background-color : ${SecondaryColor};
        li {
          background-color : ${SecondaryColor};
          a.title {
              background-color : var(--primary-color);
              width : 100%;
              border : none;
          }
        }
      }
    }
`;

export const UserSpan = styled.span`
    ${(props) => props.minimized === "true" ? `
      @media (min-width : 992px) {
          display : none !important
      }
    ` : `
    display: unset
    `}
`

export const ButtonRightCol = styled(Col)`
  ul {
      height : 100%;
      border : none;
      padding : 5px;
      padding-right : 20px;
      background-color : ${SecondaryColor};
  }
`;

export const SelectContact = styled(PhoneInput)`
    background-color : white !important;
    color : var(--secondary) !important;
    font-size : 10px;
    font-family : 'Roboto';
    &:hover {
      background-color : var(--secondary) !important;
    }
`;

export const NoBorderSelect = styled(Input)`
    border :none;
    font-style : 'Roboto';
    &:disabled {
      background-color: #3B3A3A !important;
      color: grey !important;
    }
`;

export const SelectPhoneInput = styled(PhoneInput)`
    background-color : red;
`;

export const GrayTextArea = styled(Input)`
    background-color: #E5E5E5;
    border-radius: 20px;
    &::-webkit-resizer {
      color : transparent;
    }
`;

export const NoBorderDropdown = styled(UncontrolledDropdown)`
    button {
        background-color : #fff;
        color : #2B2B2B;
        font-weight : Bold;
        font-family : 'Roboto';
        border : none;
        border-bottom : solid 1px;
        border-radius : 0px;

        i {
            float: right;
            margin-top : 5px;
        }
    }
`;

export const RightLogo = styled.a`
    margin-left : 10px;
    img {
        width: 20px;
    }
`

export const GrayRichTextEditor = styled(Col)`
  .tox-tinymce {
    border-radius : 20px;
    border : none;
    iframe {
      background-color : #E5E5E5 !important;
    }
    .tox-statusbar {
      background-color : #E5E5E5 !important;
      border :none;
    }
    .tox-toolbar {
      background-color : #E5E5E5 !important;
      border : none;
    }
    .tox-toolbar__group {
      margin-left : auto;
    }
  }
`;

export const HeaderInput = styled(Input)`
    font-size : 16px;
    font-family : 'Roboto';
    font-style : italic;
    font-weight : Bold;
    color : #2C2C2C;
    width : 100%;
    background-color : #E5E5E5;
    &:placeholder-shown {
       font-style: italic;
    }
`

export const HeaderRow = styled(Row)`
    ${(props) => props.tab === "true" ? `background-color: ${SecondaryColor}; margin-left : 10px;` : `background-color: ${HeaderAutomationsColor}`};
    .text-right.col-md-12 {
      ${(props) => props.tab === "true" ? `background-color: ${SecondaryColor}` : `background-color: ${HeaderAutomationsColor}`};
    }

    .col-form-label {
        font-weight : bold;
        font-size : 16px;
    }
`;

export const WhiteButton = styled(Button)`
    background-color : ${HeaderAutomationsColor} !important;
    color : var(--secondary) !important;
    font-family : 'Roboto';
    &:hover {
      background-color : white !important;
    }
`;

export const VeryWhiteButton = styled(Button)`
    background-color : white !important;
    border-color : #000 !important;
    color : var(--secondary) !important;
    font-family : 'Roboto';
    &:hover {
      background-color : white !important;
    }
`;

export const SecondaryButton = styled(Button)`
    background-color : var(--secondary) !important;
    border-color : var(--secondary) !important;
    color : white !important;
    font-family : 'Roboto';
    &:hover {
      background-color : white !important;
      color : var(--secondary) !important;
    }
    &:disabled {
      background-color : var(--secondary) !important;
      border-color : var(--secondary) !important;
      color : white !important;
      font-family : 'Roboto';
    }
`;

export const SelectButton = styled(Button)`
    background-color : white !important;
    color : var(--secondary) !important;
    font-size : .875rem;
    font-family : 'Roboto';
    &:hover {
      background-color : var(--secondary) !important;
      color : var(--white) !important;
    }
`;
export const SelectCandidateButton = styled(Button)`
    background-color : var(--primary) !important;
    color : white !important;
    font-size : .875rem;
    font-family : 'Roboto';
    &:hover {
      background-color : white !important;
      color : var(--secondary) !important;
    }
    &:disabled {
      background-color : var(--primary) !important;
      color : white !important;
      font-size : .875rem;
      font-family : 'Roboto';
    }
`;

export const RemoveButton = styled(Button)`
    background-color : var(--primary) !important;
    border-color : var(--primary) !important;
    color : var(--white) !important;
    font-size : .875rem;
    font-family : 'Roboto';
    &:hover {
      background-color : var(--white) !important;
      color : var(--secondary) !important;
    }
`;

export const MarginHr = styled.hr`
  margin: ${(props) => props.margin || '0px 0px 1rem 0px'} !important;
`

export const NoTabRoiRow = styled(Row)`
  background-color: ${SecondaryColor};
  height : 52px !important;
  ul {
     border-bottom : none;
     padding : 0px;
     height : 52px !important;
     li {
        padding : 5px !important;
        text-align : left !important;
        height : 52px !important;
        a {
          font-size : 14px;
          padding : 10px !important;
          font-family : 'Roboto';
          font-weight : Bold;
          height : 100% !important;
          color : #fff !important;
        }
     }
  }
`

export const DivBordered = styled.div`
    border-width : 2px;
    border-style : solid;
    border-color : black;
    padding: 10px;
`

export const SelectPrimary = {
  control: styles => ({
    ...styles,
    backgroundColor: `${PrimaryColor}`,
    borderRadius: '20px',
    borderColor: '#2B2B2B',
    color: '#FFFFFF',
    fontSize: '12px',
    marginLeft: '-10px',
    width: '20vh',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#FFFFFF'
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: `${WhiteColor}`,
    borderRadius: '10px',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: `${WhiteColor}`,
      color: `${SecondaryColor}`,
      borderRadius: '20px',
    }
  }
};

export const SelectAutomation = {
  control: styles => ({
    ...styles,
    backgroundColor: `#222222`,
    borderRadius: '20px',
    borderColor: '#2B2B2B',
    color: '#FFFFFF',
    fontSize: '12px',
    marginLeft: '-10px',
    width: '20vh',
    minHeight: '36px',
    height: '36px'
  }),
  placeholder: styles => ({
    ...styles,
    color: '#FFFFFF'
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: `${WhiteColor}`,
    borderRadius: '10px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
    transition: 'opacity 300ms',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: `${WhiteColor}`,
      color: `${SecondaryColor}`,
      borderRadius: '20px',
    }
  }
};
