import { Constants, Enum } from "helpers";
import moment from 'moment';
import _ from "lodash";

const GetKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

const FilterDate = (filterLocalDateAtMidnight, cellValue) => {
  var dateAsString = cellValue;
  if (dateAsString == null) return -1;
  var dateParts = dateAsString.split('/');
  var cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );
  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
}

const ComparatorDate = (a, b) => {
    return a < b ? -1 : a > b ? 1 : 0;
}

const FilterDateWithTime = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = moment.utc(cellValue).local().format(); // format cellvalue to local datetime
  if (dateAsString == null) return -1;
  const dateParts = dateAsString.split('T');
  const dateNew = dateParts[0].split('-');
  const year = dateNew[0];
  const month = dateNew[1];
  const day = dateNew[2];
  const cellDate = new Date(Number(year), Number(month)-1, Number(day));

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
 if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
}

const ConvertManageChatbotRawData = (rawData) => {
  let convertedData = []
  const convertedStoryType = (storyType) => {
    switch (storyType) {
      case Enum.storyType.Website:
        return 'Website';
      case Enum.storyType.Registration:
        return 'Registration';
      case Enum.storyType.DataCleanse:
        return 'Data Cleanse';
      case Enum.storyType.Survey:
        return 'Survey';
      default:
        return '';
    }
  }

  const convertedIsPublished = (isPublished) => {
    return isPublished ? "Published" : "Unpublished"
  }

  rawData.forEach(element => {
    let convertedObject = {
      ...element,
      storyType: convertedStoryType(element.storyType),
      isPublished: convertedIsPublished(element.isPublished),
      publishedDate: moment.utc(element?.publishedDate).local().format('DD/MM/YYYY h:mma')
    }

    convertedData.push(convertedObject)
  });
  return convertedData
}

const onDNDColumn = (params, store) => {
  const order = Array.isArray(params) ? params.filter(x => x?.userProvidedColDef?.field !== Constants.CandidateColumnName.CHECKBOX_ROW) : [];
  let gridColumnCopy = [...store.gridColumn.filter(x => !x.hideColConfig).sort((a, b) => a.order - b.order)];
  gridColumnCopy = gridColumnCopy.slice();
  order.forEach((item, index) => {
    gridColumnCopy = gridColumnCopy.map(x => (x.field === item?.userProvidedColDef?.field ? { ...x, order: index, sort: item.sort } : x));
  });
  store.updateGridColumn(gridColumnCopy);
};


const onModelUpdated = (noRows, recentTotalPage, gridApi, columnApi, store) => {
  setFilterModel(gridApi, store);
  setSortModel(columnApi, store);

  if (noRows === 0) {
    gridApi.current && gridApi.current.showNoRowsOverlay();
  } else {
    gridApi.current && gridApi.current.hideOverlay();
  }

  // [NOTE] Checked if rows total updated is not same with before;
  if (noRows !== store.agGridTotalRow) {
    if (gridApi.current) {
        if (gridApi.current.paginationGetPageSize() < gridApi.current.getDisplayedRowCount()) {
          store.setAgGridRowsPage(gridApi.current.paginationGetPageSize() * (gridApi.current.paginationGetCurrentPage() + 1));
        } else {
          store.setAgGridRowsPage(gridApi.current.getDisplayedRowCount());
        }
        store.setAgGridTotalRow(gridApi.current.getDisplayedRowCount());
    }
  }

  // [NOTE] Checked if page total updated is not same with before;
  if (recentTotalPage !== store.agGridTotalPage) {
    if (gridApi.current) {
      store.setAgGridTotalPage(gridApi.current.paginationGetTotalPages());
    }
  }
};


// Get Filter & Sort Model
const setFilterModel = (gridApi, store) => {
  const savedFilters = gridApi?.current?.getFilterModel();
  store.setFilterGrid(savedFilters);
};

const setSortModel = (columnApi, store) => {
  const sortState = columnApi?.current?.getColumnState();
  store.setSortGrid(sortState);
};


// [NOTE] fixed for load rows perpage when filtered
const onFilterChanged = (gridApi, store) => {
  if (gridApi.current)
  {
      if (gridApi.current.paginationGetPageSize() < gridApi.current.getDisplayedRowCount()) {
        store.setAgGridRowsPage(gridApi.current.paginationGetPageSize() * (gridApi.current.paginationGetCurrentPage() + 1));
      } else {
        store.setAgGridRowsPage(gridApi.current.getDisplayedRowCount());
      }
      store.setAgGridTotalRow(gridApi.current.getDisplayedRowCount());
      store.setAgGridTotalPage(gridApi.current.paginationGetTotalPages());
  }
}


// Set Filter & Sort Model
const restoreSortFilterModel = (gridApi, columnApi, store) => {
  if (store.sortGrid.length > 0) {
    let gridColumnCopy = [...store.gridColumn.sort((a, b) => a.order - b.order)];
    gridColumnCopy = gridColumnCopy.slice();
    gridColumnCopy = gridColumnCopy.map((item, index) => {
      const updateSort = store.sortGrid.find(x => x.colId === item.colId && x.sort !== null);
      return {
        ...item,
        sort: (updateSort !== undefined) ? updateSort.sort : null
      };
    });
    store.updateGridColumn(gridColumnCopy);
    columnApi.current && columnApi.current.applyColumnState(gridColumnCopy);
  }

  if (!_.isEmpty(store.filterGrid) && _.isObject(store.filterGrid)) {
    gridApi.current && gridApi.current.setFilterModel(store.filterGrid);
  }
}

export { FilterDate, ConvertManageChatbotRawData, FilterDateWithTime, GetKeyByValue, onDNDColumn, onModelUpdated, onFilterChanged, restoreSortFilterModel, ComparatorDate }
